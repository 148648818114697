import request from 'library/request';
import Token from 'library/helpers/token';
import LocalStorage from 'library/helpers/local-storage';
import { LoggedUser } from 'store/features/users/users.types';

class Authentication {
  private token: string | null;

  constructor() {
    this.token = this.getToken();

    if (this.token && Token.isExpired(this.token)) {
      this.clearCache();
    }
  }

  getToken() {
    return LocalStorage.getItem('token');
  }

  setToken(token: string) {
    LocalStorage.setItem('token', token);
  }

  hasSession() {
    return Boolean(this.getToken());
  }

  getLoggedUser(): LoggedUser {
    return JSON.parse(LocalStorage.getItem('loggedUser') as string);
  }

  setLoggedUser(data: LoggedUser) {
    LocalStorage.setItem('loggedUser', JSON.stringify(data));
  }

  async logIn(params: { userName: string; password: string }) {
    try {
      const token = await request.logIn(params);
      this.setToken(token);
      window.location.href = '/';
    } catch (error: any) {
      return error;
    }
  }

  logOut() {
    this.clearCache();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  }

  clearCache() {
    LocalStorage.removeItem('loggedUser');
    LocalStorage.removeItem('token');
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Authentication();
