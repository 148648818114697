import './styles.css';
import { Dispatch, SetStateAction } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import { EditNotificationRuleFormParams } from 'pages/notification-panel-edit';

type EditNotificationRuleTextfieldNotificationRecipientsEmailsProps = {
  onChange: Dispatch<SetStateAction<EditNotificationRuleFormParams>>;
  formParams: EditNotificationRuleFormParams;
};

const EditNotificationRuleTextfieldNotificationRecipientsEmails = ({
  onChange,
  formParams,
}: EditNotificationRuleTextfieldNotificationRecipientsEmailsProps) => {
  return (
    <ReactMultiEmail
      emails={formParams.notificationRecipientsEmails}
      onChange={(_emails: string[]) => {
        onChange((prevState) => ({ ...prevState, notificationRecipientsEmails: _emails }));
      }}
      getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
        return (
          <div data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        );
      }}
    />
  );
};

export default EditNotificationRuleTextfieldNotificationRecipientsEmails;
