import { Widget } from 'components';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Authentication from 'utils/authentication';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { IntegrationStatus } from 'containers/elements/integration-summary';

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Entegrasyon Özeti',
    path: '/integration-summary',
  },
];

const IntegrationSummary = () => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'integration-summary');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Entegrasyon Özeti" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget title="Şirket Grubu Entegrasyon Özeti">
        <IntegrationStatus />
      </Widget>
    </>
  );
};

export default IntegrationSummary;
