import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { AddNotificationRuleFormParams } from 'pages/notification-panel-add';

type AddNotificationRuleTextfieldDescriptionProps = {
  onChange: Dispatch<SetStateAction<AddNotificationRuleFormParams>>;
};

const AddNotificationRuleTextfieldDescription = ({ onChange }: AddNotificationRuleTextfieldDescriptionProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const description = event.target.value;
    onChange((prevState: AddNotificationRuleFormParams) => ({ ...prevState, description }));
  };
  return (
    <TextField
      size="small"
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      fullWidth
      onChange={handleChange}
      id="outlined-basic-add-notification-rule-description"
      variant="outlined"
    />
  );
};

export default AddNotificationRuleTextfieldDescription;
