import { RuleSet, styled } from 'styled-components';
import { bgCard } from 'assets/style-helpers/colors';

type CardContainerProps = {
  $additionalContainerStyle?: RuleSet;
};

export const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  height: auto;
  display: flex;
  flex: 1;
  flex-grow: 1;
  border-radius: 12px;
  padding: 16px;
  flex-direction: column;
  background-color: ${bgCard};

  &:last-child {
    margin-right: 0;
  }

  &:only-child {
    margin-right: 0;
    width: -webkit-fill-available;
  }

  @media (max-width: 990px) {
    flex-grow: 0;
    margin-bottom: 8px;
  }

  ${({ $additionalContainerStyle }) => $additionalContainerStyle && $additionalContainerStyle}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LeftItemContainer = styled.div`
  justify-content: flex-start;
`;
export const CenterItemContainer = styled.div`
  flex-grow: 1;
  justify-content: center;
`;
export const RightItemContainer = styled.div`
  justify-content: flex-end;
`;

type CardContentProps = {
  $additionalStyle?: RuleSet;
};

export const CardContent = styled.div<CardContentProps>`
  flex-direction: column;
  display: flex;
  height: 100%;

  ${({ $additionalStyle }) => $additionalStyle && $additionalStyle}
`;
