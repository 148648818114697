import * as Pages from 'pages';
import { ReactNode } from 'react';
import Layout from 'containers/layout';
import { GlobalStyle } from './global.styles';
import Authentication from 'utils/authentication';
import { Routes, Navigate, Route, BrowserRouter } from 'react-router-dom';

const App = () => {
  const hasSession = Authentication.hasSession();
  const redirect = hasSession ? '/dashboard' : '/login';

  const renderPublicRoute = (permission: boolean, component: ReactNode) => {
    return !permission ? component : <Navigate to="/dashboard" />;
  };

  const renderPrivateRoute = (permission: boolean, component: ReactNode) => {
    return permission ? component : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Layout>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={renderPublicRoute(hasSession, <Pages.Login />)} />

          {/* Private Routes */}
          <Route path="/users/*" element={renderPrivateRoute(hasSession, <Pages.Users />)} />
          <Route path="/settings" element={renderPrivateRoute(hasSession, <Pages.Settings />)} />
          <Route path="/dashboard" element={renderPrivateRoute(hasSession, <Pages.Dashboard />)} />
          <Route path="/solutions" element={renderPrivateRoute(hasSession, <Pages.Solutions />)} />
          <Route path="/my-profile/*" element={renderPrivateRoute(hasSession, <Pages.MyProfile />)} />
          <Route path="/error-codes/*" element={renderPrivateRoute(hasSession, <Pages.ErrorCodes />)} />
          <Route path="/integrations" element={renderPrivateRoute(hasSession, <Pages.Integrations />)} />
          <Route path="/group-companies/*" element={renderPrivateRoute(hasSession, <Pages.GroupCompanies />)} />
          <Route path="/integration-summary" element={renderPrivateRoute(hasSession, <Pages.IntegrationSummary />)} />
          <Route path="/notification-panel/*" element={renderPrivateRoute(hasSession, <Pages.NotificationPanel />)} />

          {/* Redirecting from path "/" */}
          <Route path="/*" element={<Navigate to={redirect} />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
