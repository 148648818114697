import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Widget, Card, Text } from 'components';
import Authentication from 'utils/authentication';
import {
  EditNotificationRuleTelegramForm,
  EditNotificationRuleSelectChannel,
  EditNotificationRuleSelectPriority,
  EditNotificationRuleSelectStatusType,
  EditNotificationRuleSelectErrorCodes,
  EditNotificationRuleTextfieldDescription,
  EditNotificationRuleCheckboxNotNotifySupportStaff,
  EditNotificationRuleTextfieldFirstNotificationDelay,
  EditNotificationRuleTextfieldNotificationIntervalInHours,
  EditNotificationRuleTextfieldNotificationRecipientsEmails,
} from 'containers/elements/notification-panel-edit';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import { TelegramNotificationModel } from 'library/request/types';
import FormButtons from 'containers/elements/@commons/form-buttons';
import { selectNotificationRule } from 'store/features/update-notification-rule/update-notification-rule.selector';

const { updateNotificationRule } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Bildirim Paneli',
    path: '/notification-panel',
  },
  {
    name: 'Bildirim Düzenle',
    path: '/notification-panel/edit',
  },
];

export type EditNotificationRuleFormParams = {
  id: number;
  statusType: number;
  errorCodes: string[];
  alertPriority: number | null;
  notificationIntervalInHours: number;
  notificationRecipientsEmails: string[];
  firstNotificationDelayInMinutes: number;
  notificationType: number;
  telegramNotificationModels: TelegramNotificationModel[];
  description: string;
  notNotifySupportStaff: boolean;
};

const NotificationPanelEdit = () => {
  const loggedUser = Authentication.getLoggedUser();

  const selectedNotificationRule = useAppSelector(selectNotificationRule);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<EditNotificationRuleFormParams>({
    id: selectedNotificationRule?.id || 0,
    statusType: selectedNotificationRule?.statusType || 1,
    alertPriority: selectedNotificationRule?.alertPriority || null,
    errorCodes: selectedNotificationRule?.errorCodes ? selectedNotificationRule.errorCodes : [],
    firstNotificationDelayInMinutes: selectedNotificationRule?.firstNotificationDelayInMinutes || 0,
    notificationIntervalInHours: selectedNotificationRule?.notificationIntervalInHours || 0,
    notificationRecipientsEmails: selectedNotificationRule?.notificationRecipientsEmails
      ? selectedNotificationRule.notificationRecipientsEmails
      : [],
    notificationType: selectedNotificationRule?.notificationType || 0,
    telegramNotificationModels: selectedNotificationRule?.telegramNotificationModels
      ? selectedNotificationRule.telegramNotificationModels
      : [],
    description: selectedNotificationRule?.description || '',
    notNotifySupportStaff: selectedNotificationRule?.notNotifySupportStaff || false,
  });

  const onClear = () => {
    setFormParams({
      id: selectedNotificationRule?.id || 0,
      statusType: selectedNotificationRule?.statusType || 1,
      alertPriority: selectedNotificationRule?.alertPriority || null,
      errorCodes: selectedNotificationRule?.errorCodes ? selectedNotificationRule.errorCodes : [],
      firstNotificationDelayInMinutes: selectedNotificationRule?.firstNotificationDelayInMinutes || 0,
      notificationIntervalInHours: selectedNotificationRule?.notificationIntervalInHours || 0,
      notificationRecipientsEmails: selectedNotificationRule?.notificationRecipientsEmails
        ? selectedNotificationRule.notificationRecipientsEmails
        : [],
      notificationType: selectedNotificationRule?.notificationType || 0,
      telegramNotificationModels: selectedNotificationRule?.telegramNotificationModels
        ? selectedNotificationRule.telegramNotificationModels
        : [],
      description: selectedNotificationRule?.description || '',
      notNotifySupportStaff: selectedNotificationRule?.notNotifySupportStaff || false,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.firstNotificationDelayInMinutes <= 0 || formParams.notificationIntervalInHours <= 0) {
      setErrorMessage('İlk Bildirim Gecikme Aralığı ve Bildirim Sıklığı sıfırdan (0) büyük olmalıdır.');
      return setError(true);
    }

    if (
      formParams.telegramNotificationModels.some(
        (notificationModel) => !notificationModel.telegramUserName || !notificationModel.telegramUserId
      )
    ) {
      setErrorMessage('Telegram Kullanıcı Adı veya Id boş bırakılamaz.');
      return setError(true);
    }

    setLoading(true);

    updateNotificationRule(formParams)
      .then((res) => {
        setLoading(false);

        if (res.message === 'Bazı hatalar oluştu') {
          setErrorMessage(res.validationErrors?.[0].errorMessage as string);
          setError(true);
          return;
        }

        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN || !selectedNotificationRule) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  useEffect(() => {
    // Email status only
    if (formParams.notificationType === 0) {
      setFormParams((prevState) => ({ ...prevState, telegramNotificationModels: [] }));
    }

    // Telegram status only
    if (formParams.notificationType === 1) {
      setFormParams((prevState) => ({ ...prevState, notificationRecipientsEmails: [] }));
    }
  }, [formParams.notificationType]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Bildirim Düzenle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Durum</span>
                  <StyledPaper>
                    <EditNotificationRuleSelectStatusType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Öncelik</span>
                  <StyledPaper>
                    <EditNotificationRuleSelectPriority onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>İlk Bildirim Gecikme Aralığı (dk)</span>
                  <StyledPaper>
                    <EditNotificationRuleTextfieldFirstNotificationDelay
                      onChange={setFormParams}
                      formParams={formParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Hangi Sıklıkla Gönderilsin (sa)</span>
                  <StyledPaper>
                    <EditNotificationRuleTextfieldNotificationIntervalInHours
                      onChange={setFormParams}
                      formParams={formParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Bildirim Kanalları</span>
                  <StyledPaper>
                    <EditNotificationRuleSelectChannel onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Hata Kodları</span>
                  <StyledPaper>
                    <EditNotificationRuleSelectErrorCodes onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Açıklama</span>
                  <StyledPaper>
                    <EditNotificationRuleTextfieldDescription onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                {formParams.notificationType !== 1 && (
                  <Grid item xs={12} md={4}>
                    <span>Mail Adresleri</span>
                    <StyledPaper>
                      <EditNotificationRuleTextfieldNotificationRecipientsEmails
                        onChange={setFormParams}
                        formParams={formParams}
                      />
                    </StyledPaper>
                  </Grid>
                )}
              </Grid>

              {formParams.notificationType !== 0 && (
                <Grid item xs={12} md={12}>
                  <span>Telegram Bilgileri</span>
                  <StyledPaper
                    elevation={3}
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                    }}>
                    <EditNotificationRuleTelegramForm
                      formParams={formParams}
                      setFormParams={setFormParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <EditNotificationRuleCheckboxNotNotifySupportStaff onChange={setFormParams} formParams={formParams} />
              </Grid>

              <FormButtons
                loading={loading}
                actionName={'Kaydet'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default NotificationPanelEdit;
