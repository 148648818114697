import { Route, Routes } from 'react-router-dom';
import { NotificationPanelMain, NotificationPanelAdd, NotificationPanelEdit } from '..';

const NotificationPanel = () => {
  return (
    <Routes>
      <Route index element={<NotificationPanelMain />} />
      <Route path="add" element={<NotificationPanelAdd />} />
      <Route path="edit" element={<NotificationPanelEdit />} />
    </Routes>
  );
};

export default NotificationPanel;
