import * as Styles from './styles';
import Text from 'components/text';
import { ROLE_API_NAME_ENUM } from 'enums';
import Authentication from 'utils/authentication';
import { SelectCompany } from '../../elements/@commons';

export const sidebarItems = [
  {
    id: 0,
    name: 'Genel Bakış',
    onClick: () => {},
    route: 'dashboard',
    icon: <Styles.DashboardIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 1,
    name: 'Entegrasyon Özeti',
    onClick: () => {},
    route: 'integration-summary',
    icon: <Styles.IntegrationSummaryIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 2,
    name: 'Entegrasyonlar',
    onClick: () => {},
    route: 'integrations',
    icon: <Styles.IntegrationsIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 3,
    name: 'Çözümler',
    onClick: () => {},
    route: 'solutions',
    icon: <Styles.SolutionsIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN],
  },
  {
    id: 4,
    name: 'Hata Kodları',
    onClick: () => {},
    route: 'error-codes',
    icon: <Styles.ErrorCodesIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 5,
    name: '',
    onClick: () => {},
    route: '',
    icon: null,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 6,
    name: '',
    onClick: () => {},
    route: '',
    icon: null,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 7,
    name: '',
    onClick: () => {},
    route: '',
    icon: null,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN, ROLE_API_NAME_ENUM.CUSTOMER],
  },
  {
    id: 8,
    name: 'Grup Şirketler',
    onClick: () => {},
    route: 'group-companies',
    icon: <Styles.GroupCompaniesIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN],
  },
  {
    id: 9,
    name: 'Kullanıcılar',
    onClick: () => {},
    route: 'users',
    icon: <Styles.UsersIcon />,
    permissions: [ROLE_API_NAME_ENUM.USER, ROLE_API_NAME_ENUM.ADMIN],
  },
  {
    id: 10,
    name: 'Bildirim Paneli',
    onClick: () => {},
    route: 'notification-panel',
    icon: <Styles.NotificationIcon />,
    permissions: [ROLE_API_NAME_ENUM.ADMIN],
  },
];

const SideBar = () => {
  const loggedUser = Authentication.getLoggedUser();

  return (
    <Styles.GeneralContainer>
      <Styles.CompanyContainer>
        <SelectCompany />
      </Styles.CompanyContainer>
      <Styles.WrapperContainer>
        <Styles.SidebarContainer>
          {sidebarItems.map((item, index) => {
            const hasPermission = item.permissions.some((permission) => permission === loggedUser?.role);

            return hasPermission ? (
              <Styles.SidebarItemPolyContainer key={`${item.route}-${index}`} to={item.route}>
                {item.icon}
                <Styles.SidebarTextContainer>
                  <Text>
                    <span>{item.name}</span>
                  </Text>
                </Styles.SidebarTextContainer>
              </Styles.SidebarItemPolyContainer>
            ) : null;
          })}
        </Styles.SidebarContainer>
        <Styles.SidebarContainer>
          <Styles.SidebarItemMonoContainer
            onClick={() => {
              Authentication.logOut();
            }}>
            <Styles.SignoutIcon />
            <Styles.ExternalTextContainer>
              <Text>
                <span>Çıkış Yap</span>
              </Text>
            </Styles.ExternalTextContainer>
          </Styles.SidebarItemMonoContainer>
        </Styles.SidebarContainer>
      </Styles.WrapperContainer>
    </Styles.GeneralContainer>
  );
};

export default SideBar;
