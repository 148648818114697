import { ChaseContainer, ChildDotContainer, WrapperContainer } from './styles';

type LoaderProps = {
  $white?: boolean;
};

const Loader = ({ $white }: LoaderProps) => {
  return (
    <WrapperContainer>
      <ChaseContainer>
        <ChildDotContainer $white={$white}></ChildDotContainer>
        <ChildDotContainer $white={$white}></ChildDotContainer>
        <ChildDotContainer $white={$white}></ChildDotContainer>
        <ChildDotContainer $white={$white}></ChildDotContainer>
        <ChildDotContainer $white={$white}></ChildDotContainer>
        <ChildDotContainer $white={$white}></ChildDotContainer>
      </ChaseContainer>
    </WrapperContainer>
  );
};

export default Loader;
