// Main

export const white = '#ffffffe6';
export const black = '#1e1e1e';
export const primary = '#0f62fe';
export const secondary = '#03dac5';
export const surface = '#121212';
export const red = '#cf6679';
export const red2 = '#c52f2f';

// Container

export const bgContainer = '#e8e8e8';

// Header

export const bgHeader = '#ffffffcf';

// Button

export const _button = white;
export const textButton = '#1e1e1ee6';
export const bgButton = '#1e1e1e0a';
export const bgButtonHover = '#1e1e1e17';
export const bgButtonActive = '#1e1e1e1a';

// Icon

export const _icon = '#121212ab';
export const iconHover = '#121212de';

// Card

export const bgCard = '#ffffffcf';

// Input

export const bgInput = '#1e1e1e0a';
export const textInput = '#1e1e1ee6';
export const inputFocus = '#1e1e1e99';
export const inputError = '#cf6679';

// Dropdown

export const bgDropdown = '#1e1e1e0f';
export const textDropdown = '#1e1e1ede';
export const dropdownIcon = '#121212';
export const dropdownOptionShadow = '#00000024';
export const bgDropdownOption = '#fff';
export const dropdownOptionIcon = white;
export const dropdownOptionSelect = '#1e1e1e1f';
export const dropdownOptionSelectHover = '#1e1e1e0f';
export const dropdownOptionItemBorder = '#ffffff1c';
export const _dropdownOption = '#1e1e1e';
export const dropdownOptionSelected = '#0f62fe';
export const bgDropdownOptionSelected = '#ffffff1c';
export const dropdownOptionSelectedHover = '#1e1e1e0f';
export const dropdownOptionBorder = '#1e1e1e1f';
export const bgCurrencyBox = '#ffffffc7';

// Notications

export const badge = '#c52f2f';
export const notificationListBorder = '#1e1e1e2e';
export const notificationArrow = '#1e1e1e2e';

// Sidebar

export const bgSidebar = white;
export const sidebarItem = '#1e1e1ec7';
