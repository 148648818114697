import * as Styles from './styles';
import { Loader } from 'components';
import request from 'library/request';
import { ChangeEvent, useState } from 'react';
import { renderFormattedTime } from 'utils/format-time';
import { Grid, Paper, Tooltip, Box } from '@mui/material';
import { CommentBlock } from 'containers/elements/@commons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { renderFormattedPriority } from 'utils/format-priority';
import { setIsDrawerOpen } from 'store/features/drawer/drawer.slice';
import { selectLoadingUpdate } from 'store/features/drawer/drawer.selector';
import { SolutionsChangeActivity, SolutionsChangeStatus, SolutionsChangeUser } from './components';
import { selectIsLoading, selectSelectedIntegration } from 'store/features/drawer-solutions/drawer-solutions.selector';

const { commentOut } = request;

const SolutionsDrawerContent = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const loadingUpdate = useAppSelector(selectLoadingUpdate);
  const selectedIntegration = useAppSelector(selectSelectedIntegration);

  const [comment, setComment] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const disabled = comment === '';

  const handleSubmit = () => {
    if (!selectedIntegration) return;

    setLoadingSubmit(true);
    commentOut({ companyIntegrationId: selectedIntegration.id, description: comment }).then((res) => {
      setComment('');
      setLoadingSubmit(false);
    });
  };

  if (!selectedIntegration) {
    dispatch(setIsDrawerOpen(false));
    return <></>;
  }

  return (
    <Box
      sx={{ width: { md: '50vw', xs: '75vw' }, height: '99vh', paddingLeft: '2px' }}
      role="presentation"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          dispatch(setIsDrawerOpen(false));
        }
      }}>
      {!selectedIntegration || isLoading ? (
        <Styles.LoaderContainer>
          <Loader />
        </Styles.LoaderContainer>
      ) : (
        <Grid container direction={'column'}>
          <Grid item xs={12} md={12}>
            <Paper>
              <Styles.HeaderContainer>
                <Styles.HeaderSpan>
                  {`${selectedIntegration.companyGroupName.toUpperCase()} - ${selectedIntegration.companyName.toUpperCase()} - ${
                    selectedIntegration.taxNumber
                  }`}
                </Styles.HeaderSpan>
              </Styles.HeaderContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper sx={{ width: '100%', margin: '10px 0px', paddingLeft: '20px' }}>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>UID:</Styles.DetailSpanTitleSpan>&nbsp;
                    {selectedIntegration.uid}
                  </Styles.DetailSpan>
                </Grid>

                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>Banka:</Styles.DetailSpanTitleSpan>&nbsp;
                    {`${selectedIntegration.bankName} - ${selectedIntegration.bankCode}`}
                  </Styles.DetailSpan>
                </Grid>

                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>Hata:</Styles.DetailSpanTitleSpan>&nbsp;
                    {selectedIntegration.errorCode ? (
                      <>
                        <Tooltip arrow followCursor placement="top" title={selectedIntegration.errorCodeDescription}>
                          <span>{selectedIntegration.errorCode}</span>
                        </Tooltip>
                        <span>&nbsp;-&nbsp;</span>
                        <span>{renderFormattedPriority(selectedIntegration.alertPriority)}</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </Styles.DetailSpan>
                </Grid>

                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>Tarih:</Styles.DetailSpanTitleSpan>&nbsp;
                    {renderFormattedTime(selectedIntegration.errorDate)}
                  </Styles.DetailSpan>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Styles.FullMessageContainer>
              <Paper sx={{ marginBottom: '10px', paddingBottom: '5px' }}>
                <Styles.MessageContentContainer>
                  <Styles.MessageContent>
                    <Styles.DetailSpanTitleSpan>Açıklama:</Styles.DetailSpanTitleSpan>&nbsp;
                    {selectedIntegration.message}
                  </Styles.MessageContent>
                </Styles.MessageContentContainer>
              </Paper>
            </Styles.FullMessageContainer>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper sx={{ width: '100%', height: '16vh', margin: '10px 0px', padding: '0px 0px 10px 10px' }}>
              <Grid container spacing={1}>
                <Grid item container>
                  <Grid item xs={5} md={2}>
                    <Styles.OperationSpan>Kullanıcıya ata:</Styles.OperationSpan>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <SolutionsChangeUser />
                  </Grid>
                </Grid>

                <Grid item container>
                  <Grid item xs={5} md={2}>
                    <Styles.OperationSpan>Aktivite değiştir:</Styles.OperationSpan>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <SolutionsChangeActivity />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={5} md={2}>
                    <Styles.OperationSpan>Durum değiştir:</Styles.OperationSpan>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <SolutionsChangeStatus />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper>
              <Styles.CommentsContainer>
                <CommentBlock
                  loadingSubmit={loadingSubmit}
                  loadingUpdate={loadingUpdate}
                  companyIntegrationId={selectedIntegration.id}
                />
              </Styles.CommentsContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            {loadingSubmit ? (
              <Styles.CommentOutContainer>
                <Loader />
              </Styles.CommentOutContainer>
            ) : (
              <Styles.CommentOutContainer>
                <Styles.InputContainer
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
                  placeholder="Yorum Ekle"
                />
                <Styles.ButtonContainer disabled={disabled} onClick={() => handleSubmit()} type="button">
                  Yorum Yap
                </Styles.ButtonContainer>
              </Styles.CommentOutContainer>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SolutionsDrawerContent;
