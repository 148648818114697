import {
  ExpandIconLess,
  ExpandIconMore,
  OptionItemContainer,
  OptionSubItemContainer,
  OptionItemGeneralContainer,
  OptionItemShowMoreContainer,
  OptionItemShowMoreWrapperContainer,
} from './styles';
import { Company } from 'library/request/types';
import { Fragment, MouseEvent, useEffect, useState } from 'react';

type CompanySelectItemProps = {
  items: Company[];
  selectedItems: Company[];
  onHandleCompany: (company: Company) => void;
  searchText: string;
};

const CompanySelectItem = (props: CompanySelectItemProps) => {
  const { items, selectedItems, onHandleCompany, searchText } = props;
  const [showMore, setShowMore] = useState<string[]>([]);

  useEffect(() => {
    if (showMore.length > 0) {
      document.addEventListener('click', () => {
        setShowMore([]);
      });
    }

    return () => {
      document.removeEventListener('click', () => {});
    };
  }, [showMore]);

  const showMoreToogle = (optionUIName: string) => {
    let cloneShowMore = [...showMore];
    const index = cloneShowMore.findIndex((item) => item === optionUIName);
    index < 0 ? cloneShowMore.push(optionUIName) : cloneShowMore.splice(index, 1);
    setShowMore(cloneShowMore);
  };

  const getOpenStatusByName = (optionUIName: string, showItems: string[]) => {
    return showItems.findIndex((item) => item === optionUIName) > -1;
  };

  const handleClickOptionItem = (e: MouseEvent, item: Company) => {
    e.stopPropagation();
    onHandleCompany(item);
  };

  const handleClickShowMore = (e: MouseEvent, item: Company) => {
    e.stopPropagation();
    item.optionUIName && showMoreToogle(item.optionUIName);
  };

  return (
    <>
      {items
        .filter((item) => {
          let appopriate = false;

          if (item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) {
            appopriate = true;
          }

          if (
            item.type === 'node' &&
            item.companies?.some((el) => el.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
          ) {
            appopriate = true;
          }

          return appopriate;
        })
        .map((item, index) => {
          const openStatus = getOpenStatusByName(item.optionUIName, showMore);

          const itemName = item.isAll ? 'Tüm Şirketler' : item.title;

          const isSelected =
            selectedItems.findIndex((selectedCompany) => selectedCompany.optionUIName === item.optionUIName) > -1;

          const isHalfSelected =
            selectedItems.findIndex((selectedCompany) => selectedCompany.parentUIName === item.optionUIName) > -1;

          return (
            <Fragment key={index.toString()}>
              <OptionItemGeneralContainer
                $isAll={item.isAll}
                $isHalfSelected={isHalfSelected}
                onClick={(e: MouseEvent) => handleClickOptionItem(e, item)}>
                <abbr title={itemName}>
                  <OptionItemContainer $isNode={item.isNode} $isSelected={isSelected}>
                    <span data-parent={item.parentUIName} data-ui={item.optionUIName}>
                      {itemName}
                    </span>
                  </OptionItemContainer>
                </abbr>
                <OptionItemShowMoreWrapperContainer
                  $isNode={item.isNode}
                  onClick={(e: MouseEvent) => handleClickShowMore(e, item)}>
                  <OptionItemShowMoreContainer $isNode={item.isNode} $openStatus={openStatus}>
                    {openStatus ? <ExpandIconLess /> : <ExpandIconMore />}
                  </OptionItemShowMoreContainer>
                </OptionItemShowMoreWrapperContainer>
              </OptionItemGeneralContainer>
              <OptionSubItemContainer $openStatus={openStatus}>
                {item.companies && <CompanySelectItem {...props} items={item.companies} key={index.toString()} />}
              </OptionSubItemContainer>
            </Fragment>
          );
        })}
    </>
  );
};

export default CompanySelectItem;
