import { useState } from 'react';
import request from 'library/request';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DialogContentText from '@mui/material/DialogContentText';
import {
  setCode,
  setErrorUpdate,
  setLoadingUpdate,
  setIsDeletePopUpOpen,
} from 'store/features/update-error-code/update-error-code.slice';
import {
  selectCode,
  selectErrorUpdate,
  selectIsDeletePopUpOpen,
} from 'store/features/update-error-code/update-error-code.selector';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';

const { deleteErrorCode } = request;

const ErrorCodesDeletePopUp = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectErrorUpdate);
  const open = useAppSelector(selectIsDeletePopUpOpen);
  const code = useAppSelector(selectCode) as string;

  const [errorMessageContent, setErrorMessageContent] = useState('');

  const handleClose = () => {
    dispatch(setCode(null));
    dispatch(setIsDeletePopUpOpen(false));
    setTimeout(() => {
      dispatch(setErrorUpdate(false));
    }, 1000);
  };

  const handleSubmit = () => {
    dispatch(setLoadingUpdate(true));
    deleteErrorCode({ code })
      .then((res) => {
        if (res.message === 'Kayıt silindi') {
          dispatch(errorCodesActions.getCodes());
          dispatch(setLoadingUpdate(false));
          handleClose();
          return;
        }

        dispatch(setLoadingUpdate(false));
        dispatch(setErrorUpdate(true));
        setErrorMessageContent(res.message);
      })
      .catch((err) => {
        dispatch(setLoadingUpdate(false));
        if (err.message === 'Network Error') {
          dispatch(setErrorUpdate(true));
          setErrorMessageContent(
            'Maalesef sunucuya bağlanırken bir hata ile karşılaştık. Lütfen daha sonra tekrar deneyiniz.'
          );
        }
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {error ? '' : 'Hata Kodunu Silmek İstediğinize Emin Misiniz?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error
              ? errorMessageContent
              : 'Bu işlem onaylandıktan sonra hata kodu veritabanından silinecektir. Silme işlemini onaylıyor musunuz?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {error ? (
            <Button onClick={handleClose}>Anladım</Button>
          ) : (
            <>
              <Button onClick={handleClose}>Hayır</Button>
              <Button onClick={handleSubmit}>Evet</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorCodesDeletePopUp;
