import usersActions from './users.actions';
import { UsersState } from './users.types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: UsersState = {
  error: null,
  usersMap: [],
  isLoading: false,
};

export const usersSlice = createSlice({
  initialState,
  name: 'users',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(usersActions.getUsers.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(usersActions.getUsers.fulfilled, (state, { payload }) => {
      state.usersMap = payload;
      state.isLoading = false;
    });

    builder.addCase(usersActions.getUsers.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });

    builder.addCase(usersActions.logUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(usersActions.logUser.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(usersActions.logUser.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export default usersSlice.reducer;
