import { Route, Routes } from 'react-router-dom';
import { ErrorCodesAdd, ErrorCodesEdit, ErrorCodesMain } from '..';

const ErrorCodes = () => {
  return (
    <Routes>
      <Route index element={<ErrorCodesMain />} />
      <Route path="add" element={<ErrorCodesAdd />} />
      <Route path="edit" element={<ErrorCodesEdit />} />
    </Routes>
  );
};

export default ErrorCodes;
