import { ReactNode, useEffect } from 'react';
import LocalStorage from 'library/helpers/local-storage';

type VersionProviderProps = {
  children: ReactNode;
};

const Version = ({ children }: VersionProviderProps) => {
  const currentVersion = process.env.REACT_APP_ENV_VERSION;

  useEffect(() => {
    const appVersion = LocalStorage.getItem('app-version');

    if (currentVersion && appVersion !== currentVersion) {
      LocalStorage.removeItem('app-version');
      LocalStorage.setItem('app-version', currentVersion);

      setTimeout(() => {
        window.location.href = '/';
      }, 100);
    }
  }, []);

  return <>{children}</>;
};

export default Version;
