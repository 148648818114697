import { Dispatch, SetStateAction } from 'react';
import { AddUserFormParams } from 'pages/users-add';
import { Checkbox, FormControlLabel } from '@mui/material';

type AddUserCheckboxUseTelegramProps = {
  onChange: Dispatch<SetStateAction<AddUserFormParams>>;
  formParams: AddUserFormParams;
};
const AddUserCheckboxUseTelegram = ({ onChange, formParams }: AddUserCheckboxUseTelegramProps) => {
  const handleChange = (event: any) => {
    const useTelegram = event.target.checked;
    onChange((prevState) => ({ ...prevState, useTelegram }));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      sx={{
        fontFamily: '"Open Sans", sans-serif',
      }}
      labelPlacement="end"
      label="Bildirimler için varsayılan olarak telegram bilgilerim kullanılsın."
      checked={formParams.useTelegram}
      onChange={handleChange}
    />
  );
};

export default AddUserCheckboxUseTelegram;
