import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorLog } from 'library/request/types';
import { StyledTableCell, StyledTableRow } from './styles';
import { formattedLongText } from 'utils/format-long-text';
import { RowByGroupCompany, renderFormattedTime } from '..';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableHead, Tooltip, Typography } from '@mui/material';

type RowByErrorCodeProps = {
  row: ErrorLog;
};

const RowByErrorCode = ({ row }: RowByErrorCodeProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onClickNavigateHandler = () =>
    navigate('/integrations', {
      state: {
        errorCodes: [row.errorCode],
      },
    });

  return (
    <>
      <StyledTableRow
        sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: open ? '#f7f7f7' : 'inherit' }}
        onClick={() => setOpen(!open)}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {row.errorCode}
        </StyledTableCell>
        {row.errorDescription.length >= 72 ? (
          <Tooltip arrow followCursor placement="top" title={row.errorDescription}>
            <StyledTableCell align="center">{formattedLongText(row.errorDescription, 72)}</StyledTableCell>
          </Tooltip>
        ) : (
          <StyledTableCell align="center">{row.errorDescription}</StyledTableCell>
        )}
        <StyledTableCell align="center">{row.totalErrorCount}</StyledTableCell>
        <StyledTableCell align="center">{row.totalSolutionCount}</StyledTableCell>
        <StyledTableCell align="center">{renderFormattedTime(row.avarageSolutionTime)}</StyledTableCell>
        <Tooltip arrow followCursor placement="top" title="Detaya Git">
          <StyledTableCell>
            <IconButton size="small" onClick={onClickNavigateHandler}>
              {<LanOutlinedIcon />}
            </IconButton>
          </StyledTableCell>
        </Tooltip>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          sx={{ backgroundColor: open ? '#f7f7f7ff' : 'inherit' }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ cursor: 'default' }}>
                Şirket Grubu Bazında
              </Typography>
              <Table aria-label="purchases">
                <TableHead sx={{ borderRadius: '12px' }} component={Paper}>
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}>
                      Şirket Grubu
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Toplam Hata Sayısı
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Toplam Çözüm Sayısı
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Ort. Çözüm Süresi
                    </StyledTableCell>
                    <StyledTableCell />
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row.companyGroups.map((companyGroup, index) => (
                    <RowByGroupCompany key={index.toString()} row={companyGroup} errorCode={row.errorCode} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default RowByErrorCode;
