import { Loader } from 'components';
import request from 'library/request';
import { useEffect, useState } from 'react';
import { Company } from 'library/request/types';
import { Autocomplete, TextField } from '@mui/material';

const { getCompaniesMap } = request;

const DisplayGroupCompanies = ({ groupCompanies }: { groupCompanies: number[] }) => {
  const [loading, setLoading] = useState(false);
  const [groupCompaniesMap, setGroupCompaniesMap] = useState<Company[]>([]);
  const [selectedGroupCompanies, setSelectedGroupCompanies] = useState<Company[]>([]);

  useEffect(() => {
    setLoading(true);
    getCompaniesMap().then((res) => {
      setGroupCompaniesMap(res.filter((groupCompany) => groupCompany.isNode));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (groupCompanies.length > 0 && groupCompaniesMap.length > 0) {
      const assignedGroupCompanies = groupCompaniesMap.filter((groupCompany) =>
        groupCompanies.includes(groupCompany.id as number)
      );
      setSelectedGroupCompanies(assignedGroupCompanies);
    } else {
      setSelectedGroupCompanies([]);
    }
  }, [groupCompanies, groupCompaniesMap]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      readOnly
      multiple
      size="small"
      value={selectedGroupCompanies}
      id="checkboxes-tags-demo"
      options={groupCompaniesMap}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Grup Şirket" />}
    />
  );
};

export default DisplayGroupCompanies;
