import styled, { css } from 'styled-components';

export const additionalInfoCardStyle = css`
  padding: 0 !important;
  border-radius: 15px !important;
  height: 135px !important;
`;

export const additionalInfoCardContentStyle = css`
  justify-content: center !important;
  align-items: center !important;
`;

export const TopContentContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  padding-bottom: 15px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
`;

export const MainTitleSpan = styled.span`
  font-size: 16px;
  color: #7b809ab0;
  font-weight: light;
`;

export const MainTitleAmountSpan = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

export const Divider = styled.div`
  width: 80%;
  border: 1px solid #00000014;
`;

export const BottomContentContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #7b809a80;
`;

type ExplanationAmountSpanProps = {
  $color?: string;
};

export const PrimaryExplanationAmountSpan = styled.span<ExplanationAmountSpanProps>`
  color: ${({ $color }) => $color || '#4caf50'};
`;

export const SecondaryExplanationAmountSpan = styled.span<ExplanationAmountSpanProps>`
  color: ${({ $color }) => $color || '#de5462'};
`;

export const ExplanationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
