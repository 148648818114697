import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { ROLE_API_NAME_ENUM } from 'enums';
import {
  EditErrorCodeTextfieldCode,
  EditErrorCodeSelectPriority,
  EditErrorCodeTextfieldDescription,
} from 'containers/elements/error-codes-edit';
import { useNavigate } from 'react-router-dom';
import { Widget, Card, Text } from 'components';
import Authentication from 'utils/authentication';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import FormButtons from 'containers/elements/@commons/form-buttons';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';
import { selectErrorCode } from 'store/features/update-error-code/update-error-code.selector';

const { updateErrorCode } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Hata Kodları',
    path: '/error-codes',
  },
  {
    name: 'Hata Kodu Düzenle',
    path: '/error-codes/edit',
  },
];

export type EditErrorCodeFormParams = {
  code: string;
  description: string;
  alertPriority: number;
};

const ErrorCodesEdit = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();
  const selectedErrorCode = useAppSelector(selectErrorCode);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<EditErrorCodeFormParams>({
    code: selectedErrorCode?.code || '',
    description: selectedErrorCode?.description || '',
    alertPriority: selectedErrorCode?.alertPriority || 1,
  });

  const onClear = () => {
    setFormParams({
      code: selectedErrorCode?.code || '',
      description: selectedErrorCode?.description || '',
      alertPriority: selectedErrorCode?.alertPriority || 1,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.description === '') {
      setErrorMessage('Açıklama boş geçilemez.');
      return setError(true);
    }

    setLoading(true);

    updateErrorCode(formParams)
      .then((res) => {
        setLoading(false);
        dispatch(errorCodesActions.getCodes());
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN || !selectedErrorCode) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Hata Kodu Düzenle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} md={6}>
                  <span>Kod</span>
                  <StyledPaper>
                    <EditErrorCodeTextfieldCode formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span>Öncelik</span>
                  <StyledPaper>
                    <EditErrorCodeSelectPriority onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={12}>
                  <span>Açıklama</span>
                  <StyledPaper>
                    <EditErrorCodeTextfieldDescription onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
              </Grid>
              <FormButtons
                loading={loading}
                actionName={'Kaydet'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default ErrorCodesEdit;
