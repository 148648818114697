import { Company } from '../request/types';

export const createUIName = (index: number, parentUIName?: string) => {
  const uiName = parentUIName || 'root';
  return `${uiName}-${index}`;
};

export const formatCompanies = (companies: Company[]): Company[] => {
  const cloneArray = [...companies];
  const typedArray = cloneArray.map((item, index) => {
    if (item.companies) {
      const optionUIName = `root-${index + 1}`;
      item.isNode = true;
      item.type = 'node';

      item.optionUIName = optionUIName;

      item.companies.forEach((item, index) => {
        item.isLeaf = true;
        item.type = 'leaf';
        item.optionUIName = `${optionUIName}-${index}`;
        item.parentUIName = optionUIName;
      });
    }

    if (!item.companies) {
      item.isLeaf = true;
      item.type = 'leaf';
      item.optionUIName = `root-${index + 1}`;
    }

    return item;
  });

  typedArray.unshift({
    id: 0,
    isLeaf: true,
    title: 'Tüm Şirketler',
    optionUIName: 'root-0',
    userName: 'tüm şirketler',
    updatedDate: null,
    statusType: 0,
    type: 'leaf',
    createdDate: ' ',
    companies: [],
    defaultSupportPerson: ' ',
    isAll: true,
  });

  return typedArray;
};
