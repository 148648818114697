import { InputContainer, WrapperContainer } from './styles';
import { useEffect, useState, InputHTMLAttributes } from 'react';

type InputProps = {
  type?: string;
  $error?: boolean;
  value: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({ type = 'text', $error = false, value, ...props }: InputProps) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value ? value : '');
  }, [value]);

  return (
    <WrapperContainer>
      <InputContainer type={type} value={inputValue} $error={$error} {...props} />
    </WrapperContainer>
  );
};

export default Input;
