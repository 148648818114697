import Box from '@mui/material/Box';
import BorderLinearProgress from './styles';

type CustomizedProgressBarsProps = {
  value: number;
};

const CustomizedProgressBars = ({ value }: CustomizedProgressBarsProps) => {
  const color = value >= 90 ? '#F24848' : value >= 70 ? '#DBD812' : '#1EE32E';

  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={value} fillcolor={color} />
    </Box>
  );
};

export default CustomizedProgressBars;
