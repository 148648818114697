import TextField from '@mui/material/TextField';
import { EditErrorCodeFormParams } from 'pages/error-codes-edit';

type EditErrorCodeTextfieldCodeProps = {
  formParams: EditErrorCodeFormParams;
};

const EditErrorCodeTextfieldCode = ({ formParams }: EditErrorCodeTextfieldCodeProps) => (
  <TextField
    fullWidth
    size="small"
    sx={{
      '& div': {
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      },
    }}
    inputProps={{ readOnly: true }}
    value={formParams.code}
    id="outlined-basic-code"
    variant="outlined"
  />
);

export default EditErrorCodeTextfieldCode;
