import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { STATUS_TYPE_ENUM, getStatusTranslated } from 'enums';
import { EditMyProfileFormParams } from 'pages/my-profile-edit';

type EditMyProfileSelectStatusTypeProps = {
  onChange: Dispatch<SetStateAction<EditMyProfileFormParams>>;
  formParams: EditMyProfileFormParams;
};

const EditMyProfileSelectStatusType = ({ onChange, formParams }: EditMyProfileSelectStatusTypeProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const statusType = Number(event.target.value);
    onChange((prevState: EditMyProfileFormParams) => ({ ...prevState, statusType }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-status-type-edit-label"
      id="select-status-type-edit"
      value={String(formParams.statusType)}
      defaultValue={String(formParams.statusType)}
      onChange={handleChange}>
      {(Object.keys(STATUS_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof STATUS_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={STATUS_TYPE_ENUM[key]}>
            {getStatusTranslated(STATUS_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default EditMyProfileSelectStatusType;
