import { SetStateAction } from 'react';
import { Input, Button } from 'components';
import { ButtonContainer, CompanyFilterCardContainer, InputContainer, additionalButtonStyle } from './styles';

type CompanyFilterCardProps = {
  filterApplyButton: () => void;
  filterCompanyText: (value: SetStateAction<string>) => void;
  searchText: string;
};

const CompanyFilterCard = ({ filterApplyButton, filterCompanyText, searchText }: CompanyFilterCardProps) => {
  return (
    <CompanyFilterCardContainer>
      <InputContainer>
        <Input
          style={{ border: '1px solid', borderRadius: '10px', marginTop: '-1000px' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          value={searchText}
          placeholder="Şirket Adı"
          onChange={(e) => filterCompanyText(e.target.value)}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          $additionalStyle={additionalButtonStyle}
          style={{ width: '100%', backgroundColor: '#d4d4d4' }}
          onClick={() => filterApplyButton()}>
          Filtrele
        </Button>
      </ButtonContainer>
    </CompanyFilterCardContainer>
  );
};

export default CompanyFilterCard;
