import { ROLE_API_NAME_ENUM } from 'enums';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import Authentication from 'utils/authentication';
import { ErrorCode } from 'library/request/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { ErrorCodesFilterParams } from 'pages/error-codes-main';
import { renderFormattedPriority } from 'utils/format-priority';
import { CustomPagination } from 'containers/elements/@commons';
import { StyledTableCell, StyledTableRow, DeleteIcon, EditIcon } from './styles';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { Table, Paper, Tooltip, TableRow, TableBody, TableHead, TableFooter, TableContainer } from '@mui/material';
import { setCode, setErrorCode, setIsDeletePopUpOpen } from 'store/features/update-error-code/update-error-code.slice';

type ErrorCodesTableProps = {
  totalErrorCode: number;
  errorCodeList: ErrorCode[];
  filterParams: ErrorCodesFilterParams;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
  setFilterParams: Dispatch<SetStateAction<ErrorCodesFilterParams>>;
};

const ErrorCodesTable = ({
  errorCodeList: rows,
  totalErrorCode,
  filterParams,
  setFilterParams,
  onApplyPaginationFilter,
}: ErrorCodesTableProps) => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToEditErrorCodeHandler = useCallback(() => navigate('edit'), []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
              <>
                <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                <StyledTableCell
                  colSpan={1}
                  sx={{ borderRight: '1px solid #1e1e1e20' }}
                  align="center"></StyledTableCell>
              </>
            )}
            <StyledTableCell width="75px" align="center">
              Hata Kodu
            </StyledTableCell>
            <StyledTableCell align="center">Açıklama</StyledTableCell>
            <StyledTableCell width="95px" align="center">
              Öncelik
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!totalErrorCode ? (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={5}>Hata kodu bulunmamaktadır.</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index.toString()}>
                  {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
                    <>
                      <Tooltip placement="top" title="Sil" arrow followCursor>
                        <StyledTableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            dispatch(setCode(row.code));
                            dispatch(setIsDeletePopUpOpen(true));
                          }}
                          padding="none"
                          align="center">
                          <DeleteIcon />
                        </StyledTableCell>
                      </Tooltip>
                      <Tooltip placement="top" title="Düzenle" arrow followCursor>
                        <StyledTableCell
                          padding="none"
                          sx={{
                            borderRight: '1px solid #1e1e1e20',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            dispatch(setErrorCode(row));
                            goToEditErrorCodeHandler();
                          }}
                          align="center">
                          <EditIcon />
                        </StyledTableCell>
                      </Tooltip>
                    </>
                  )}
                  <StyledTableCell align="center">{row.code}</StyledTableCell>
                  <StyledTableCell align="center">{row.description}</StyledTableCell>
                  <StyledTableCell align="center">{renderFormattedPriority(row.alertPriority)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow sx={{ cursor: 'default' }}>
                <CustomPagination
                  colSpan={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? 5 : 3}
                  totalCount={totalErrorCode}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  onApplyPaginationFilter={onApplyPaginationFilter}
                />
              </StyledTableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default ErrorCodesTable;
