import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { EditGroupCompanyFormParams } from 'pages/group-companies-edit';

type EditGroupCompanyTextfieldPasswordProps = {
  onChange: Dispatch<SetStateAction<EditGroupCompanyFormParams>>;
  formParams: EditGroupCompanyFormParams;
};

const EditGroupCompanyTextfieldPassword = ({ onChange, formParams }: EditGroupCompanyTextfieldPasswordProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    onChange((prevState: EditGroupCompanyFormParams) => ({ ...prevState, password }));
  };

  return (
    <TextField
      fullWidth
      size="small"
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      onChange={handleChange}
      value={formParams.password}
      id="outlined-basic-password"
      variant="outlined"
    />
  );
};

export default EditGroupCompanyTextfieldPassword;
