import * as Styles from './styles';
import { Loader } from 'components';
import request from 'library/request';
import { ChangeEvent, useState } from 'react';
import Authentication from 'utils/authentication';
import { renderFormattedTime } from 'utils/format-time';
import { Grid, Box, Paper, Tooltip } from '@mui/material';
import { IntegrationByFilter } from 'library/request/types';
import { CommentBlock } from 'containers/elements/@commons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { renderFormattedPriority } from 'utils/format-priority';
import { setIsDrawerOpen } from 'store/features/drawer/drawer.slice';
import { ChangeUser, ChangeActivity, ChangeStatus } from './components';
import {
  selectRows,
  selectSelectedCompanyIntegrationId,
} from 'store/features/drawer-integrations/drawer-integrations.selector';
import { selectLoadingUpdate } from 'store/features/drawer/drawer.selector';

const { commentOut } = request;

const DrawerContent = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();
  const loadingUpdate = useAppSelector(selectLoadingUpdate);
  const rows = useAppSelector(selectRows) as IntegrationByFilter[];
  const id = useAppSelector(selectSelectedCompanyIntegrationId) as number;

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const [selectedRow] = rows.filter((row) => row.id === id);
  const disabled = comment === '';

  const handleSubmit = () => {
    setLoading(true);
    commentOut({ companyIntegrationId: selectedRow.id, description: comment }).then((res) => {
      setComment('');
      setLoading(false);
    });
  };

  if (!selectedRow) {
    dispatch(setIsDrawerOpen(false));
    return <></>;
  }

  return (
    <Box
      sx={{ width: { md: '50vw', xs: '75vw' }, height: '99vh', paddingLeft: '2px' }}
      role="presentation"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          dispatch(setIsDrawerOpen(false));
        }
      }}>
      <Grid container direction={'column'}>
        <Grid item xs={12} md={12}>
          <Paper>
            <Styles.HeaderContainer>
              <Styles.HeaderSpan>
                {`${selectedRow.companyGroupName.toUpperCase()} - ${selectedRow.companyName.toUpperCase()} - ${
                  selectedRow.taxNumber
                }`}
              </Styles.HeaderSpan>
            </Styles.HeaderContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper sx={{ width: '100%', margin: '10px 0px', paddingLeft: '20px' }}>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Styles.DetailSpan>
                  <Styles.DetailSpanTitleSpan>UID:</Styles.DetailSpanTitleSpan>&nbsp;
                  {selectedRow.uid}
                </Styles.DetailSpan>
              </Grid>

              <Grid item xs={12}>
                <Styles.DetailSpan>
                  <Styles.DetailSpanTitleSpan>Banka:</Styles.DetailSpanTitleSpan>&nbsp;
                  {`${selectedRow.bankName} - ${selectedRow.bankCode}`}
                </Styles.DetailSpan>
              </Grid>

              <Grid item xs={12}>
                <Styles.DetailSpan>
                  <Styles.DetailSpanTitleSpan>Hata:</Styles.DetailSpanTitleSpan>&nbsp;
                  {selectedRow.errorCode ? (
                    <>
                      <Tooltip arrow followCursor placement="top" title={selectedRow.errorCodeDescription}>
                        <span>{selectedRow.errorCode}</span>
                      </Tooltip>
                      <span>&nbsp;&nbsp;</span>
                      <span>{renderFormattedPriority(selectedRow.alertPriority)}</span>
                    </>
                  ) : (
                    '-'
                  )}
                </Styles.DetailSpan>
              </Grid>

              <Grid item xs={12}>
                <Styles.DetailSpan>
                  <Styles.DetailSpanTitleSpan>Tarih:</Styles.DetailSpanTitleSpan>&nbsp;
                  {renderFormattedTime(selectedRow.errorDate)}
                </Styles.DetailSpan>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Styles.FullMessageContainer>
            <Paper sx={{ marginBottom: '10px', paddingBottom: '5px' }}>
              <Styles.MessageContentContainer>
                <Styles.MessageContent>
                  <Styles.DetailSpanTitleSpan>Açıklama:</Styles.DetailSpanTitleSpan>&nbsp;
                  {selectedRow.message}
                </Styles.MessageContent>
              </Styles.MessageContentContainer>
            </Paper>
          </Styles.FullMessageContainer>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper sx={{ width: '100%', height: '16vh', margin: '10px 0px', padding: '0px 0px 10px 10px' }}>
            <Grid container spacing={1}>
              <Grid item container>
                <Grid item xs={5} md={2}>
                  <Styles.OperationSpan>Kullanıcıya ata:</Styles.OperationSpan>
                </Grid>

                <Grid item xs={6} md={4}>
                  <ChangeUser role={loggedUser.role} />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={5} md={2}>
                  <Styles.OperationSpan>Aktivite değiştir:</Styles.OperationSpan>
                </Grid>

                <Grid item xs={6} md={4}>
                  <ChangeActivity role={loggedUser.role} />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={5} md={2}>
                  <Styles.OperationSpan>Durum değiştir:</Styles.OperationSpan>
                </Grid>

                <Grid item xs={6} md={4}>
                  <ChangeStatus role={loggedUser.role} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper>
            <Styles.CommentsContainer>
              <CommentBlock
                loadingSubmit={loading}
                loadingUpdate={loadingUpdate}
                companyIntegrationId={selectedRow.id}
              />
            </Styles.CommentsContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          {loading ? (
            <Styles.CommentOutContainer>
              <Loader />
            </Styles.CommentOutContainer>
          ) : (
            <Styles.CommentOutContainer>
              <Styles.InputContainer
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
                placeholder="Yorum Ekle"
              />
              <Styles.ButtonContainer disabled={disabled} onClick={() => handleSubmit()} type="button">
                Yorum Yap
              </Styles.ButtonContainer>
            </Styles.CommentOutContainer>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DrawerContent;
