import { Company } from 'library/request/types';

export const getDistincTaxNumber = (selectedCompanies: Company[]): string[] => {
  const taxNumbersSet = new Set<string>();

  selectedCompanies.forEach((selectedCompany) => {
    if (selectedCompany.type === 'node' && selectedCompany.companies) {
      selectedCompany.companies.forEach((company) => {
        taxNumbersSet.add(company.taxNumber as string);
      });
    } else {
      taxNumbersSet.add(selectedCompany.taxNumber as string);
    }
  });

  return [...taxNumbersSet];
};
