import { ReactNode } from 'react';
import { RuleSet } from 'styled-components';
import { RowOne, RowTwo, RowThree, RowFour, WidgetContainer, WidgetTitleContainer } from './styles';

type WidgetProps = {
  row?: number;
  title?: string;
  children: ReactNode;
  $additionalStyle?: RuleSet;
  $additionalRowStyle?: RuleSet;
};

const rowMap = [RowOne, RowTwo, RowThree, RowFour];
const renderRow = (rowNumber: number) => rowMap[rowNumber - 1];

const Widget = ({ children, title, $additionalRowStyle, $additionalStyle, row = 1 }: WidgetProps) => {
  const CustomRow = renderRow(row);

  return (
    <WidgetContainer $additionalStyle={$additionalStyle}>
      {title && <WidgetTitleContainer>{title}</WidgetTitleContainer>}
      <CustomRow $additionalRowStyle={$additionalRowStyle}>{children}</CustomRow>
    </WidgetContainer>
  );
};

export default Widget;
