import request from 'library/request';
import { StyledPaper } from './styles';
import {
  EditUserTextfieldName,
  EditUserTextfieldEmail,
  EditUserSelectRoleType,
  EditUserTextfieldSurname,
  EditUserSelectStatusType,
  EditUserTextfieldPassword,
  EditUserCheckboxUseTelegram,
  EditUserSelectGroupCompanies,
  EditUserTextfieldTelegramUserId,
  EditUserTextfieldTelegramUserName,
} from 'containers/elements/users-edit';
import { useNavigate } from 'react-router-dom';
import { Widget, Card, Text } from 'components';
import Authentication from 'utils/authentication';
import { Box, Grid, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import { ROLE_API_NAME_ENUM, ROLE_TYPE_ENUM } from 'enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import usersActions from 'store/features/users/users.actions';
import FormButtons from 'containers/elements/@commons/form-buttons';
import { selectUserWithGroupCompanies } from 'store/features/update-user/update-user.selector';

const { updateUser } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Kullanıcılar',
    path: '/users',
  },
  {
    name: 'Kullanıcı Düzenle',
    path: '/users/edit',
  },
];

export type EditUserFormParams = {
  name: string;
  email: string;
  surname: string;
  userName: string;
  roleType: number;
  statusType: number;
  password: string | null;
  companyGroupIds: number[];
  telegramUserName: string;
  telegramUserId: string;
  useTelegram: boolean;
};

const UsersEdit = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(selectUserWithGroupCompanies);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<EditUserFormParams>({
    password: '',
    companyGroupIds: selectedUser?.companyGroupIds ? selectedUser.companyGroupIds : [],
    name: selectedUser?.name || '',
    email: selectedUser?.email || '',
    surname: selectedUser?.surname || '',
    userName: selectedUser?.userName || '',
    roleType: selectedUser?.roleType || 1,
    statusType: selectedUser?.statusType || 1,
    telegramUserName: selectedUser?.telegramUserName || '',
    telegramUserId: selectedUser?.telegramUserId || '',
    useTelegram: selectedUser?.useTelegram || false,
  });

  const onClear = () => {
    setFormParams({
      password: '',
      companyGroupIds: [],
      name: selectedUser?.name || '',
      email: selectedUser?.email || '',
      surname: selectedUser?.surname || '',
      userName: selectedUser?.userName || '',
      roleType: selectedUser?.roleType || 1,
      statusType: selectedUser?.statusType || 1,
      telegramUserName: selectedUser?.telegramUserName || '',
      telegramUserId: selectedUser?.telegramUserId || '',
      useTelegram: selectedUser?.useTelegram || false,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.name === '' || formParams.surname === '' || formParams.email === '') {
      setErrorMessage(
        formParams.name === ''
          ? 'Ad boş geçilemez.'
          : formParams.surname === ''
          ? 'Soyad boş geçilemez.'
          : formParams.email === ''
          ? 'E-Posta boş geçilemez.'
          : ''
      );
      return setError(true);
    }

    if (formParams.useTelegram && (formParams.telegramUserName === '' || formParams.telegramUserId === '')) {
      setErrorMessage('Varsayılan telegram olarak seçili iken telegram kullanıcı adı veya id boş bırakılamaz.');
      return setError(true);
    }

    setLoading(true);

    updateUser(formParams)
      .then((res) => {
        if (res.message === 'Bazı hatalar oluştu') {
          setErrorMessage(res.validationErrors?.[0].errorMessage as string);
          setError(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        dispatch(usersActions.getUsers());
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (selectedUser) {
      setFormParams((prevState) => ({
        ...prevState,
        password: '',
        companyGroupIds: selectedUser.companyGroupIds ? selectedUser.companyGroupIds : [],
        name: selectedUser.name,
        email: selectedUser.email,
        surname: selectedUser.surname,
        userName: selectedUser.userName,
        roleType: selectedUser.roleType,
        statusType: selectedUser.statusType,
        telegramUserName: selectedUser.telegramUserName,
        telegramUserId: selectedUser.telegramUserId,
        useTelegram: selectedUser.useTelegram,
      }));
    }
  }, [selectedUser]);

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  useEffect(() => {
    if (formParams.roleType === 2) {
      setFormParams((prevState) => ({ ...prevState, companyGroupIds: [] }));
    }
  }, [formParams.roleType]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Kullanıcı Düzenle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Ad</span>
                  <StyledPaper>
                    <EditUserTextfieldName onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Soyad</span>
                  <StyledPaper>
                    <EditUserTextfieldSurname onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Rol</span>
                  <StyledPaper>
                    <EditUserSelectRoleType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Parola</span>
                  <StyledPaper>
                    <EditUserTextfieldPassword onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>E-Posta</span>
                  <StyledPaper>
                    <EditUserTextfieldEmail onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Durum</span>
                  <StyledPaper>
                    <EditUserSelectStatusType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Telegram Kullanıcı Adı</span>
                  <StyledPaper>
                    <EditUserTextfieldTelegramUserName onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Telegram Kullanıcı Id</span>
                  <StyledPaper>
                    <EditUserTextfieldTelegramUserId onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>

                {formParams.roleType !== ROLE_TYPE_ENUM.ADMIN ? (
                  <Grid item xs={12} md={4}>
                    <span>Grup Şirketler</span>
                    <StyledPaper>
                      <EditUserSelectGroupCompanies onChange={setFormParams} formParams={formParams} />
                    </StyledPaper>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4}>
                    <span>Grup Şirketler</span>
                    <StyledPaper>
                      <TextField
                        sx={{
                          '& div': {
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: 'medium',
                          },
                        }}
                        size="small"
                        fullWidth
                        id="outlined-read-only-input-groupCompaniesAll"
                        defaultValue={'Tüm Grup Şirketler İçin Yetki Verilecektir'}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </StyledPaper>
                  </Grid>
                )}

                <Grid item xs={12} md={12}>
                  <EditUserCheckboxUseTelegram onChange={setFormParams} formParams={formParams} />
                </Grid>
              </Grid>

              <FormButtons
                loading={loading}
                actionName={'Kaydet'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default UsersEdit;
