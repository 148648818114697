import { Loader } from 'components';
import { ErrorCode } from 'library/request/types';
import { FilterParams } from 'pages/integrations';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Checkbox, TextField, Autocomplete } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { selectErrorCodesMap, selectIsLoading } from 'store/features/error-codes/error-codes.selector';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectErrorCodesProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const SelectErrorCodes = ({ onChange, filterParams }: SelectErrorCodesProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const errorCodeMap = useAppSelector(selectErrorCodesMap);

  const [selectedErrorCodes, setSelectedErrorCodes] = useState<ErrorCode[]>([]);

  useEffect(() => {
    if (!errorCodeMap.length) {
      dispatch(errorCodesActions.getCodes());
    }
  }, [errorCodeMap]);

  useEffect(() => {
    if (!filterParams.errorCodes.length) {
      setSelectedErrorCodes([]);
    } else {
      if (!Array.isArray(filterParams.errorCodes)) return;

      const errorCodes = filterParams.errorCodes.map((errorCode) =>
        errorCodeMap.find((errCode) => errCode.code === errorCode)
      );
      setSelectedErrorCodes(errorCodes as ErrorCode[]);
    }
  }, [filterParams.errorCodes]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      multiple
      value={selectedErrorCodes}
      size="small"
      id="checkboxes-tags-demo"
      options={errorCodeMap}
      disableCloseOnSelect
      onChange={(event: any, newValue: ErrorCode[]) => {
        setSelectedErrorCodes(newValue);

        const errorCodes = newValue.map((value) => value.code);
        onChange((prevState: FilterParams) => ({ ...prevState, errorCodes }));
      }}
      getOptionLabel={(option) => option.code}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.code}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Hata Kodu" />}
      ListboxProps={{
        style: {
          maxHeight: '220px',
        },
      }}
    />
  );
};

export default SelectErrorCodes;
