import { Loader } from 'components';
import { ErrorCode } from 'library/request/types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Checkbox, TextField, Autocomplete } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ErrorLogsFilterParams } from 'containers/elements/dashboard/error-logs-table';
import { selectErrorCodesMap, selectIsLoading } from 'store/features/error-codes/error-codes.selector';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectErrorCodesProps = {
  onChange: Dispatch<SetStateAction<ErrorLogsFilterParams>>;
  filterParams: ErrorLogsFilterParams;
};

const SelectErrorCodes = ({ onChange, filterParams }: SelectErrorCodesProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const errorCodeMap = useAppSelector(selectErrorCodesMap);

  const [selectedErrorCodes, setSelectedErrorCodes] = useState<ErrorCode[]>([]);

  useEffect(() => {
    if (!errorCodeMap.length) {
      dispatch(errorCodesActions.getCodes());
    }
  }, [errorCodeMap]);

  useEffect(() => {
    if (!filterParams.errorCodes.length) {
      setSelectedErrorCodes([]);
    }
  }, [filterParams.errorCodes]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      limitTags={1}
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
          height: '32px',
        },
        '& fieldset': {
          borderColor: '#00000080',
        },
        '& label': {
          lineHeight: 1,
        },
      }}
      multiple
      value={selectedErrorCodes}
      size="small"
      id="checkboxes-tags-demo"
      options={errorCodeMap}
      disableCloseOnSelect
      onChange={(event: any, newValue: ErrorCode[]) => {
        setSelectedErrorCodes(newValue);

        const errorCodes = newValue.map((value) => value.code);
        onChange((prevState: ErrorLogsFilterParams) => ({ ...prevState, errorCodes }));
      }}
      getOptionLabel={(option) => option.code}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.code}
        </li>
      )}
      renderInput={(params) => {
        const text =
          selectedErrorCodes.length === 0 ? null : selectedErrorCodes.length === 1 ? (
            <span style={{ paddingLeft: 10, paddingBottom: 10 }}>{selectedErrorCodes[0].code}</span>
          ) : (
            <span style={{ paddingLeft: 10, paddingBottom: 10 }}>{`${selectedErrorCodes.length} Kod seçili`}</span>
          );

        return <TextField {...params} InputProps={{ ...params.InputProps, startAdornment: text }} label="Hata Kodu" />;
      }}
      ListboxProps={{
        style: {
          maxHeight: '220px',
        },
      }}
    />
  );
};

export default SelectErrorCodes;
