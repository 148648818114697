import { Loader } from 'components';
import { ErrorCode } from 'library/request/types';
import { Autocomplete, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ErrorCodesFilterParams } from 'pages/error-codes-main';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';
import { selectErrorCodesMap, selectIsLoading } from 'store/features/error-codes/error-codes.selector';

const allItem: ErrorCode = {
  code: 'Tümü',
  description: 'Tümü',
  alertPriority: 0,
  createdDate: null,
  updatedDate: null,
};

type ErrorCodesSelecErrorCodesProps = {
  onChange: Dispatch<SetStateAction<ErrorCodesFilterParams>>;
  filterParams: ErrorCodesFilterParams;
};

const ErrorCodesSelectErrorCodes = ({ onChange, filterParams }: ErrorCodesSelecErrorCodesProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const errorCodeMap = useAppSelector(selectErrorCodesMap);
  const errorCodes = [allItem, ...errorCodeMap];

  const [selectedErrorCode, setSelectedErrorCode] = useState<ErrorCode>(allItem);

  useEffect(() => {
    if (!errorCodeMap.length) {
      dispatch(errorCodesActions.getCodes());
    }
  }, [errorCodeMap]);

  useEffect(() => {
    if (!filterParams.code) {
      setSelectedErrorCode(allItem);
    }
  }, [filterParams.code]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      size="small"
      value={selectedErrorCode}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => option.code}
      disablePortal
      id="combo-box-demo"
      options={errorCodes}
      onChange={(event: any, newValue: ErrorCode | null) => {
        const value = newValue ?? allItem;
        setSelectedErrorCode(value);

        const code = value.code === 'Tümü' ? '' : value.code;
        onChange((prevState: ErrorCodesFilterParams) => ({ ...prevState, code }));
      }}
      renderInput={(params) => <TextField {...params} label="Hata Kodu" />}
      ListboxProps={{
        style: {
          maxHeight: '155px',
        },
      }}
    />
  );
};

export default ErrorCodesSelectErrorCodes;
