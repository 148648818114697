import Loader from 'components/loader';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { Company } from 'library/request/types';
import { CompanySelectButtonContainer } from './styles';
import { selectLoading, selectSelectedCompanies } from 'store/features/select-companies/select-companies.selector';

const CompanySelectLabel = () => {
  const [label, setLabel] = useState('');
  const loading = useAppSelector(selectLoading);
  const selectedCompanies = useAppSelector(selectSelectedCompanies);

  const getLabelFromSelectedCompanies = (selectedCompanies: Company[]): string => {
    if (selectedCompanies.length > 1) {
      const mainCompany = selectedCompanies.filter((i) => i.isNode).length;
      const subCompany = selectedCompanies.filter((i) => i.isLeaf).length;

      const labelItem = mainCompany > 0 ? `${mainCompany} Şirket` : '';

      const labelItem2 = subCompany > 0 ? `${subCompany} Alt Şirket` : '';
      const and = mainCompany > 0 && subCompany > 0 ? 've' : '';

      return `${labelItem} ${and} ${labelItem2} seçildi`;
    }

    const selectedCompanyName =
      selectedCompanies?.[0]?.title === 'Tüm Şirketler' ? 'Tüm Şirketler' : selectedCompanies?.[0]?.title;

    return selectedCompanyName;
  };

  useEffect(() => {
    const labelText = getLabelFromSelectedCompanies(selectedCompanies);
    setLabel(labelText);
  }, [selectedCompanies]);

  if (loading) {
    return <Loader />;
  }

  return (
    <CompanySelectButtonContainer>
      <p>
        <abbr title={label}>{label}</abbr>
      </p>
    </CompanySelectButtonContainer>
  );
};

export default CompanySelectLabel;
