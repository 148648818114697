import request from 'library/request';
import Authentication from 'utils/authentication';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { getUsersMap, getUserByUserName } = request;

const getUsers = createAsyncThunk('getUsers', async () => {
  const { result: users } = await getUsersMap({ pageNumber: 1, pageSize: 50 });
  const formattedUsers = users.map((user) => ({ value: user.userName, label: user.label as string }));

  return formattedUsers;
});

const logUser = createAsyncThunk('logUser', async (userName: string) => {
  const loggedUser = Authentication.getLoggedUser();
  const user = await getUserByUserName({ userName });

  Authentication.setLoggedUser({
    ...loggedUser,
    email: user.email,
    name: user.name,
    surname: user.surname,
    roleType: user.roleType,
    statusType: user.statusType,
    companyGroupIds: user.companyGroupIds ? user.companyGroupIds : [],
    telegramUserName: user.telegramUserName,
    telegramUserId: user.telegramUserId,
    useTelegram: user.useTelegram,
  });
});

const usersActions = {
  getUsers,
  logUser,
};

export default usersActions;
