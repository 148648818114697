import { css, styled } from 'styled-components';
import { red2 } from '../../assets/style-helpers/colors';

export const additionalLogoStyle = css`
  margin: 0 auto;
  padding: 16px 0px;

  > img {
    height: 64px;
  }
`;

export const additionalWidgetStyle = css`
  justify-content: center;
  align-items: center;
`;

export const additionalButtonStyle = css`
  padding: 20px 0px !important;
  background-color: #0f62fe;

  &:hover {
    background-color: #0f62fecc;
  }
`;

export const LoginTextContainer = styled.div`
  > div {
    color: white;
  }
`;

export const ErrorTextContainer = styled.div`
  > div {
    color: ${red2};
    text-align: center;
    margin: 16px 0px;
  }
`;
