import { useState } from 'react';
import { renderFormattedTime } from '..';
import { useNavigate } from 'react-router-dom';
import { StyledTableCell, StyledTableRow } from './styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Company, CompanyGroup } from 'library/request/types';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import { getDistincTaxNumber } from 'utils/format-tax-numbers';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { renderFormattedIconCompany } from 'utils/format-icon-company';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { onFilterCompany } from 'store/features/select-companies/select-companies.slice';
import { selectCompanies } from 'store/features/select-companies/select-companies.selector';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableHead, Tooltip, Typography } from '@mui/material';

type RowByGroupCompanyProps = {
  row: CompanyGroup;
  errorCode: string;
};

const RowByGroupCompany = ({ row, errorCode }: RowByGroupCompanyProps) => {
  // other hooks
  const navigate = useNavigate();

  // redux hooks
  const dispatch = useAppDispatch();
  const formattedCompanies = useAppSelector(selectCompanies);

  // useState hooks
  const [open, setOpen] = useState(false);

  const company = formattedCompanies.find(
    (formattedCompany) => formattedCompany.title === row.companyGroupName
  ) as Company;

  const onClickNavigateHandler = () => {
    navigate('/integrations', {
      state: {
        errorCodes: [errorCode],
        taxNumbers: getDistincTaxNumber([company]),
      },
    });
    dispatch(onFilterCompany([company]));
  };

  return (
    <>
      <StyledTableRow
        sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: open ? '#f2f2f2' : 'inherit' }}
        onClick={() => setOpen(!open)}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {renderFormattedIconCompany(row.companyGroupName, row.companyGroupUsername)}
        </StyledTableCell>
        <StyledTableCell align="center">{row.totalErrorCount}</StyledTableCell>
        <StyledTableCell align="center">{row.totalSolutionCount}</StyledTableCell>
        <StyledTableCell align="center">{renderFormattedTime(row.avarageSolutionTime)}</StyledTableCell>
        <Tooltip arrow followCursor placement="top" title="Detaya Git">
          <StyledTableCell>
            <IconButton size="small" onClick={onClickNavigateHandler}>
              {<LanOutlinedIcon />}
            </IconButton>
          </StyledTableCell>
        </Tooltip>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          sx={{ backgroundColor: open ? '#f2f2f2' : 'inherit' }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ cursor: 'default' }}>
                Banka Bazında
              </Typography>
              <Table aria-label="purchases">
                <TableHead sx={{ borderRadius: '12px' }} component={Paper}>
                  <StyledTableRow>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Banka
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Toplam Hata Sayısı
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Toplam Çözüm Sayısı
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                        cursor: 'default',
                      }}
                      align="center">
                      Ort. Çözüm Süresi
                    </StyledTableCell>
                    <StyledTableCell />
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row.banks.map((bank, index) => {
                    const onClickNavigateHandler = () => {
                      navigate('/integrations', {
                        state: {
                          errorCodes: [errorCode],
                          taxNumbers: getDistincTaxNumber([company]),
                          bankCodes: [String(bank.bankCode)],
                        },
                      });
                      dispatch(onFilterCompany([company]));
                    };

                    return (
                      <StyledTableRow key={index.toString()}>
                        <Tooltip arrow followCursor placement="top" title={`Banka Kodu: ${bank.bankCode}`}>
                          <StyledTableCell component="th" scope="row" align="center" sx={{ cursor: 'default' }}>
                            {bank.bankName}
                          </StyledTableCell>
                        </Tooltip>
                        <StyledTableCell align="center" sx={{ cursor: 'default' }}>
                          {bank.totalErrorCount}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ cursor: 'default' }}>
                          {bank.totalSolutionCount}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ cursor: 'default' }}>
                          {renderFormattedTime(bank.avarageSolutionTime)}
                        </StyledTableCell>
                        <Tooltip arrow followCursor placement="top" title="Detaya Git">
                          <StyledTableCell>
                            <IconButton size="small" onClick={onClickNavigateHandler}>
                              {<LanOutlinedIcon />}
                            </IconButton>
                          </StyledTableCell>
                        </Tooltip>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default RowByGroupCompany;
