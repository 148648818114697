import request from 'library/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { getCompaniesMap } = request;

const initialize = createAsyncThunk('initialize', async () => {
  const companies = await getCompaniesMap();
  const selectedCompanies = companies.filter((company) => company.isAll);
  const selectedGroupCompanies = companies.filter((groupCompany) => groupCompany.isNode);

  return { companies, selectedCompanies, selectedGroupCompanies };
});

const selectCompaniesActions = {
  initialize,
};

export default selectCompaniesActions;
