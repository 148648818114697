import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { SERVER_TYPE_ENUM, getServerTranslated } from 'enums';
import { AddGroupCompanyFormParams } from 'pages/group-companies-add';

type AddGroupCompanySelectServerTypeProps = {
  onChange: Dispatch<SetStateAction<AddGroupCompanyFormParams>>;
  formParams: AddGroupCompanyFormParams;
};

const AddGroupCompanySelectServerType = ({ onChange, formParams }: AddGroupCompanySelectServerTypeProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const serverType = Number(event.target.value);
    onChange((prevState: AddGroupCompanyFormParams) => ({ ...prevState, serverType }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-server-type-dialog-label"
      id="select-server-type-dialog"
      value={String(formParams.serverType)}
      defaultValue={String(formParams.serverType)}
      onChange={handleChange}>
      {(Object.keys(SERVER_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof SERVER_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={SERVER_TYPE_ENUM[key]}>
            {getServerTranslated(SERVER_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default AddGroupCompanySelectServerType;
