import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { AddErrorCodeFormParams } from 'pages/error-codes-add';

type AddErrorCodeTextfieldCodeProps = {
  onChange: Dispatch<SetStateAction<AddErrorCodeFormParams>>;
  formParams: AddErrorCodeFormParams;
  error: boolean;
};

const AddErrorCodeTextfieldCode = ({ onChange, formParams, error }: AddErrorCodeTextfieldCodeProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;
    onChange((prevState: AddErrorCodeFormParams) => ({ ...prevState, code }));
  };
  return (
    <TextField
      fullWidth
      size="small"
      sx={
        error && !formParams.code
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.code}
      id="outlined-basic-code"
      variant="outlined"
    />
  );
};

export default AddErrorCodeTextfieldCode;
