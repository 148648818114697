import { Dispatch, SetStateAction } from 'react';
import { SERVER_TYPE_ENUM, getServerTranslated } from 'enums';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, InputLabel, FormControl } from '@mui/material';
import { GroupCompaniesFilterParams } from 'pages/group-companies-main';

type GroupCompaniesSelectServerTypeProps = {
  onChange: Dispatch<SetStateAction<GroupCompaniesFilterParams>>;
  filterParams: GroupCompaniesFilterParams;
};

const GroupCompaniesSelectServerType = ({ onChange, filterParams }: GroupCompaniesSelectServerTypeProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const serverType = event.target.value === 'Tümü' ? null : Number(event.target.value);
    onChange((prevState: GroupCompaniesFilterParams) => ({ ...prevState, serverType }));
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="select-server-type-group-companies-label">
        Sunucu
      </InputLabel>
      <Select
        sx={{
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        }}
        size="small"
        labelId="select-server-type-group-companies-label"
        id="select-server-type-group-companies"
        value={filterParams.serverType ? String(filterParams.serverType) : 'Tümü'}
        label="Sunucu"
        defaultValue={filterParams.serverType ? String(filterParams.serverType) : 'Tümü'}
        onChange={handleChange}>
        <MenuItem value={'Tümü'}>Tümü</MenuItem>
        {(Object.keys(SERVER_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof SERVER_TYPE_ENUM)[]).map(
          (key, index) => (
            <MenuItem key={index.toString()} value={SERVER_TYPE_ENUM[key]}>
              {getServerTranslated(SERVER_TYPE_ENUM[key])}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default GroupCompaniesSelectServerType;
