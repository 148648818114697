import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useAppSelector } from 'store/hooks';
import { SortType } from 'pages/integrations';
import { useNavigate } from 'react-router-dom';
import {
  NotificationPanelTable,
  NotificationPanelFilters,
  NotificationPanelDeletePopUp,
} from 'containers/elements/notification-panel';
import Authentication from 'utils/authentication';
import { Card, Loader, Widget } from 'components';
import { useEffect, useCallback, useState } from 'react';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { NotificationRuleByFilter } from 'library/request/types';
import { RightItemContainer, TextSpan, AddNotificationRuleIcon } from './styles';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { selectLoadingUpdate } from 'store/features/update-notification-rule/update-notification-rule.selector';

const { getNotificationRulesMapByFilter } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Bildirim Paneli',
    path: '/notification-panel',
  },
];

export type NotificationPanelFilterParams = {
  pageSize: number;
  selector: string;
  pageNumber: number;
  statusType: number | null;
  orderByType: number | null;
  alertPriority: string | number | null;
};

const NotificationPanelMain = () => {
  const loggedUser = Authentication.getLoggedUser();

  const loadingUpdate = useAppSelector(selectLoadingUpdate);

  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);
  const goToAddNotificationRuleHandler = useCallback(() => navigate('add'), []);

  const [loading, setLoading] = useState(false);
  const [totalNotificationRule, setTotalNotificationRule] = useState(0);
  const [notificationRuleList, setNotificationRuleList] = useState<NotificationRuleByFilter[]>([]);
  const [filterParams, setFilterParams] = useState<NotificationPanelFilterParams>({
    pageSize: 20,
    selector: '',
    pageNumber: 1,
    statusType: null,
    orderByType: null,
    alertPriority: null,
  });

  const onClear = () => {
    setFilterParams({
      pageSize: 20,
      selector: '',
      pageNumber: 1,
      statusType: null,
      orderByType: null,
      alertPriority: null,
    });
  };

  const onApplyFilter = () => {
    setLoading(true);
    getNotificationRulesMapByFilter(filterParams).then((res) => {
      setTotalNotificationRule(res.totalCount);
      setNotificationRuleList(res.result);
      setLoading(false);
    });
  };

  const onApplySortFilter = async ({ selector, orderByType }: SortType) => {
    setLoading(true);
    try {
      const response = await getNotificationRulesMapByFilter({ ...filterParams, selector, orderByType });

      setTotalNotificationRule(response.totalCount);
      setNotificationRuleList(response.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) => {
    setLoading(true);
    getNotificationRulesMapByFilter({ ...filterParams, pageSize, pageNumber }).then((res) => {
      setTotalNotificationRule(res.totalCount);
      setNotificationRuleList(res.result);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getNotificationRulesMapByFilter(filterParams).then((res) => {
      setTotalNotificationRule(res.totalCount);
      setNotificationRuleList(res.result);
      setLoading(false);
    });
  }, [loadingUpdate]);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'notification-panel');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Bildirim Paneli" breadcrumbs={breadcrumbs} />
      </Widget>
      <NotificationPanelFilters
        onClear={onClear}
        filterParams={filterParams}
        onApplyFilter={onApplyFilter}
        setFilterParams={setFilterParams}
      />
      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Card
            leftItem={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? <div /> : null}
            rightItem={
              loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? (
                <RightItemContainer onClick={goToAddNotificationRuleHandler}>
                  <AddNotificationRuleIcon />
                  <TextSpan>Bildirim Ekle</TextSpan>
                </RightItemContainer>
              ) : null
            }>
            <NotificationPanelTable
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              notificationRuleList={notificationRuleList}
              totalNotificationRule={totalNotificationRule}
              onApplySortFilter={onApplySortFilter}
              onApplyPaginationFilter={onApplyPaginationFilter}
            />
          </Card>
        )}
      </Widget>
      <NotificationPanelDeletePopUp />
    </>
  );
};

export default NotificationPanelMain;
