import { RuleSet } from 'styled-components';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { ButtonContainer, ButtonWrapperContainer } from './styles';

type ButtonProps = {
  children: ReactNode;
  $additionalStyle?: RuleSet;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, $additionalStyle, onClick = () => {}, ...props }: ButtonProps) => {
  return (
    <ButtonWrapperContainer type="button" onClick={onClick} {...props}>
      <ButtonContainer $additionalStyle={$additionalStyle}>{children}</ButtonContainer>
    </ButtonWrapperContainer>
  );
};

export default Button;
