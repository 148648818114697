import { Dispatch, SetStateAction } from 'react';
import { EditUserFormParams } from 'pages/users-edit';
import { Checkbox, FormControlLabel } from '@mui/material';

type EditUserCheckboxUseTelegramProps = {
  onChange: Dispatch<SetStateAction<EditUserFormParams>>;
  formParams: EditUserFormParams;
};

const EditUserCheckboxUseTelegram = ({ onChange, formParams }: EditUserCheckboxUseTelegramProps) => {
  const handleChange = (event: any) => {
    const useTelegram = event.target.checked;
    onChange((prevState) => ({ ...prevState, useTelegram }));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      labelPlacement="end"
      label="Bildirimler için varsayılan olarak telegram bilgilerim kullanılsın."
      checked={formParams.useTelegram}
      onChange={handleChange}
    />
  );
};

export default EditUserCheckboxUseTelegram;
