import { styled } from 'styled-components';
import TableRow from '@mui/material/TableRow';
import { SortDownSvg, SortUpSvg } from 'assets/icons';
import { styled as styledMui } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableCell = styledMui(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1e1e1e29',
    color: '#1e1e1e',
    fontFamily: '"Open Sans", sans-serif',
    height: '40px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: '"Open Sans", sans-serif',
  },
  '&.MuiTableCell-root': {
    padding: '12px 5px !important',
  },
}));

export const StyledTableRow = styledMui(TableRow)(() => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffffff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f7f7f7ff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}));

export const SortDownIcon = styled(SortDownSvg)`
  height: 16px;
  width: 16px;
`;

export const SortUpIcon = styled(SortUpSvg)`
  height: 16px;
  width: 16px;
`;
