import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { EditMyProfileFormParams } from 'pages/my-profile-edit';

type EditMyProfileTextfieldPasswordProps = {
  onChange: Dispatch<SetStateAction<EditMyProfileFormParams>>;
  formParams: EditMyProfileFormParams;
};

const EditMyProfileTextfieldPassword = ({ onChange, formParams }: EditMyProfileTextfieldPasswordProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    onChange((prevState: EditMyProfileFormParams) => ({ ...prevState, password }));
  };
  return (
    <TextField
      fullWidth
      size="small"
      type="password"
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      onChange={handleChange}
      value={formParams.password}
      id="outlined-basic-password"
      variant="outlined"
    />
  );
};

export default EditMyProfileTextfieldPassword;
