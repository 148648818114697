import { Dispatch, SetStateAction } from 'react';
import { STATUS_TYPE_ENUM, getStatusTranslated } from 'enums';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, InputLabel, FormControl } from '@mui/material';
import { GroupCompaniesFilterParams } from 'pages/group-companies-main';

type GroupCompaniesSelectStatusTypeProps = {
  onChange: Dispatch<SetStateAction<GroupCompaniesFilterParams>>;
  filterParams: GroupCompaniesFilterParams;
};

const GroupCompaniesSelectStatusType = ({ onChange, filterParams }: GroupCompaniesSelectStatusTypeProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const statusType = event.target.value === 'Tümü' ? null : Number(event.target.value);
    onChange((prevState: GroupCompaniesFilterParams) => ({ ...prevState, statusType }));
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="select-status-type-group-companies-label">
        Durum
      </InputLabel>
      <Select
        sx={{
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        }}
        size="small"
        labelId="select-status-type-group-companies-label"
        id="select-status-type-group-companies"
        value={filterParams.statusType ? String(filterParams.statusType) : 'Tümü'}
        label="Durum"
        defaultValue={filterParams.statusType ? String(filterParams.statusType) : 'Tümü'}
        onChange={handleChange}>
        <MenuItem value={'Tümü'}>Tümü</MenuItem>
        {(Object.keys(STATUS_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof STATUS_TYPE_ENUM)[]).map(
          (key, index) => (
            <MenuItem key={index.toString()} value={STATUS_TYPE_ENUM[key]}>
              {getStatusTranslated(STATUS_TYPE_ENUM[key])}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default GroupCompaniesSelectStatusType;
