import { combineReducers } from '@reduxjs/toolkit';
import usersReducer from './features/users/users.slice';
import banksReducer from './features/banks/banks.slice';
import drawerReducer from './features/drawer/drawer.slice';
import updateUserReducer from './features/update-user/update-user.slice';
import errorCodesReducer from './features/error-codes/error-codes.slice';
import selectCompaniesReducer from './features/select-companies/select-companies.slice';
import drawerSolutionsReducer from './features/drawer-solutions/drawer-solutions.slice';
import updateErrorCodeReducer from './features/update-error-code/update-error-code.slice';
import drawerIntegrationsReducer from './features/drawer-integrations/drawer-integrations.slice';
import updateGroupCompanyReducer from './features/update-group-company/update-group-company.slice';
import updateNotificationRuleReducer from './features/update-notification-rule/update-notification-rule.slice';

const rootReducer = combineReducers({
  users: usersReducer,
  banks: banksReducer,
  drawer: drawerReducer,
  errorCodes: errorCodesReducer,
  updateUser: updateUserReducer,
  updateErrorCode: updateErrorCodeReducer,
  selectCompanies: selectCompaniesReducer,
  drawerSolutions: drawerSolutionsReducer,
  updateGroupCompany: updateGroupCompanyReducer,
  drawerIntegrations: drawerIntegrationsReducer,
  updateNotificationRule: updateNotificationRuleReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
