import { useAppDispatch } from 'store/hooks';
import { Dispatch, SetStateAction } from 'react';
import { ErrorSolution } from 'library/request/types';
import { SolutionsFilterParams } from 'pages/solutions';
import { formattedLongText } from 'utils/format-long-text';
import { StyledTableCell, StyledTableRow } from './styles';
import {
  setMarkedCommentId,
  setSelectedCompanyIntegrationId,
} from 'store/features/drawer-solutions/drawer-solutions.slice';
import { setIsDrawerOpen } from 'store/features/drawer/drawer.slice';
import { CustomPagination } from 'containers/elements/@commons';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import drawerSolutionsActions from 'store/features/drawer-solutions/drawer-solutions.actions';
import { Table, Paper, TableRow, TableBody, TableHead, TableFooter, TableContainer } from '@mui/material';

type TableSolutionsProps = {
  totalSolution: number;
  solutionList: ErrorSolution[];
  filterParams: SolutionsFilterParams;
  setFilterParams: Dispatch<SetStateAction<SolutionsFilterParams>>;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
};

const SolutionsTable = ({
  solutionList: rows,
  totalSolution,
  filterParams,
  setFilterParams,
  onApplyPaginationFilter,
}: TableSolutionsProps) => {
  const dispatch = useAppDispatch();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell width="75px" align="center">
              Hata Kodu
            </StyledTableCell>
            <StyledTableCell width="125px" align="center">
              Banka Adı
            </StyledTableCell>
            <StyledTableCell width="416px" align="center">
              Hata Mesajı
            </StyledTableCell>
            <StyledTableCell width="416px" align="center">
              Çözüm Yorumu
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!totalSolution ? (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell sx={{ cursor: 'default' }} colSpan={4}>
                Çözüm bulunmamaktadır.
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow
                  onClick={() => {
                    dispatch(drawerSolutionsActions.getIntegrationByCompanyIntegrationId(row.companyIntegrationId));
                    dispatch(setSelectedCompanyIntegrationId(row.companyIntegrationId));
                    dispatch(setMarkedCommentId(row.companyIntegrationUserCommentId));
                    dispatch(setIsDrawerOpen(true));
                  }}
                  key={index.toString()}>
                  <StyledTableCell align="center">{row.errorCode}</StyledTableCell>
                  <StyledTableCell align="center">{row.bankName}</StyledTableCell>
                  <StyledTableCell align="center">{formattedLongText(row.message, 100)}</StyledTableCell>
                  <StyledTableCell align="center">{formattedLongText(row.comment, 100)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow sx={{ cursor: 'default' }}>
                <CustomPagination
                  colSpan={4}
                  totalCount={totalSolution}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  onApplyPaginationFilter={onApplyPaginationFilter}
                />
              </StyledTableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default SolutionsTable;
