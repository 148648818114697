import { ErrorLogsFilterParams } from '../..';
import { MenuItem, Select } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { PERIOD_TYPE_ENUM, getPeriodTranslated } from 'enums';

type SelectPeriodProps = {
  onChange: Dispatch<SetStateAction<ErrorLogsFilterParams>>;
  filterParams: ErrorLogsFilterParams;
};

const SelectPeriod = ({ onChange, filterParams }: SelectPeriodProps) => {
  return (
    <Select
      sx={{
        width: '200px',
        height: '32px',
        borderRadius: '8px',
        '& .MuiInputBase-root': {
          height: '32px',
        },
        '& fieldset': {
          borderRadius: '8px',
          borderColor: '#00000080',
        },
      }}
      onChange={(event) => {
        const period = Number(event.target.value);
        onChange((prevState) => ({ ...prevState, period }));
      }}
      placeholder="Dönem"
      labelId="outlined-basic-period-label"
      id="outlined-basic-period"
      variant="outlined"
      value={String(filterParams.period)}
      defaultValue={String(filterParams.period)}>
      {(Object.keys(PERIOD_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof PERIOD_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={PERIOD_TYPE_ENUM[key]}>
            {getPeriodTranslated(PERIOD_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default SelectPeriod;
