import { Dispatch, SetStateAction } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { EditMyProfileFormParams } from 'pages/my-profile-edit';

type EditMyProfileCheckboxUseTelegramProps = {
  onChange: Dispatch<SetStateAction<EditMyProfileFormParams>>;
  formParams: EditMyProfileFormParams;
};

const EditMyProfileCheckboxUseTelegram = ({ onChange, formParams }: EditMyProfileCheckboxUseTelegramProps) => {
  const handleChange = (event: any) => {
    const useTelegram = event.target.checked;
    onChange((prevState) => ({ ...prevState, useTelegram }));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      labelPlacement="end"
      label="Bildirimler için varsayılan olarak telegram bilgilerim kullanılsın."
      checked={formParams.useTelegram}
      onChange={handleChange}
    />
  );
};

export default EditMyProfileCheckboxUseTelegram;
