import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Card, Widget, Text } from 'components';
import Authentication from 'utils/authentication';
import {
  TelegramForm,
  AddNotificationRuleSelectChannel,
  AddNotificationRuleSelectPriority,
  AddNotificationRuleSelectErrorCodes,
  AddNotificationRuleTextfieldDescription,
  AddNotificationRuleCheckboxNotNotifySupportStaff,
  AddNotificationRuleTextfieldFirstNotificationDelay,
  AddNotificationRuleTextfieldNotificationIntervalInHours,
  AddNotificationRuleTextfieldNotificationRecipientsEmails,
} from 'containers/elements/notification-panel-add';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import { CHANNEL_TYPE_ENUM, ROLE_API_NAME_ENUM } from 'enums';
import { TelegramNotificationModel } from 'library/request/types';
import FormButtons from 'containers/elements/@commons/form-buttons';

const { createNotificationRule } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Bildirim Paneli',
    path: '/notification-panel',
  },
  {
    name: 'Bildirim Ekle',
    path: '/notification-panel/add',
  },
];

export type AddNotificationRuleFormParams = {
  alertPriority: number | null;
  errorCodes: string[];
  firstNotificationDelayInMinutes: number;
  notificationIntervalInHours: number;
  notificationRecipientsEmails: string[];
  notificationType: number;
  telegramNotificationModels: TelegramNotificationModel[];
  description: string;
  notNotifySupportStaff: boolean;
};

const NotificationPanelAdd = () => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<AddNotificationRuleFormParams>({
    alertPriority: null,
    errorCodes: [],
    firstNotificationDelayInMinutes: 0,
    notificationIntervalInHours: 0,
    notificationRecipientsEmails: [],
    notificationType: 0,
    telegramNotificationModels: [],
    description: '',
    notNotifySupportStaff: false,
  });

  const onClear = () => {
    setFormParams({
      alertPriority: null,
      errorCodes: [],
      firstNotificationDelayInMinutes: 0,
      notificationIntervalInHours: 0,
      notificationRecipientsEmails: [],
      notificationType: 0,
      telegramNotificationModels: [],
      description: '',
      notNotifySupportStaff: false,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.firstNotificationDelayInMinutes <= 0 || formParams.notificationIntervalInHours <= 0) {
      setErrorMessage('İlk Bildirim Gecikme Aralığı ve Bildirim Sıklığı sıfırdan (0) büyük olmalıdır.');
      return setError(true);
    }

    if (
      formParams.telegramNotificationModels.some(
        (notificationModel) => !notificationModel.telegramUserName || !notificationModel.telegramUserId
      )
    ) {
      setErrorMessage('Telegram Kullanıcı Adı veya Id boş bırakılamaz.');
      return setError(true);
    }

    if (formParams.notificationType !== CHANNEL_TYPE_ENUM.TELEGRAM && !formParams.notificationRecipientsEmails.length) {
      setErrorMessage('En az 1 adet e-posta girilmelidir.');
      return setError(true);
    }

    setLoading(true);

    createNotificationRule(formParams)
      .then((res) => {
        setLoading(false);

        if (res.message === 'Bazı hatalar oluştu') {
          setErrorMessage(res.validationErrors?.[0].errorMessage as string);
          setError(true);
          return;
        }

        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  useEffect(() => {
    // Email status only
    if (formParams.notificationType === CHANNEL_TYPE_ENUM.EMAIL) {
      setFormParams((prevState) => ({ ...prevState, telegramNotificationModels: [] }));
    }

    // Telegram status only
    if (formParams.notificationType === CHANNEL_TYPE_ENUM.TELEGRAM) {
      setFormParams((prevState) => ({ ...prevState, notificationRecipientsEmails: [] }));
    }
  }, [formParams.notificationType]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Bildirim Ekle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Öncelik</span>
                  <StyledPaper>
                    <AddNotificationRuleSelectPriority onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>İlk Bildirim Gecikme Aralığı (dk)</span>
                  <StyledPaper>
                    <AddNotificationRuleTextfieldFirstNotificationDelay
                      onChange={setFormParams}
                      formParams={formParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Hangi Sıklıkla Gönderilsin (sa)</span>
                  <StyledPaper>
                    <AddNotificationRuleTextfieldNotificationIntervalInHours
                      onChange={setFormParams}
                      formParams={formParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Bildirim Kanalları</span>
                  <StyledPaper>
                    <AddNotificationRuleSelectChannel onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Hata Kodları</span>
                  <StyledPaper>
                    <AddNotificationRuleSelectErrorCodes onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Açıklama</span>
                  <StyledPaper>
                    <AddNotificationRuleTextfieldDescription onChange={setFormParams} />
                  </StyledPaper>
                </Grid>

                {formParams.notificationType !== CHANNEL_TYPE_ENUM.TELEGRAM && (
                  <Grid item xs={12} md={4}>
                    <span>Mail Adresleri</span>
                    <StyledPaper>
                      <AddNotificationRuleTextfieldNotificationRecipientsEmails
                        onChange={setFormParams}
                        formParams={formParams}
                        error={error}
                      />
                    </StyledPaper>
                  </Grid>
                )}
              </Grid>

              {formParams.notificationType !== CHANNEL_TYPE_ENUM.EMAIL && (
                <Grid item xs={12} md={12}>
                  <span>Telegram Bilgileri</span>
                  <StyledPaper
                    elevation={3}
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                    }}>
                    <TelegramForm setFormParams={setFormParams} error={error} />
                  </StyledPaper>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <AddNotificationRuleCheckboxNotNotifySupportStaff onChange={setFormParams} formParams={formParams} />
              </Grid>

              <FormButtons
                loading={loading}
                actionName={'Oluştur'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default NotificationPanelAdd;
