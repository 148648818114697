import { css, styled } from 'styled-components';
import { bgSidebar } from 'assets/style-helpers/colors';

export const WrapperContainer = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  user-select: none;
  font-family: 'Open Sans', sans-serif;

  > p {
    font-size: 14px !important;
    letter-spacing: 0.1px;
    line-height: 1.5;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    min-width: 50px;
    margin-right: 0px;
    margin-bottom: 16px;

    > p {
      font-size: 10px !important;
      text-align: center;
    }
  }

  background-color: ${bgSidebar};

  > p {
    color: '#000000de' !important;
  }
`;

export const LoaderContainer = styled(WrapperContainer)`
  min-width: 200px;
  padding: 8px 0;
`;

export const additionalSelectContainerStyle = css`
  min-width: 160px;
  width: 100%;
  height: 20px;
  justify-content: space-between;
  padding: 20px;
`;

export const additionalSelectItemSelectedContainerStyle = css`
  white-space: normal;
  line-height: 16px;
  max-width: 132px;
`;

export const additionalSelectItemContainerStyle = css`
  max-height: 500px;
  overflow-y: auto;
  font-family: 'Open Sans', sans-serif;
`;
