import { RuleSet, styled } from 'styled-components';
import * as Colors from 'assets/style-helpers/colors';

type ButtonContainerProps = {
  $additionalStyle?: RuleSet;
};

export const ButtonWrapperContainer = styled.button`
  border: 0px;
  outline: 0;
  cursor: pointer;
  border-radius: 8px;
  padding: 0px;
  user-select: none;
  background-color: ${Colors.bgButton};
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  border-radius: 8px;
  padding: 16px 16px;
  line-height: 24px;
  box-sizing: border-box;

  > * {
    font-family: 'Open Sans', sans-serif;
  }

  background-color: ${Colors.bgButton};
  /* color: ${Colors.textButton}; */

  &:hover {
    background-color: ${Colors.bgButtonHover};
  }

  &:active {
    background-color: ${Colors.bgButtonActive};
  }

  ${({ $additionalStyle }) => $additionalStyle && $additionalStyle}
`;
