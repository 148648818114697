import {
  TitleSpan,
  SortUpIcon,
  SortDownIcon,
  StyledTableRow,
  TitleContainer,
  StyledTableCell,
  FilterContainer,
  CardTopContainer,
  ProgressContainer,
  additionalInfoCardStyle,
  additionalInfoCardContentStyle,
} from './styles';
import {
  Paper,
  Table,
  Button,
  TableRow,
  TextField,
  TableHead,
  TableBody,
  TableFooter,
  TableContainer,
} from '@mui/material';
import request from 'library/request';
import { ORDER_TYPE_ENUM } from 'enums';
import { Card, Loader } from 'components';
import { ProgressBar } from './components';
import { useState, useEffect } from 'react';
import { SortType } from 'pages/integrations';
import { useNavigate } from 'react-router-dom';
import { Company } from 'library/request/types';
import { sortHandler } from 'utils/render-sort';
import { CustomPagination } from 'containers/elements/@commons';
import { renderFormattedIconCompany } from 'utils/format-icon-company';
import { renderFormattedConsumptionRate } from 'utils/format-consumption-rate';
import { TableHeads } from 'containers/elements/integrations/integration-table';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';

const { getCriticalResource } = request;

export type CriticalResourceFilterParams = {
  pageNumber: number;
  pageSize: number;
  selector: string;
  filterText: string;
  orderByType: number | null;
};

const CriticalConsumptionTable = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [totalGroupCompany, setTotalGroupCompany] = useState(0);
  const [groupCompanyList, setGroupCompanyList] = useState<Company[]>([]);
  const [filterParams, setFilterParams] = useState<CriticalResourceFilterParams>({
    pageNumber: 1,
    pageSize: 5,
    selector: '',
    filterText: '',
    orderByType: null,
  });

  const tableHeads: TableHeads[] = [
    {
      label: 'Şirket Grubu',
      key: 'title',
      isSort: filterParams.selector === 'title',
      sortType: filterParams.selector === 'title' ? filterParams.orderByType : null,
    },
    {
      label: 'RAM',
      key: 'memoryConsumption',
      isSort: 'none',
      sortType: filterParams.selector === 'memoryConsumption' ? filterParams.orderByType : null,
    },
    {
      label: 'Disk',
      key: 'diskConsumption',
      isSort: 'none',
      sortType: filterParams.selector === 'diskConsumption' ? filterParams.orderByType : null,
    },
    {
      label: 'CPU',
      key: 'cpuConsumption',
      isSort: 'none',
      sortType: filterParams.selector === 'cpuConsumption' ? filterParams.orderByType : null,
    },
    {
      label: 'IP Adresi',
      key: 'ipAddress',
      isSort: filterParams.selector === 'ipAddress',
      sortType: filterParams.selector === 'ipAddress' ? filterParams.orderByType : null,
    },
  ];

  const getValues = async (newParams: any = null) => {
    setLoading(true);

    const { data } = newParams
      ? await getCriticalResource({ ...filterParams, ...newParams })
      : await getCriticalResource(filterParams);
    if (!data) return;

    setTotalGroupCompany(data.totalCount);
    setGroupCompanyList(data.result);
    setLoading(false);
  };

  const onApplySortFilter = ({ selector, orderByType }: SortType) => getValues({ selector, orderByType });
  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) =>
    getValues({ pageSize, pageNumber });

  const onClickRowHandler = (title: string) =>
    navigate('/group-companies', {
      state: { title },
    });

  useEffect(() => {
    getValues();
  }, []);

  return (
    <Paper elevation={5} sx={{ borderRadius: '15px' }}>
      <Card $additionalContainerStyle={additionalInfoCardStyle} $additionalStyle={additionalInfoCardContentStyle}>
        <CardTopContainer>
          <TitleContainer>
            <TitleSpan>Kritik Kaynak Tüketimleri</TitleSpan>
          </TitleContainer>
          <FilterContainer>
            <Paper
              sx={{
                width: '200px',
                height: '32px',
                borderRadius: '8px',
              }}
              elevation={2}>
              <TextField
                sx={{
                  width: '200px',
                  height: '32px',
                  borderRadius: '8px',
                  '& .MuiInputBase-root': {
                    height: '32px',
                  },
                  '& fieldset': {
                    borderRadius: '8px',
                    borderColor: '#00000080',
                  },
                }}
                onChange={(e) => setFilterParams((prevState) => ({ ...prevState, filterText: e.target.value }))}
                placeholder="Grup Şirket"
                id="outlined-basic-group-company"
                variant="outlined"
              />
            </Paper>
            <Paper
              sx={{
                width: '200px',
                height: '32px',
                borderRadius: '8px',
              }}
              elevation={3}>
              <Button
                sx={{
                  width: '200px',
                  height: '32px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'medium',
                  backgroundColor: '#1877F2',
                }}
                onClick={() => getValues()}
                variant="contained">
                Filtrele
              </Button>
            </Paper>
          </FilterContainer>
        </CardTopContainer>
        <TableContainer sx={{ boxShadow: 'none', backgroundColor: 'inherit' }} component={Paper}>
          <Table aria-label="custom pagination table">
            <TableHead sx={{ borderRadius: '12px' }} component={Paper}>
              <TableRow>
                {tableHeads.map((tableHead, index) => (
                  <StyledTableCell
                    key={index.toString()}
                    sx={{
                      cursor: tableHead.isSort !== 'none' ? 'pointer' : 'default',
                      fontSize: '16px',
                      color: '#1e1e1ee9 !important',
                      fontWeight: 'medium',
                    }}
                    onClick={() => sortHandler({ tableHead, setFilterParams, onApplySortFilter })}
                    align="center">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                      }}>
                      <span style={{ marginRight: '5px' }}>{tableHead.label}</span>
                      {tableHead.isSort &&
                        tableHead.isSort !== 'none' &&
                        tableHead.sortType === ORDER_TYPE_ENUM.ASC && <SortDownIcon />}
                      {tableHead.isSort &&
                        tableHead.isSort !== 'none' &&
                        tableHead.sortType === ORDER_TYPE_ENUM.DESC && <SortUpIcon />}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={tableHeads.length} sx={{ cursor: 'default' }}>
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : !totalGroupCompany ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={tableHeads.length} sx={{ cursor: 'default' }}>
                    Kritik Kaynak Tüketimi Olan Şirket Bulunmamaktadır.
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                groupCompanyList.map((company: Company, index: number) => {
                  const memoryConsumption = renderFormattedConsumptionRate(
                    company.memoryCapacity,
                    company.memoryUsedSpace
                  );
                  const diskConsumption = renderFormattedConsumptionRate(company.diskCapacity, company.diskUsedSpace);
                  const CPUConsumption = company.cpuUsage ? Number(company.cpuUsage.replaceAll(',', '.')) : 0;

                  return (
                    <StyledTableRow key={index.toString()} onClick={() => onClickRowHandler(company.title)}>
                      <StyledTableCell>
                        {renderFormattedIconCompany(company.title, company.userName as string, 20)}
                      </StyledTableCell>
                      <StyledTableCell width="20%" align="center">
                        <ProgressContainer>
                          <ProgressBar value={memoryConsumption} />
                          {`${memoryConsumption} %`}
                        </ProgressContainer>
                      </StyledTableCell>
                      <StyledTableCell width="20%" align="center">
                        <ProgressContainer>
                          <ProgressBar value={diskConsumption} />
                          {`${diskConsumption} %`}
                        </ProgressContainer>
                      </StyledTableCell>
                      <StyledTableCell width="20%" align="center">
                        <ProgressContainer>
                          <ProgressBar value={CPUConsumption} />
                          {`${CPUConsumption} %`}
                        </ProgressContainer>
                      </StyledTableCell>
                      <StyledTableCell width="20%" align="center">
                        {company.ipAddress}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
            {!loading && totalGroupCompany > 0 && (
              <TableFooter>
                <StyledTableRow sx={{ cursor: 'default' }}>
                  <CustomPagination
                    sx={{ borderBottom: 'none', borderTop: '1px solid #00000040' }}
                    options={[5, 20, 50, 100, { label: 'Hepsi', value: -1 }]}
                    colSpan={tableHeads.length}
                    totalCount={totalGroupCompany}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    onApplyPaginationFilter={onApplyPaginationFilter}
                  />
                </StyledTableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Card>
    </Paper>
  );
};

export default CriticalConsumptionTable;
