import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { UpdateNotificationRuleState } from './update-notification-rule.types';

const selectUpdateNotificationRuleReducer = (state: RootState): UpdateNotificationRuleState =>
  state.updateNotificationRule;

export const selectErrorUpdate = createSelector(
  [selectUpdateNotificationRuleReducer],
  (updateNotificationRule) => updateNotificationRule.errorUpdate
);

export const selectLoadingUpdate = createSelector(
  [selectUpdateNotificationRuleReducer],
  (updateNotificationRule) => updateNotificationRule.loadingUpdate
);

export const selectNotificationRule = createSelector(
  [selectUpdateNotificationRuleReducer],
  (updateNotificationRule) => updateNotificationRule.notificationRule
);

export const selectIsDeletePopUpOpen = createSelector(
  [selectUpdateNotificationRuleReducer],
  (updateNotificationRule) => updateNotificationRule.isPopUpOpen
);

export const selectNotificationRuleId = createSelector(
  [selectUpdateNotificationRuleReducer],
  (updateNotificationRule) => updateNotificationRule.notificationRuleId
);
