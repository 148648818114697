import request from 'library/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { getBanksMap } = request;

const getBanks = createAsyncThunk('getBanks', async () => {
  return await getBanksMap();
});

const banksActions = {
  getBanks,
};

export default banksActions;
