import {
  TitleSpan,
  TitleContainer,
  CardTopContainer,
  IntegrationlessSpan,
  additionalInfoCardStyle,
  IntegrationlessContainer,
  additionalInfoCardContentStyle,
} from './styles';
import { useState } from 'react';
import { Card } from 'components';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Sector } from 'recharts';
import { PRIORITY_TYPE_ENUM, getPriorityTranslated } from 'enums';

const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload }: any) => {
  return (
    <g>
      <text
        fontSize={17}
        fontFamily='"Open Sans", sans-serif'
        x={cx}
        y={cy - 8}
        dy={6}
        textAnchor="middle"
        fill={payload.color}>
        {payload.name}
      </text>
      <text
        fontFamily='"Open Sans", sans-serif'
        fontSize={17}
        x={cx}
        y={cy + 8}
        dy={8}
        textAnchor="middle"
        fill={payload.color}>
        {`${payload.value} Entegrasyon`}
      </text>
      <Sector
        cursor="pointer"
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.color}
      />
    </g>
  );
};

type CustomPieChartProps = {
  veryUrgent: number;
  urgent: number;
  nonUrgent: number;
};

const CustomPieChart = ({ veryUrgent, urgent, nonUrgent }: CustomPieChartProps) => {
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  const dataVeryUrgent = {
    name: getPriorityTranslated(PRIORITY_TYPE_ENUM.VERY_URGENT),
    value: veryUrgent,
    priority: PRIORITY_TYPE_ENUM.VERY_URGENT,
    color: '#F24848',
  };

  const dataUrgent = {
    name: getPriorityTranslated(PRIORITY_TYPE_ENUM.URGENT),
    value: urgent,
    priority: PRIORITY_TYPE_ENUM.URGENT,
    color: '#DBD812',
  };

  const dataNonUrgent = {
    name: getPriorityTranslated(PRIORITY_TYPE_ENUM.NON_URGENT),
    value: nonUrgent,
    priority: PRIORITY_TYPE_ENUM.NON_URGENT,
    color: '#1EE32E',
  };

  const data = veryUrgent
    ? [dataVeryUrgent, dataUrgent, dataNonUrgent]
    : urgent
    ? [dataUrgent, dataNonUrgent, dataVeryUrgent]
    : [dataNonUrgent, dataUrgent, dataVeryUrgent];

  const onPieEnter = (_: any, index: number) => setActiveIndex(index);

  const onClickHandler = (data: any) => navigate('/integrations', { state: { alertPriority: data.priority } });

  return (
    <Paper elevation={5} sx={{ borderRadius: '15px' }}>
      <Card $additionalContainerStyle={additionalInfoCardStyle} $additionalStyle={additionalInfoCardContentStyle}>
        <CardTopContainer>
          <TitleContainer>
            <TitleSpan>Başarısız Entegrasyonlar Grafiği</TitleSpan>
          </TitleContainer>
        </CardTopContainer>
        {veryUrgent === 0 && urgent === 0 && nonUrgent === 0 ? (
          <IntegrationlessContainer>
            <IntegrationlessSpan>Başarısız Entegrasyon Bulunmamaktadır.</IntegrationlessSpan>
          </IntegrationlessContainer>
        ) : (
          <PieChart height={400} width={275}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              innerRadius={90}
              outerRadius={120}
              dataKey="value"
              onMouseEnter={onPieEnter}
              onClick={onClickHandler}
            />
          </PieChart>
        )}
      </Card>
    </Paper>
  );
};

export default CustomPieChart;
