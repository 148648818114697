import { styled } from 'styled-components';
import { AddGroupCompanySvg } from 'assets/icons';

export const AddGroupCompanyIcon = styled(AddGroupCompanySvg)``;

export const RightItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 5px 5px 15px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`;

export const TextSpan = styled.span`
  margin-left: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
`;
