import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useNavigate } from 'react-router-dom';
import { Card, Widget, Text } from 'components';
import {
  AddGroupCompanyTextfieldTitle,
  AddGroupCompanySelectServerType,
  AddGroupCompanyTextfieldPassword,
  AddGroupCompanyTextfieldUsername,
  AddGroupCompanySelectSupportPerson,
  AddGroupCompanyCheckboxSendAutoEmail,
  AddGroupCompanyTextfieldAutoNotificationEmailAddresses,
} from 'containers/elements/group-companies-add';
import Authentication from 'utils/authentication';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import FormButtons from 'containers/elements/@commons/form-buttons';

const { createCompanyGroup } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Grup Şirketler',
    path: '/group-companies',
  },
  {
    name: 'Grup Şirket Ekle',
    path: '/group-companies/add',
  },
];

export type AddGroupCompanyFormParams = {
  title: string;
  userName: string;
  password: string;
  serverType: number;
  sendAutoEmail: boolean;
  defaultSupportPerson: string;
  autoNotificationEmailAddresses: string[];
};

const GroupCompaniesAdd = () => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<AddGroupCompanyFormParams>({
    title: '',
    userName: '',
    password: '',
    serverType: 1,
    sendAutoEmail: false,
    autoNotificationEmailAddresses: [],
    defaultSupportPerson: loggedUser.value,
  });

  const onClear = () => {
    setFormParams({
      title: '',
      userName: '',
      password: '',
      serverType: 1,
      sendAutoEmail: false,
      autoNotificationEmailAddresses: [],
      defaultSupportPerson: loggedUser.value,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.title === '' || formParams.userName === '' || formParams.password === '') {
      setErrorMessage(
        formParams.title === ''
          ? 'Şirket Grubu boş geçilemez.'
          : formParams.userName === ''
          ? 'API Kullanıcı Adı boş geçilemez.'
          : formParams.password === ''
          ? 'Parola boş geçilemez.'
          : ''
      );
      return setError(true);
    }

    if (formParams.sendAutoEmail && !formParams.autoNotificationEmailAddresses.length) {
      setErrorMessage('Otomatik gönderim için en az 1 adet e-posta girilmelidir.');
      return setError(true);
    }

    setLoading(true);

    createCompanyGroup(formParams)
      .then((res) => {
        setLoading(false);

        if (res.message === 'Bazı hatalar oluştu') {
          setErrorMessage(res.validationErrors?.[0].errorMessage as string);
          setError(true);
          return;
        }

        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  useEffect(() => {
    if (!formParams.sendAutoEmail) {
      setFormParams((prevState) => ({ ...prevState, autoNotificationEmailAddresses: [] }));
    }
  }, [formParams.sendAutoEmail]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Grup Şirket Ekle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Şirket Grubu</span>
                  <StyledPaper>
                    <AddGroupCompanyTextfieldTitle onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>API Kullanıcı Adı</span>
                  <StyledPaper>
                    <AddGroupCompanyTextfieldUsername onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Parola</span>
                  <StyledPaper>
                    <AddGroupCompanyTextfieldPassword onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Destek Personeli</span>
                  <StyledPaper>
                    <AddGroupCompanySelectSupportPerson onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Sunucu</span>
                  <StyledPaper>
                    <AddGroupCompanySelectServerType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                {formParams.sendAutoEmail && (
                  <Grid item xs={12} md={4}>
                    <span>Mail Adresleri</span>
                    <StyledPaper>
                      <AddGroupCompanyTextfieldAutoNotificationEmailAddresses
                        onChange={setFormParams}
                        formParams={formParams}
                        error={error}
                      />
                    </StyledPaper>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <AddGroupCompanyCheckboxSendAutoEmail onChange={setFormParams} formParams={formParams} />
              </Grid>
              <FormButtons
                loading={loading}
                actionName={'Oluştur'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default GroupCompaniesAdd;
