import { SummaryCard } from '..';
import request from 'library/request';
import { Card, Loader } from 'components';
import { Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { Company } from 'library/request/types';
import { selectSelectedGroupCompanies } from 'store/features/select-companies/select-companies.selector';

const { getCompaniesMap } = request;

const IntegrationStatus = () => {
  const selectedGroupCompanies = useAppSelector(selectSelectedGroupCompanies);

  const [loading, setLoading] = useState(false);
  const [selectedGroupCompaniesMap, setSelectedGroupCompaniesMap] = useState<Company[]>([]);

  const idMap = selectedGroupCompanies.map((groupCompany) => groupCompany.id);

  useEffect(() => {
    setLoading(true);

    getCompaniesMap().then((res) => {
      setSelectedGroupCompaniesMap(res.filter((groupCompany) => groupCompany.isNode));
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Box sx={{ flexGrow: 1 }}>
      <Card>
        <Grid container spacing={2}>
          {selectedGroupCompaniesMap.length > 0 &&
            selectedGroupCompaniesMap
              .filter((groupCompany) => idMap.includes(groupCompany.id))
              .map((groupCompany, index) => (
                <Grid key={index.toString()} item xs={12} md={4}>
                  <SummaryCard groupCompany={groupCompany} />
                </Grid>
              ))}
        </Grid>
      </Card>
    </Box>
  );
};

export default IntegrationStatus;
