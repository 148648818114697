import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { DrawerIntegrationsState } from './drawer-integrations.types';

const selectDrawerIntegrationsReducer = (state: RootState): DrawerIntegrationsState => state.drawerIntegrations;

export const selectRows = createSelector(
  [selectDrawerIntegrationsReducer],
  (drawerIntegrations) => drawerIntegrations.rows
);
export const selectIsChangedActivity = createSelector(
  [selectDrawerIntegrationsReducer],
  (drawerIntegrations) => drawerIntegrations.isChangedActivity
);
export const selectSelectedCompanyIntegrationId = createSelector(
  [selectDrawerIntegrationsReducer],
  (drawerIntegrations) => drawerIntegrations.selectedCompanyIntegrationId
);
