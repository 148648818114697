import TextField from '@mui/material/TextField';
import { EditUserFormParams } from 'pages/users-edit';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

type EditUserTextfieldTelegramUserNameProps = {
  onChange: Dispatch<SetStateAction<EditUserFormParams>>;
  formParams: EditUserFormParams;
  error: boolean;
};

const EditUserTextfieldTelegramUserName = ({ onChange, formParams, error }: EditUserTextfieldTelegramUserNameProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const telegramUserName = event.target.value;
    onChange((prevState: EditUserFormParams) => ({ ...prevState, telegramUserName }));
  };

  return (
    <TextField
      size="small"
      fullWidth
      sx={
        error && formParams.useTelegram && !formParams.telegramUserName
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.telegramUserName}
      id="outlined-basic-telegram-user-name"
      variant="outlined"
    />
  );
};

export default EditUserTextfieldTelegramUserName;
