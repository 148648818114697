import { Loader } from 'components';
import { BankMap } from 'library/request/types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import banksActions from 'store/features/banks/banks.actions';
import { Checkbox, TextField, Autocomplete } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { selectBanksMap, selectIsLoading } from 'store/features/banks/banks.selector';
import { ErrorLogsFilterParams } from 'containers/elements/dashboard/error-logs-table';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectBanksProps = {
  onChange: Dispatch<SetStateAction<ErrorLogsFilterParams>>;
  filterParams: ErrorLogsFilterParams;
};

const SelectBank = ({ onChange, filterParams }: SelectBanksProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const banksMap = useAppSelector(selectBanksMap);

  const [selectedBanks, setSelectedBanks] = useState<BankMap[]>([]);

  useEffect(() => {
    if (!banksMap.length) {
      dispatch(banksActions.getBanks());
    }
  }, [banksMap]);

  useEffect(() => {
    if (!filterParams.bankCodes.length) {
      setSelectedBanks([]);
    }
  }, [filterParams.bankCodes]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      limitTags={1}
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
          height: '32px',
        },
        '& fieldset': {
          borderColor: '#00000080',
        },
        '& label': {
          lineHeight: 1,
        },
      }}
      multiple
      value={selectedBanks}
      size="small"
      id="checkboxes-tags-demo"
      options={banksMap}
      disableCloseOnSelect
      onChange={(event: any, newValue: BankMap[]) => {
        setSelectedBanks(newValue);

        const bankCodes = newValue.map((value) => Number(value.code));
        onChange((prevState: ErrorLogsFilterParams) => ({ ...prevState, bankCodes }));
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      renderInput={(params) => {
        const text =
          selectedBanks.length === 0 ? null : selectedBanks.length === 1 ? (
            <span style={{ paddingLeft: 10, paddingBottom: 10 }}>{selectedBanks[0].name}</span>
          ) : (
            <span style={{ paddingLeft: 10, paddingBottom: 10 }}>{`${selectedBanks.length} banka seçili`}</span>
          );

        return <TextField {...params} InputProps={{ ...params.InputProps, startAdornment: text }} label="Banka" />;
      }}
      ListboxProps={{
        style: {
          maxHeight: '220px',
        },
      }}
    />
  );
};

export default SelectBank;
