import { Loader } from 'components';
import { BankMap } from 'library/request/types';
import { FilterParams } from 'pages/integrations';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import banksActions from 'store/features/banks/banks.actions';
import { Checkbox, TextField, Autocomplete } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { selectBanksMap, selectIsLoading } from 'store/features/banks/banks.selector';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectBanksProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const SelectBank = ({ onChange, filterParams }: SelectBanksProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const banksMap = useAppSelector(selectBanksMap);

  const [selectedBanks, setSelectedBanks] = useState<BankMap[]>([]);

  useEffect(() => {
    if (!banksMap.length) {
      dispatch(banksActions.getBanks());
    }
  }, [banksMap]);

  useEffect(() => {
    if (!filterParams.bankCodes.length) {
      setSelectedBanks([]);
    } else {
      if (!Array.isArray(filterParams.bankCodes)) return;

      const bankCodes = filterParams.bankCodes.map((bankCode) => banksMap.find((bnkCode) => bnkCode.code === bankCode));
      setSelectedBanks(bankCodes as BankMap[]);
    }
  }, [filterParams.bankCodes]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      multiple
      value={selectedBanks}
      size="small"
      id="checkboxes-tags-demo"
      options={banksMap}
      disableCloseOnSelect
      onChange={(event: any, newValue: BankMap[]) => {
        setSelectedBanks(newValue);

        const bankCodes = newValue.map((value) => value.code);
        onChange((prevState: FilterParams) => ({ ...prevState, bankCodes }));
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Banka" />}
      ListboxProps={{
        style: {
          maxHeight: '220px',
        },
      }}
    />
  );
};

export default SelectBank;
