import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { Box, IconButton, TablePagination } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Dispatch, SetStateAction, MouseEvent, useState, ChangeEvent } from 'react';

export type PaginationFilterParams = {
  pageSize: number;
  pageNumber: number;
};

type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export type CustomPaginationProps = {
  filterParams: any;
  setFilterParams: Dispatch<SetStateAction<any>>;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
  totalCount: number;
  colSpan: number;
  options?: any;
  sx?: any;
};

const CustomPagination = ({
  filterParams,
  setFilterParams,
  onApplyPaginationFilter,
  totalCount,
  colSpan,
  sx = {},
  options = [20, 50, 100, 250, 500, 1000, { label: 'Hepsi', value: -1 }],
}: CustomPaginationProps) => {
  const initSize = filterParams.pageSize === 9999 ? -1 : filterParams.pageSize;
  const [page, setPage] = useState(filterParams.pageNumber - 1);
  const [rowsPerPage, setRowsPerPage] = useState(initSize);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setFilterParams((prevState: ReturnType<typeof filterParams>) => ({ ...prevState, pageNumber: newPage + 1 }));
    onApplyPaginationFilter({ pageSize: filterParams.pageSize, pageNumber: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : 9999;
    setFilterParams((prevState: ReturnType<typeof filterParams>) => ({ ...prevState, pageSize, pageNumber: 1 }));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onApplyPaginationFilter({ pageSize, pageNumber: 1 });
  };

  return (
    <TablePagination
      sx={sx}
      labelRowsPerPage="Sayfa Başına Öğe:"
      labelDisplayedRows={({ from, to, count }) => {
        return `${count} satırdan ${from}-${to} arası`;
      }}
      rowsPerPageOptions={options}
      colSpan={colSpan}
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default CustomPagination;
