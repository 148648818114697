const renderFormattedTime = (minute: number) => {
  if (!minute) return '0';

  if (minute < 60) return `${minute} dakika`;
  if (minute < 60 * 24) return `${Math.trunc(minute / 60)} saat`;
  if (minute < 60 * 24 * 7) return `${Math.trunc(minute / (60 * 24))} gün`;
  if (minute < 60 * 24 * 7 * 4) return `${Math.trunc(minute / (60 * 24 * 7))} hafta`;
  if (minute < 60 * 24 * 7 * 4 * 12) return `${Math.trunc(minute / (60 * 24 * 7 * 4))} ay`;

  return '1 Yıl';
};

export default renderFormattedTime;
