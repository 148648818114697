import { SetStateAction } from 'react';
import { ORDER_TYPE_ENUM } from 'enums';
import { SortType } from 'pages/integrations';
import { TableHeads } from 'containers/elements/integrations/integration-table';

type SortHandlerProps = {
  tableHead: TableHeads;
  setFilterParams: (value: SetStateAction<any>) => void;
  onApplySortFilter: ({ selector, orderByType }: SortType) => Promise<void>;
};

export const sortHandler = ({ tableHead, setFilterParams, onApplySortFilter }: SortHandlerProps) => {
  if (tableHead.isSort === 'none') return;

  const selector = tableHead.sortType === ORDER_TYPE_ENUM.DESC ? '' : tableHead.key;
  const orderByType =
    tableHead.sortType === null
      ? ORDER_TYPE_ENUM.ASC
      : tableHead.sortType === ORDER_TYPE_ENUM.ASC
      ? ORDER_TYPE_ENUM.DESC
      : null;

  setFilterParams((prevState: any) => ({
    ...prevState,
    selector,
    orderByType,
  }));

  onApplySortFilter({ selector, orderByType });
};
