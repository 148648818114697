import { Loader } from 'components';
import request from 'library/request';
import { Company } from 'library/request/types';
import { EditUserFormParams } from 'pages/users-edit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const { getCompaniesMap } = request;

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

type EditUserSelectGroupCompaniesProps = {
  onChange: Dispatch<SetStateAction<EditUserFormParams>>;
  formParams: EditUserFormParams;
};

const EditUserSelectGroupCompanies = ({ onChange, formParams }: EditUserSelectGroupCompaniesProps) => {
  const [loading, setLoading] = useState(false);
  const [groupCompaniesMap, setGroupCompaniesMap] = useState<Company[]>([]);
  const [selectedGroupCompanies, setSelectedGroupCompanies] = useState<Company[]>([]);

  useEffect(() => {
    setLoading(true);
    getCompaniesMap().then((res) => {
      setGroupCompaniesMap(res.filter((groupCompany) => groupCompany.isNode));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (formParams.companyGroupIds.length > 0 && groupCompaniesMap.length > 0) {
      const assignedGroupCompanies = groupCompaniesMap.filter((groupCompany) =>
        formParams.companyGroupIds.includes(groupCompany.id as number)
      );
      setSelectedGroupCompanies(assignedGroupCompanies);
    } else {
      setSelectedGroupCompanies([]);
    }
  }, [formParams.companyGroupIds, groupCompaniesMap]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      fullWidth
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      multiple
      value={selectedGroupCompanies}
      size="small"
      id="checkboxes-tags-demo"
      options={groupCompaniesMap}
      disableCloseOnSelect
      onChange={(event: any, newValue: Company[]) => {
        setSelectedGroupCompanies(newValue);

        const companyGroupIds = newValue.map((value) => value.id as number);
        onChange((prevState: EditUserFormParams) => ({ ...prevState, companyGroupIds }));
      }}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.title}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} />}
      ListboxProps={{
        style: {
          maxHeight: '220px',
        },
      }}
    />
  );
};

export default EditUserSelectGroupCompanies;
