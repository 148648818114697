import {
  CardContent,
  CardContainer,
  HeaderContainer,
  LeftItemContainer,
  RightItemContainer,
  CenterItemContainer,
} from './styles';
import { ReactNode } from 'react';
import { RuleSet } from 'styled-components';

type CardProps = {
  children: ReactNode;
  leftItem?: ReactNode;
  rightItem?: ReactNode;
  centerItem?: ReactNode;
  $additionalStyle?: RuleSet;
  $additionalContainerStyle?: RuleSet;
};

const Card = ({
  children,
  leftItem,
  centerItem,
  rightItem,
  $additionalStyle,
  $additionalContainerStyle,
}: CardProps) => {
  const renderHeader = () => {
    if (leftItem || centerItem || rightItem) {
      return (
        <HeaderContainer>
          {leftItem && <LeftItemContainer>{leftItem}</LeftItemContainer>}
          {centerItem && <CenterItemContainer>{centerItem}</CenterItemContainer>}
          {rightItem && <RightItemContainer>{rightItem}</RightItemContainer>}
        </HeaderContainer>
      );
    }

    return null;
  };

  return (
    <CardContainer $additionalContainerStyle={$additionalContainerStyle}>
      {renderHeader()}
      <CardContent $additionalStyle={$additionalStyle}>{children}</CardContent>
    </CardContainer>
  );
};

export default Card;
