import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { UpdateGroupCompanyState } from './update-group-company.types';

const selectUpdateGroupCompanyReducer = (state: RootState): UpdateGroupCompanyState => state.updateGroupCompany;

export const selectErrorUpdate = createSelector(
  [selectUpdateGroupCompanyReducer],
  (updateGroupCompany) => updateGroupCompany.errorUpdate
);

export const selectLoadingUpdate = createSelector(
  [selectUpdateGroupCompanyReducer],
  (updateGroupCompany) => updateGroupCompany.loadingUpdate
);

export const selectGroupCompany = createSelector(
  [selectUpdateGroupCompanyReducer],
  (updateGroupCompany) => updateGroupCompany.groupCompany
);
export const selectGroupCompanyId = createSelector(
  [selectUpdateGroupCompanyReducer],
  (updateGroupCompany) => updateGroupCompany.groupCompanyId
);

export const selectIsDeletePopUpOpen = createSelector(
  [selectUpdateGroupCompanyReducer],
  (updateGroupCompany) => updateGroupCompany.isPopUpOpen
);
