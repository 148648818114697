import styled, { css } from 'styled-components';

export const additionalInfoCardStyle = css`
  padding: 0px 10px !important;
  border-radius: 15px !important;
`;

export const additionalInfoCardContentStyle = css`
  justify-content: center !important;
  align-items: center !important;
`;

export const CardTopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 10px 20px 10px;
`;

export const IntegrationlessContainer = styled.div`
  height: 400;
  display: flex;
  align-items: center;
`;

export const IntegrationlessSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 14;
  color: rgba(0, 0, 0, 0.87);
`;

export const TitleContainer = styled.div`
  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const TitleSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  color: #00000080;
  font-weight: 500;

  @media (max-width: 500px) {
    text-align: center;
  }
`;
