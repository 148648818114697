import request from 'library/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { getUserByUserName } = request;

const getUserByUsername = createAsyncThunk('getUserByUserName', async (userName: string) => {
  return await getUserByUserName({ userName });
});

const updateUserActions = {
  getUserByUsername,
};

export default updateUserActions;
