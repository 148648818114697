import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { EditNotificationRuleFormParams } from 'pages/notification-panel-edit';

type EditNotificationRuleTextfieldDescriptionProps = {
  onChange: Dispatch<SetStateAction<EditNotificationRuleFormParams>>;
  formParams: EditNotificationRuleFormParams;
};

const EditNotificationRuleTextfieldDescription = ({
  onChange,
  formParams,
}: EditNotificationRuleTextfieldDescriptionProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const description = event.target.value;
    onChange((prevState: EditNotificationRuleFormParams) => ({ ...prevState, description }));
  };
  return (
    <TextField
      size="small"
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      fullWidth
      value={formParams.description}
      onChange={handleChange}
      id="outlined-basic-edit-notification-rule-description"
      variant="outlined"
    />
  );
};

export default EditNotificationRuleTextfieldDescription;
