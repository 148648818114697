import { useAppSelector } from 'store/hooks';
import { FilterParams } from 'pages/integrations';
import { Autocomplete, TextField } from '@mui/material';
import { Option } from 'store/features/users/users.types';
import { selectUsersMap } from 'store/features/users/users.selector';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';

const allItem = { value: '', label: 'Tümü' };

type SelectUserProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const SelectUser = ({ onChange, filterParams }: SelectUserProps) => {
  const userMap = useAppSelector(selectUsersMap);
  const users = [allItem, ...userMap];

  const [selectedUser, setSelectedUser] = useState<Option>(allItem);

  useEffect(() => {
    if (!filterParams.userName) {
      setSelectedUser(allItem);
    }
  }, [filterParams.userName]);

  return (
    <Autocomplete
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      size="small"
      value={selectedUser}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disablePortal
      id="combo-box-demo"
      options={users}
      onChange={(event: any, newValue: Option | null) => {
        const value = newValue ?? allItem;
        setSelectedUser(value);

        const userName = value.value;
        onChange((prevState: FilterParams) => ({ ...prevState, userName }));
      }}
      renderInput={(params) => <TextField {...params} label="Kullanıcı" />}
      ListboxProps={{
        style: {
          maxHeight: '155px',
        },
      }}
    />
  );
};

export default SelectUser;
