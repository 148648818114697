import { Dispatch, SetStateAction } from 'react';
import { FilterParams } from 'pages/integrations';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { PRIORITY_TYPE_ENUM, getPriorityTranslated } from 'enums';
import { MenuItem, InputLabel, FormControl } from '@mui/material';

type SelectPriorityProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const SelectPriority = ({ onChange, filterParams }: SelectPriorityProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const alertPriority = event.target.value === 'Tümü' ? null : event.target.value;
    onChange((prevState: FilterParams) => ({ ...prevState, alertPriority }));
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="demo-simple-select-label">
        Öncelik
      </InputLabel>
      <Select
        sx={{
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        }}
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filterParams.alertPriority ? String(filterParams.alertPriority) : 'Tümü'}
        label="Öncelik"
        defaultValue={filterParams.alertPriority ? String(filterParams.alertPriority) : 'Tümü'}
        onChange={handleChange}>
        <MenuItem value={'Tümü'}>Tümü</MenuItem>
        {(Object.keys(PRIORITY_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof PRIORITY_TYPE_ENUM)[]).map(
          (key, index) => (
            <MenuItem key={index.toString()} value={PRIORITY_TYPE_ENUM[key]}>
              {getPriorityTranslated(PRIORITY_TYPE_ENUM[key])}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default SelectPriority;
