import TextField from '@mui/material/TextField';
import { EditUserFormParams } from 'pages/users-edit';
import { InputAdornment, Tooltip } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type EditUserTextfieldTelegramUserIdProps = {
  onChange: Dispatch<SetStateAction<EditUserFormParams>>;
  formParams: EditUserFormParams;
  error: boolean;
};

const EditUserTextfieldTelegramUserId = ({ onChange, formParams, error }: EditUserTextfieldTelegramUserIdProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const telegramUserId = event.target.value;
    onChange((prevState: EditUserFormParams) => ({ ...prevState, telegramUserId }));
  };

  return (
    <TextField
      size="small"
      fullWidth
      sx={
        error && formParams.useTelegram && !formParams.telegramUserId
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.telegramUserId}
      id="outlined-basic-telegram-user-id"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip
              placement="top"
              title="Kullanıcı Id Telegram üzerinden alınacaktır. https://t.me/userinfobot adresinde bulunan bota /start şeklinde mesaj gönderilerek alınacaktır!">
              <InfoOutlinedIcon />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default EditUserTextfieldTelegramUserId;
