import { Dispatch, SetStateAction } from 'react';
import { FilterParams } from 'pages/integrations';
import { STATUS_TYPE_ENUM, getStatusTranslated } from 'enums';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, InputLabel, FormControl } from '@mui/material';

type SelectStatusProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const SelectStatus = ({ onChange, filterParams }: SelectStatusProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const statusType = event.target.value === 'Tümü' ? null : event.target.value;
    onChange((prevState: FilterParams) => ({ ...prevState, statusType }));
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="demo-simple-select-label">
        Durum
      </InputLabel>
      <Select
        sx={{
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        }}
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filterParams.statusType ? String(filterParams.statusType) : 'Tümü'}
        label="Durum"
        defaultValue={filterParams.statusType ? String(filterParams.statusType) : 'Tümü'}
        onChange={handleChange}>
        <MenuItem value={'Tümü'}>Tümü</MenuItem>
        {(Object.keys(STATUS_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof STATUS_TYPE_ENUM)[]).map(
          (key, index) => (
            <MenuItem key={index.toString()} value={STATUS_TYPE_ENUM[key]}>
              {getStatusTranslated(STATUS_TYPE_ENUM[key])}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default SelectStatus;
