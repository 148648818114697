import Box from '@mui/material/Box';
import BorderLinearProgress from './styles';

type CustomizedProgressBarsProps = {
  value: number;
  isError: boolean;
};

const CustomizedProgressBars = ({ value, isError }: CustomizedProgressBarsProps) => {
  const redColor = isError ? '#D04238' : '#DC685F';
  const color = value >= 90 ? redColor : value >= 70 ? '#F4F38680' : '#9BF7C780';

  return (
    <Box sx={{ flexGrow: 1, width: '160px' }}>
      <BorderLinearProgress variant="determinate" value={value} fillcolor={color} />
    </Box>
  );
};

export default CustomizedProgressBars;
