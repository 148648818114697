import axios from 'axios';
import LocalStorage from './helpers/local-storage';

const userToken = LocalStorage.getItem('token');

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REST_URL,
  headers: {
    Authorization: userToken ? `Bearer ${userToken}` : null,
  },
});
