import { useAppDispatch } from 'store/hooks';
import { SortType } from 'pages/integrations';
import { useNavigate } from 'react-router-dom';
import { sortHandler } from 'utils/render-sort';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { renderFormattedPriority } from 'utils/format-priority';
import { CustomPagination } from 'containers/elements/@commons';
import { NotificationRuleByFilter } from 'library/request/types';
import { NotificationPanelFilterParams } from 'pages/notification-panel-main';
import {
  setNotificationRule,
  setIsDeletePopUpOpen,
  setNotificationRuleId,
} from 'store/features/update-notification-rule/update-notification-rule.slice';
import { TableHeads } from 'containers/elements/integrations/integration-table';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { ORDER_TYPE_ENUM, getChannelTranslated, getStatusTranslated } from 'enums';
import { Table, Paper, Tooltip, TableRow, TableBody, TableHead, TableFooter, TableContainer } from '@mui/material';
import { EditIcon, DeleteIcon, StyledTableCell, StyledTableRow, SortDownIcon, SortUpIcon, EmailIcon } from './styles';

type NotificationPanelTableProps = {
  totalNotificationRule: number;
  notificationRuleList: NotificationRuleByFilter[];
  filterParams: NotificationPanelFilterParams;
  setFilterParams: Dispatch<SetStateAction<NotificationPanelFilterParams>>;
  onApplySortFilter: ({ selector, orderByType }: SortType) => Promise<void>;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
};

const NotificationPanelTable = ({
  notificationRuleList: rows,
  totalNotificationRule,
  filterParams,
  setFilterParams,
  onApplySortFilter,
  onApplyPaginationFilter,
}: NotificationPanelTableProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formatStringArray = (value: string[], defaultValue: string) => {
    if (!value.length) return defaultValue;

    return value.length === 1 ? value : value.reduce((accumulator, currentValue) => `${accumulator}, ${currentValue}`);
  };

  const tableHeads: TableHeads[] = [
    {
      label: 'Durum',
      key: 'statusType',
      isSort: filterParams.selector === 'statusType',
      sortType: filterParams.selector === 'statusType' ? filterParams.orderByType : null,
    },
    {
      label: 'Öncelik',
      key: 'alertPriority',
      isSort: filterParams.selector === 'alertPriority',
      sortType: filterParams.selector === 'alertPriority' ? filterParams.orderByType : null,
    },
    {
      label: 'İlk Bildirim Gecikme Aralığı',
      key: 'firstNotificationDelayInMinutes',
      isSort: filterParams.selector === 'firstNotificationDelayInMinutes',
      sortType: filterParams.selector === 'firstNotificationDelayInMinutes' ? filterParams.orderByType : null,
    },
    {
      label: 'Hangi Sıklıkla Gönderilsin',
      key: 'notificationIntervalInHours',
      isSort: filterParams.selector === 'notificationIntervalInHours',
      sortType: filterParams.selector === 'notificationIntervalInHours' ? filterParams.orderByType : null,
    },
    {
      label: 'Hata Kodları',
      key: 'errorCodes',
      isSort: filterParams.selector === 'errorCodes',
      sortType: filterParams.selector === 'errorCodes' ? filterParams.orderByType : null,
    },
    {
      label: 'Bildirim Kanalı',
      key: 'notificationType',
      isSort: filterParams.selector === 'notificationType',
      sortType: filterParams.selector === 'notificationType' ? filterParams.orderByType : null,
    },
    {
      label: 'Açıklama',
      key: 'description',
      isSort: filterParams.selector === 'description',
      sortType: filterParams.selector === 'description' ? filterParams.orderByType : null,
    },
    {
      label: 'Mail Adresleri',
      key: 'notificationRecipientsEmails',
      isSort: filterParams.selector === 'notificationRecipientsEmails',
      sortType: filterParams.selector === 'notificationRecipientsEmails' ? filterParams.orderByType : null,
    },
  ];

  const goToEditNotificationRuleHandler = useCallback(() => navigate('edit'), []);

  return (
    <TableContainer sx={{ width: '100%' }} component={Paper}>
      <Table aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={1} align="center"></StyledTableCell>
            <StyledTableCell colSpan={1} sx={{ borderRight: '1px solid #1e1e1e20' }} align="center"></StyledTableCell>
            {tableHeads.map((tableHead, index) => (
              <StyledTableCell
                key={index.toString()}
                sx={{ ...tableHead.style, cursor: tableHead.isSort !== 'none' ? 'pointer' : 'default' }}
                onClick={() => sortHandler({ tableHead, setFilterParams, onApplySortFilter })}
                align="center">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                  }}>
                  <span style={{ marginRight: '5px' }}>{tableHead.label}</span>
                  {tableHead.isSort && tableHead.isSort !== 'none' && tableHead.sortType === ORDER_TYPE_ENUM.ASC && (
                    <SortDownIcon />
                  )}
                  {tableHead.isSort && tableHead.isSort !== 'none' && tableHead.sortType === ORDER_TYPE_ENUM.DESC && (
                    <SortUpIcon />
                  )}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {!totalNotificationRule ? (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={tableHeads.length + 2}>Bildirim bulunmamaktadır.</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            <TableBody>
              {rows.map((row, index) => {
                const emails =
                  row.notificationType === 1
                    ? '-'
                    : formatStringArray(row.notificationRecipientsEmails, 'Sadece Destek Personeli');

                return (
                  <StyledTableRow key={index.toString()}>
                    <Tooltip placement="top" title="Sil" arrow followCursor>
                      <StyledTableCell
                        sx={{ cursor: 'pointer', width: '30px' }}
                        onClick={() => {
                          dispatch(setNotificationRuleId(row.id));
                          dispatch(setIsDeletePopUpOpen(true));
                        }}
                        padding="none"
                        align="center">
                        <DeleteIcon />
                      </StyledTableCell>
                    </Tooltip>
                    <Tooltip placement="top" title="Düzenle" arrow followCursor>
                      <StyledTableCell
                        padding="none"
                        sx={{
                          borderRight: '1px solid #1e1e1e20',
                          cursor: 'pointer',
                          width: '30px',
                        }}
                        onClick={() => {
                          dispatch(setNotificationRule(row));
                          goToEditNotificationRuleHandler();
                        }}
                        align="center">
                        <EditIcon />
                      </StyledTableCell>
                    </Tooltip>
                    <StyledTableCell sx={{ width: '50px' }} align="center">
                      {getStatusTranslated(row.statusType)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '80px' }} align="center">
                      {renderFormattedPriority(row.alertPriority) === '-'
                        ? 'Tümü'
                        : renderFormattedPriority(row.alertPriority)}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ width: '120px' }}
                      align="center">{`${row.firstNotificationDelayInMinutes} dakika`}</StyledTableCell>
                    <StyledTableCell
                      sx={{ width: '140px' }}
                      align="center">{`${row.notificationIntervalInHours} saat`}</StyledTableCell>
                    <StyledTableCell sx={{ width: '160px' }} align="center">
                      {formatStringArray(row.errorCodes, 'Tümü')}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '150px' }} align="center">
                      {getChannelTranslated(row.notificationType)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '75px' }} align="center">
                      {row.description || '-'}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '50px' }} align="center">
                      <Tooltip placement="top" title={emails} arrow followCursor>
                        <EmailIcon />
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <StyledTableRow sx={{ cursor: 'default' }}>
                <CustomPagination
                  colSpan={tableHeads.length + 2}
                  totalCount={totalNotificationRule}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  onApplyPaginationFilter={onApplyPaginationFilter}
                />
              </StyledTableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default NotificationPanelTable;
