import * as Types from './types';
import { Component, ErrorInfo } from 'react';
import { ErrorBoundaryLink, ErrorBoundaryError, ErrorBoundaryButton, ErrorBoundaryContainer } from './styles';

class ErrorBoundary extends Component<Types.BoundaryProps, Types.State> {
  constructor(props: Types.BoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      showDetailedErrors: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  handleDetailedErrors() {
    this.setState({ showDetailedErrors: !this.state.showDetailedErrors });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContainer>
          <h3>Üzgünüm, bir şeyler yanlış gitti.</h3>

          <ErrorBoundaryLink href="/">Ana sayfaya gidin.</ErrorBoundaryLink>

          <ErrorBoundaryButton
            onClick={() => {
              this.handleDetailedErrors();
            }}>
            {!this.state.showDetailedErrors ? 'Ayrıntıları göster' : 'Ayrıntıları gizle'}
          </ErrorBoundaryButton>

          {this.state.showDetailedErrors && (
            <>
              <ErrorBoundaryError>{this.state.error?.message}</ErrorBoundaryError>
              <p>{this.state.errorInfo?.componentStack}</p>
            </>
          )}
        </ErrorBoundaryContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
