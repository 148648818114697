import request from 'library/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { getErrorCodes } = request;

const getCodes = createAsyncThunk('getErrorCodes', async () => {
  return await getErrorCodes();
});

const errorCodesActions = {
  getCodes,
};

export default errorCodesActions;
