import {
  TitleSpan,
  SortUpIcon,
  StyledPaper,
  SortDownIcon,
  StyledTableRow,
  TitleContainer,
  StyledTableCell,
  FilterContainer,
  CardTopContainer,
  additionalInfoCardStyle,
  additionalInfoCardContentStyle,
} from './styles';
import {
  Paper,
  Table,
  Button,
  TableRow,
  TextField,
  TableHead,
  TableBody,
  IconButton,
  TableFooter,
  TableContainer,
  TablePagination,
} from '@mui/material';
import request from 'library/request';
import { Card, Loader } from 'components';
import { ErrorLog } from 'library/request/types';
import ClearIcon from '@mui/icons-material/Clear';
import { ORDER_TYPE_ENUM, PERIOD_TYPE_ENUM } from 'enums';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { TableHeads } from 'containers/elements/integrations/integration-table';
import { RowByErrorCode, SelectBank, SelectErrorCodes, SelectPeriod, TablePaginationActions } from './components';

const { getErrorLogs } = request;

export type ErrorLogsFilterParams = {
  filterText: string;
  bankCodes: number[];
  errorCodes: string[];
  period: number;
};

const ErrorLogsTable = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errorLogList, setErrorLogList] = useState<ErrorLog[]>([]);
  const [filterParams, setFilterParams] = useState<ErrorLogsFilterParams>({
    filterText: '',
    bankCodes: [],
    errorCodes: [],
    period: PERIOD_TYPE_ENUM.WEEKLY,
  });

  const tableHeads: TableHeads[] = [
    {
      label: 'Hata Kodu',
      key: 'errorCode',
      isSort: 'none',
      sortType: null,
    },
    {
      label: 'Hata Açıklaması',
      key: 'errorDescription',
      isSort: 'none',
      sortType: null,
    },
    {
      label: 'Toplam Hata Sayısı',
      key: 'totalErrorCount',
      isSort: 'none',
      sortType: null,
    },
    {
      label: 'Toplam Çözüm Sayısı',
      key: 'totalSolutionCount',
      isSort: 'none',
      sortType: null,
    },
    {
      label: 'Ort. Çözüm Süresi',
      key: 'avarageSolutionTime',
      isSort: 'none',
      sortType: null,
    },
  ];

  const getValues = async () => {
    setLoading(true);

    const { data } = await getErrorLogs(filterParams);
    if (!data) return;

    setErrorLogList(data);
    setLoading(false);
  };

  const handleClearClick = () => {
    setFilterParams((prevState) => ({ ...prevState, filterText: '' }));
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getValues();
  }, []);

  return (
    <>
      <Paper elevation={5} sx={{ borderRadius: '15px' }}>
        <Card $additionalContainerStyle={additionalInfoCardStyle} $additionalStyle={additionalInfoCardContentStyle}>
          <CardTopContainer>
            <TitleContainer>
              <TitleSpan>Hata İstatistikleri</TitleSpan>
            </TitleContainer>
            <FilterContainer>
              <StyledPaper elevation={2}>
                <TextField
                  sx={{
                    width: '200px',
                    height: '32px',
                    borderRadius: '8px',
                    '& .MuiInputBase-root': {
                      height: '32px',
                    },
                    '& fieldset': {
                      borderRadius: '8px',
                      borderColor: '#00000080',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: filterParams.filterText ? 'visible' : 'hidden' }}
                        onClick={handleClearClick}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => setFilterParams((prevState) => ({ ...prevState, filterText: e.target.value }))}
                  placeholder="Grup Şirket"
                  id="outlined-basic-group-company"
                  variant="outlined"
                  value={filterParams.filterText}
                  defaultValue={filterParams.filterText}
                />
              </StyledPaper>
              <StyledPaper elevation={2}>
                <SelectBank onChange={setFilterParams} filterParams={filterParams} />
              </StyledPaper>
              <StyledPaper elevation={2}>
                <SelectErrorCodes onChange={setFilterParams} filterParams={filterParams} />
              </StyledPaper>
              <StyledPaper elevation={2}>
                <SelectPeriod onChange={setFilterParams} filterParams={filterParams} />
              </StyledPaper>
              <StyledPaper elevation={2}>
                <Button
                  sx={{
                    width: '200px',
                    height: '32px',
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 'medium',
                    backgroundColor: '#1877F2',
                  }}
                  onClick={() => getValues()}
                  variant="contained">
                  Filtrele
                </Button>
              </StyledPaper>
            </FilterContainer>
          </CardTopContainer>
          <TableContainer sx={{ boxShadow: 'none', backgroundColor: 'inherit' }} component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead sx={{ borderRadius: '12px' }} component={Paper}>
                <TableRow>
                  <StyledTableCell />
                  {tableHeads.map((tableHead, index) => (
                    <StyledTableCell
                      key={index.toString()}
                      sx={{
                        cursor: tableHead.isSort !== 'none' ? 'pointer' : 'default',
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                      }}
                      align="center">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxSizing: 'border-box',
                        }}>
                        <span style={{ marginRight: '5px' }}>{tableHead.label}</span>
                        {tableHead.isSort &&
                          tableHead.isSort !== 'none' &&
                          tableHead.sortType === ORDER_TYPE_ENUM.ASC && <SortDownIcon />}
                        {tableHead.isSort &&
                          tableHead.isSort !== 'none' &&
                          tableHead.sortType === ORDER_TYPE_ENUM.DESC && <SortUpIcon />}{' '}
                      </div>
                    </StyledTableCell>
                  ))}
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={tableHeads.length + 2} sx={{ cursor: 'default' }}>
                      <Loader />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : !errorLogList.length ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={tableHeads.length + 2} sx={{ cursor: 'default' }}>
                      Hatalı kayıt bulunmamaktadır.
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  errorLogList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((errorLog, index) => <RowByErrorCode key={index.toString()} row={errorLog} />)
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter sx={{ width: '100%', borderTop: '1px solid #BFBFBF' }}>
            <TablePagination
              component="div"
              labelRowsPerPage="Sayfa Başına Öğe:"
              labelDisplayedRows={({ from, to, count }) => {
                return `${count} satırdan ${from}-${to} arası`;
              }}
              rowsPerPageOptions={[5, 20, 50, 100]}
              colSpan={tableHeads.length + 2}
              count={errorLogList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Card>
      </Paper>
    </>
  );
};

export default ErrorLogsTable;
