import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import { User } from 'library/request/types';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Widget, Card, Loader } from 'components';
import Authentication from 'utils/authentication';
import { useCallback, useEffect, useState } from 'react';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { AddUserIcon, RightItemContainer, TextSpan } from './styles';
import { UsersDeletePopUp, UsersTable } from 'containers/elements/users';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { selectLoadingUpdate } from 'store/features/update-user/update-user.selector';

const { getUsersMap } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Kullanıcılar',
    path: '/users',
  },
];

export type UsersFilterParams = {
  pageSize: number;
  pageNumber: number;
};

const UsersMain = () => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);
  const loadingUpdate = useAppSelector(selectLoadingUpdate);

  const [loading, setLoading] = useState(false);
  const [totalUser, setTotalUser] = useState(0);
  const [userList, setUserList] = useState<User[]>([]);
  const [filterParams, setFilterParams] = useState<UsersFilterParams>({
    pageSize: 20,
    pageNumber: 1,
  });

  const onClear = () => {
    setFilterParams({
      pageSize: 20,
      pageNumber: 1,
    });
  };

  const goToAddUserHandler = useCallback(() => navigate('add'), []);

  const onApplyFilter = () => {
    setLoading(true);
    getUsersMap(filterParams).then((res) => {
      setTotalUser(res.totalCount);
      setUserList(res.result);
      setLoading(false);
    });
  };

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) => {
    setLoading(true);
    getUsersMap({ ...filterParams, pageSize, pageNumber }).then((res) => {
      setTotalUser(res.totalCount);
      setUserList(res.result);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getUsersMap(filterParams).then((res) => {
      setTotalUser(res.totalCount);
      setUserList(res.result);
      setLoading(false);
    });
  }, [loadingUpdate]);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'users');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Kullanıcılar" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Card
            leftItem={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? <div /> : null}
            rightItem={
              loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? (
                <RightItemContainer onClick={goToAddUserHandler}>
                  <AddUserIcon />
                  <TextSpan>Kullanıcı Ekle</TextSpan>
                </RightItemContainer>
              ) : null
            }>
            <UsersTable
              userList={userList}
              totalUser={totalUser}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              onApplyPaginationFilter={onApplyPaginationFilter}
            />
          </Card>
        )}
      </Widget>
      {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && <UsersDeletePopUp />}
    </>
  );
};

export default UsersMain;
