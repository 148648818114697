import { styled } from 'styled-components';
import * as Colors from 'assets/style-helpers/colors';

type InputContainerProps = {
  $error: boolean;
};

export const WrapperContainer = styled.div`
  position: relative;
  margin: 16px 0px;

  button {
    position: absolute;
    border: 0;
    width: 22px;
    height: 100%;
    right: 16px;
    top: 0;
    z-index: 99;
    background-color: transparent;
  }
`;

export const InputContainer = styled.input<InputContainerProps>`
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  line-height: 24px;
  background-color: ${Colors.bgInput};
  color: ${Colors.textInput} !important;

  border-bottom: ${({ $error }) => $error && `2px solid ${Colors.inputError}`};
  border-radius: ${({ $error }) => $error && `4px 4px 0px 0px`};

  &:focus {
    border: 0;
    box-shadow: none;
    outline: 0;
    border-bottom: 2px solid ${Colors.inputFocus};
    border-radius: 4px 4px 0px 0px;
  }
`;
