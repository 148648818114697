import * as Icons from 'assets/icons';
import AcibademSrc from 'assets/images/acibadem-logo.png';
import AkfenSrc from 'assets/images/akfen-logo.png';
import AlpaSrc from 'assets/images/alpa-logo.png';
import AnkutsanSrc from 'assets/images/ankutsan-logo.png';
import ArenaBilgisayarSrc from 'assets/images/arenabilgisayar-logo.png';
import BakiogluSrc from 'assets/images/bakioglu-logo.png';
import BermudaSrc from 'assets/images/bermuda-logo.png';
import BilkentSrc from 'assets/images/bilkent-logo.png';
import BiofarmaSrc from 'assets/images/biofarma-logo.png';
import BlutvSrc from 'assets/images/blutv-logo.png';
import CeynakSrc from 'assets/images/ceynak-logo.png';
import CherySrc from 'assets/images/chery-logo.png';
import CimentasSrc from 'assets/images/cimentas-logo.png';
import DentasSrc from 'assets/images/dentas-logo.png';
import DitasSrc from 'assets/images/ditas-logo.png';
import DoganSrc from 'assets/images/dogan-logo.png';
import DogtasKelebekSrc from 'assets/images/dogtaskelebek-logo.png';
import EAEElektrikSrc from 'assets/images/eaeelektrik-logo.png';
import EcoplasSrc from 'assets/images/ecoplas-logo.png';
import EAForceSrc from 'assets/images/eaforce-logo.png';
import ErbakirSrc from 'assets/images/erbakir-logo.png';
import ErenliSrc from 'assets/images/erenli-logo.png';
import FigoSrc from 'assets/images/figo-logo.png';
import FlokserSrc from 'assets/images/flokser-logo.png';
import FrimpeksSrc from 'assets/images/frimpeks-logo.png';
import GenveonSrc from 'assets/images/genveon-logo.png';
import GulsanSrc from 'assets/images/gulsan-logo.png';
import HabasSrc from 'assets/images/habas-logo.png';
import HascelikSrc from 'assets/images/hascelik-logo.png';
import HayatKimyaSrc from 'assets/images/hayatkimya-logo.png';
import IdeconSrc from 'assets/images/idecon-logo.png';
import KancaSrc from 'assets/images/kanca-logo.png';
import EgeKirmatasSrc from 'assets/images/cimentas-logo.png';
import KoyuncuSrc from 'assets/images/koyuncu-logo.png';
import KastamonuEntegreSrc from 'assets/images/hayatkimya-logo.png';
import KutlusanSrc from 'assets/images/kutlusan-logo.png';
import MapaSrc from 'assets/images/mapa-logo.png';
import MaxionSrc from 'assets/images/maxion-logo.png';
import MedipolitanSrc from 'assets/images/medipolitan-logo.png';
import MesaSrc from 'assets/images/mesa-logo.png';
import MigrosSrc from 'assets/images/migros-logo.png';
import MukellefSrc from 'assets/images/mukellef-logo.png';
import N11Src from 'assets/images/n11-logo.png';
import ProgidaSrc from 'assets/images/progida-logo.png';
import RecydiaSrc from 'assets/images/recydia-logo.png';
import SartenSrc from 'assets/images/sarten-logo.png';
import SayaSrc from 'assets/images/saya-logo.png';
import SilverlineSrc from 'assets/images/silverline-logo.png';
import StandardSrc from 'assets/images/standard-logo.png';
import StarwoodSrc from 'assets/images/starwood-logo.png';
import SuntekstilSrc from 'assets/images/suntekstil-logo.png';
import TestSrc from 'assets/images/test-logo.png';
import TirsanKardanSrc from 'assets/images/tirsankardan-logo.png';
import TkgSrc from 'assets/images/tkg-logo.png';
import TrendboxSrc from 'assets/images/trendbox-logo.png';
import YildizSrc from 'assets/images/yildiz-logo.png';
import YilteksSrc from 'assets/images/yilteks-logo.png';
import YurtbaySeramikSrc from 'assets/images/yurtbayseramik-logo.png';
import YurticiSrc from 'assets/images/yurtici-logo.png';
import ZorluHoldingSrc from 'assets/images/zorluholding-logo.png';

export enum ACTIVITY_TYPE_ENUM {
  OPEN = 0,
  AT_SOFTWARE_TEAM = 1,
  AT_SUPPORT_TEAM = 2,
  WAITING_ON_CUSTOMER = 3,
}

export const getActivityTranslated = (activity: number) =>
  ({
    [ACTIVITY_TYPE_ENUM.OPEN]: 'Açık',
    [ACTIVITY_TYPE_ENUM.AT_SOFTWARE_TEAM]: 'Yazılım Ekibinde',
    [ACTIVITY_TYPE_ENUM.AT_SUPPORT_TEAM]: 'Destek Ekibinde',
    [ACTIVITY_TYPE_ENUM.WAITING_ON_CUSTOMER]: 'Müşteride Bekliyor',
  }[activity]);

/////////////////////////////////////////////////////////////////////

export enum CHANNEL_TYPE_ENUM {
  EMAIL = 0,
  TELEGRAM = 1,
  EMAIL_AND_TELEGRAM = 2,
}

export const getChannelTranslated = (channel: number) =>
  ({
    [CHANNEL_TYPE_ENUM.EMAIL]: 'E-Posta',
    [CHANNEL_TYPE_ENUM.TELEGRAM]: 'Telegram',
    [CHANNEL_TYPE_ENUM.EMAIL_AND_TELEGRAM]: 'E-Posta ve Telegram',
  }[channel]);

/////////////////////////////////////////////////////////////////////

export enum INTEGRATION_STATUS_TYPE_ENUM {
  SUCCESS = 1,
  FAILED = 2,
}

export const getIntegrationStatusTranslated = (integrationStatus: number) =>
  ({
    [INTEGRATION_STATUS_TYPE_ENUM.SUCCESS]: 'Başarılı',
    [INTEGRATION_STATUS_TYPE_ENUM.FAILED]: 'Başarısız',
  }[integrationStatus]);

export const getIntegrationStatusIcon = (integrationStatus: number) =>
  ({
    [INTEGRATION_STATUS_TYPE_ENUM.SUCCESS]: <Icons.SuccessSvg style={{ color: '#1dcf4c' }} />,
    [INTEGRATION_STATUS_TYPE_ENUM.FAILED]: <Icons.UnsuccessSvg style={{ color: '#DE1F1F' }} />,
  }[integrationStatus]);

/////////////////////////////////////////////////////////////////////

export enum ORDER_TYPE_ENUM {
  ASC = 1,
  DESC = 2,
}

/////////////////////////////////////////////////////////////////////

export enum PERIOD_TYPE_ENUM {
  WEEKLY = 1,
  MONTHLY = 2,
  YEARLY = 3,
}

export const getPeriodTranslated = (period: number) =>
  ({
    [PERIOD_TYPE_ENUM.WEEKLY]: 'Haftalık',
    [PERIOD_TYPE_ENUM.MONTHLY]: 'Aylık',
    [PERIOD_TYPE_ENUM.YEARLY]: 'Yıllık',
  }[period]);

/////////////////////////////////////////////////////////////////////

export enum PRIORITY_TYPE_ENUM {
  NON_URGENT = 1,
  URGENT = 2,
  VERY_URGENT = 3,
}

export const getPriorityTranslated = (priority: number) =>
  ({
    [PRIORITY_TYPE_ENUM.NON_URGENT]: 'Önceliksiz',
    [PRIORITY_TYPE_ENUM.URGENT]: 'Acil',
    [PRIORITY_TYPE_ENUM.VERY_URGENT]: 'Çok Acil',
  }[priority]);

export const getPriorityIcon = (priority: number) =>
  ({
    [PRIORITY_TYPE_ENUM.NON_URGENT]: <Icons.GreenDotIcon />,
    [PRIORITY_TYPE_ENUM.URGENT]: <Icons.YellowDotIcon />,
    [PRIORITY_TYPE_ENUM.VERY_URGENT]: <Icons.RedDotIcon />,
  }[priority]);

/////////////////////////////////////////////////////////////////////

export enum ROLE_API_NAME_ENUM {
  USER = 'User',
  ADMIN = 'Admin',
  CUSTOMER = 'Customer',
}

export enum ROLE_TYPE_ENUM {
  USER = 1,
  ADMIN = 2,
  CUSTOMER = 3,
}

export const getRoleTranslated = (role: number) =>
  ({
    [ROLE_TYPE_ENUM.USER]: 'Kullanıcı',
    [ROLE_TYPE_ENUM.ADMIN]: 'Admin',
    [ROLE_TYPE_ENUM.CUSTOMER]: 'Müşteri',
  }[role]);

/////////////////////////////////////////////////////////////////////

export enum SERVER_TYPE_ENUM {
  ON_PREM = 1,
  CLOUD = 2,
}

export const getServerTranslated = (server: number) =>
  ({
    [SERVER_TYPE_ENUM.ON_PREM]: 'On-Prem',
    [SERVER_TYPE_ENUM.CLOUD]: 'Cloud',
  }[server]);

/////////////////////////////////////////////////////////////////////

export enum STATUS_TYPE_ENUM {
  ACTIVE = 1,
  PASSIVE = 2,
}

export const getStatusTranslated = (status: number) =>
  ({
    [STATUS_TYPE_ENUM.ACTIVE]: 'Aktif',
    [STATUS_TYPE_ENUM.PASSIVE]: 'Pasif',
  }[status]);

/////////////////////////////////////////////////////////////////////

export const GROUP_COMPANIES_ICONS_ENUM = [
  {
    userName: 'acibadem',
    icon: <img width="24px" height="24px" src={AcibademSrc} alt="Acıbadem" />,
  },
  {
    userName: 'akfen',
    icon: <img width="24px" height="24px" src={AkfenSrc} alt="AKFEN" />,
  },
  {
    userName: 'alpa',
    icon: <img width="24px" height="24px" src={AlpaSrc} alt="Alpa" />,
  },
  {
    userName: 'ankutsan',
    icon: <img width="24px" height="24px" src={AnkutsanSrc} alt="ANKUTSAN" />,
  },
  {
    userName: 'arena',
    icon: <img width="24px" height="24px" src={ArenaBilgisayarSrc} alt="ARENA BİLGİSAYAR" />,
  },
  {
    userName: 'bakioglu',
    icon: <img width="24px" height="24px" src={BakiogluSrc} alt="BAKİOĞLU" />,
  },
  {
    userName: 'bermuda',
    icon: <img width="24px" height="24px" src={BermudaSrc} alt="Bermuda" />,
  },
  {
    userName: 'bilkent',
    icon: <img width="24px" height="24px" src={BilkentSrc} alt="BİLKENT" />,
  },
  {
    userName: 'biofarma',
    icon: <img width="24px" height="24px" src={BiofarmaSrc} alt="BİOFARMA" />,
  },
  {
    userName: 'blutv',
    icon: <img width="24px" height="24px" src={BlutvSrc} alt="BluTV" />,
  },
  {
    userName: 'ceynak',
    icon: <img width="24px" height="24px" src={CeynakSrc} alt="CEYNAK" />,
  },
  {
    userName: 'chery',
    icon: <img width="24px" height="24px" src={CherySrc} alt="CHERY" />,
  },
  {
    userName: 'cimentas',
    icon: <img width="24px" height="24px" src={CimentasSrc} alt="Çimentaş" />,
  },
  {
    userName: 'dentas',
    icon: <img width="24px" height="24px" src={DentasSrc} alt="Dentaş" />,
  },
  {
    userName: 'ditasdogan',
    icon: <img width="24px" height="24px" src={DitasSrc} alt="DİTAS DOGAN" />,
  },
  {
    userName: 'doganholding',
    icon: <img width="24px" height="24px" src={DoganSrc} alt="DOĞAN HOLDİNG" />,
  },
  {
    userName: 'dogtas',
    icon: <img width="24px" height="24px" src={DogtasKelebekSrc} alt="DOĞTAŞ KELEBEK" />,
  },
  {
    userName: 'eaelektrik',
    icon: <img width="24px" height="24px" src={EAEElektrikSrc} alt="EAE ELEKTRİK" />,
  },
  {
    userName: 'ecoplas',
    icon: <img width="24px" height="24px" src={EcoplasSrc} alt="ECOPLAS" />,
  },
  {
    userName: 'eaforce',
    icon: <img width="24px" height="24px" src={EAForceSrc} alt="EA Force" />,
  },
  {
    userName: 'erbakir',
    icon: <img width="24px" height="24px" src={ErbakirSrc} alt="ERBAKIR" />,
  },
  {
    userName: 'erenli',
    icon: <img width="24px" height="24px" src={ErenliSrc} alt="ERENLI" />,
  },
  {
    userName: 'figo',
    icon: <img width="24px" height="24px" src={FigoSrc} alt="Figo" />,
  },
  {
    userName: 'flokser',
    icon: <img width="24px" height="24px" src={FlokserSrc} alt="FLOKSER" />,
  },
  {
    userName: 'frimpeks',
    icon: <img width="24px" height="24px" src={FrimpeksSrc} alt="FRİMPEKS" />,
  },
  {
    userName: 'genveon',
    icon: <img width="24px" height="24px" src={GenveonSrc} alt="Genveon" />,
  },
  {
    userName: 'gülsan',
    icon: <img width="24px" height="24px" src={GulsanSrc} alt="GÜLSAN" />,
  },
  {
    userName: 'habas',
    icon: <img width="24px" height="24px" src={HabasSrc} alt="HABAS" />,
  },
  {
    userName: 'hascelik',
    icon: <img width="24px" height="24px" src={HascelikSrc} alt="HASCELİK" />,
  },
  {
    userName: 'hayatkimya',
    icon: <img width="24px" height="24px" src={HayatKimyaSrc} alt="Hayat Kimya" />,
  },
  {
    userName: 'ideconteletek',
    icon: <img width="24px" height="24px" src={IdeconSrc} alt="IDECON TELETEK" />,
  },
  {
    userName: 'kanca',
    icon: <img width="24px" height="24px" src={KancaSrc} alt="KANCA" />,
  },
  {
    userName: 'kirmatas',
    icon: <img width="24px" height="24px" src={EgeKirmatasSrc} alt="Ege Kırmataş" />,
  },
  {
    userName: 'koyuncu',
    icon: <img width="24px" height="24px" src={KoyuncuSrc} alt="KOYUNCU" />,
  },
  {
    userName: 'kstentegre',
    icon: <img width="24px" height="24px" src={KastamonuEntegreSrc} alt="Kastamonu Entegre" />,
  },
  {
    userName: 'kutlusan',
    icon: <img width="24px" height="24px" src={KutlusanSrc} alt="KUTLUSAN" />,
  },
  {
    userName: 'mapa',
    icon: <img width="24px" height="24px" src={MapaSrc} alt="MAPA" />,
  },
  {
    userName: 'maxion',
    icon: <img width="24px" height="24px" src={MaxionSrc} alt="Maxion" />,
  },
  {
    userName: 'medipolitan',
    icon: <img width="24px" height="24px" src={MedipolitanSrc} alt="Medipolitan" />,
  },
  {
    userName: 'mesa',
    icon: <img width="24px" height="24px" src={MesaSrc} alt="MESA" />,
  },
  {
    userName: 'migros',
    icon: <img width="24px" height="24px" src={MigrosSrc} alt="Migros" />,
  },
  {
    userName: 'mukellef',
    icon: <img width="24px" height="24px" src={MukellefSrc} alt="Mükellef" />,
  },
  {
    userName: 'n11',
    icon: <img width="24px" height="24px" src={N11Src} alt="N11" />,
  },
  {
    userName: 'progida',
    icon: <img width="24px" height="24px" src={ProgidaSrc} alt="Progıda" />,
  },
  {
    userName: 'recydia',
    icon: <img width="24px" height="24px" src={RecydiaSrc} alt="Recydia" />,
  },
  {
    userName: 'sarten',
    icon: <img width="24px" height="24px" src={SartenSrc} alt="SARTEN" />,
  },
  {
    userName: 'saya',
    icon: <img width="24px" height="24px" src={SayaSrc} alt="SAYA" />,
  },
  {
    userName: 'silverline',
    icon: <img width="24px" height="24px" src={SilverlineSrc} alt="SİLVERLİNE" />,
  },
  {
    userName: 'standardprofil',
    icon: <img width="24px" height="24px" src={StandardSrc} alt="STANDARD PROFİL" />,
  },
  {
    userName: 'starwood',
    icon: <img width="24px" height="24px" src={StarwoodSrc} alt="STARWOOD" />,
  },
  {
    userName: 'suntekstil',
    icon: <img width="24px" height="24px" src={SuntekstilSrc} alt="SUN TEKSTİL" />,
  },
  {
    userName: 'test',
    icon: <img width="24px" height="24px" src={TestSrc} alt="Demo Şirket" />,
  },
  {
    userName: 'tirsan',
    icon: <img width="24px" height="24px" src={TirsanKardanSrc} alt="Tirsan" />,
  },
  {
    userName: 'tkg',
    icon: <img width="24px" height="24px" src={TkgSrc} alt="TKG" />,
  },
  {
    userName: 'trendbox',
    icon: <img width="24px" height="24px" src={TrendboxSrc} alt="Trendbox" />,
  },
  {
    userName: 'yildiz',
    icon: <img width="24px" height="24px" src={YildizSrc} alt="Yıldız Holding" />,
  },
  {
    userName: 'yilteks',
    icon: <img width="24px" height="24px" src={YilteksSrc} alt="Yılteks" />,
  },
  {
    userName: 'yurtbay',
    icon: <img width="24px" height="24px" src={YurtbaySeramikSrc} alt="YURTBAY SERAMİK" />,
  },
  {
    userName: 'yurtiçi',
    icon: <img width="24px" height="24px" src={YurticiSrc} alt="Yurtiçi" />,
  },
  {
    userName: 'zorlu',
    icon: <img width="24px" height="24px" src={ZorluHoldingSrc} alt="Zorlu Holding" />,
  },
];
