import { Loader } from 'components';
import { ErrorCode } from 'library/request/types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AddNotificationRuleFormParams } from 'pages/notification-panel-add';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { selectErrorCodesMap, selectIsLoading } from 'store/features/error-codes/error-codes.selector';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

type AddNotificationRuleSelectErrorCodesProps = {
  onChange: Dispatch<SetStateAction<AddNotificationRuleFormParams>>;
  formParams: AddNotificationRuleFormParams;
};

const AddNotificationRuleSelectErrorCodes = ({ onChange, formParams }: AddNotificationRuleSelectErrorCodesProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const errorCodeMap = useAppSelector(selectErrorCodesMap);

  const [selectedErrorCodes, setSelectedErrorCodes] = useState<ErrorCode[]>([]);

  useEffect(() => {
    if (!errorCodeMap.length) {
      dispatch(errorCodesActions.getCodes());
    }
  }, [errorCodeMap]);

  useEffect(() => {
    if (!formParams.errorCodes.length) {
      setSelectedErrorCodes([]);
    }
  }, [formParams.errorCodes]);

  return loading ? (
    <Loader />
  ) : (
    <Autocomplete
      multiple
      size="small"
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      fullWidth
      value={selectedErrorCodes}
      id="checkboxes-tags-demo"
      options={errorCodeMap}
      disableCloseOnSelect
      onChange={(event: any, newValue: ErrorCode[]) => {
        setSelectedErrorCodes(newValue);

        const errorCodes = newValue.map((value) => value.code);
        onChange((prevState: AddNotificationRuleFormParams) => ({ ...prevState, errorCodes }));
      }}
      getOptionLabel={(option) => option.code}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.code}
        </li>
      )}
      renderInput={(params) => <TextField {...params} />}
      ListboxProps={{
        style: {
          maxHeight: '220px',
        },
      }}
    />
  );
};

export default AddNotificationRuleSelectErrorCodes;
