import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { EditErrorCodeFormParams } from 'pages/error-codes-edit';
import { PRIORITY_TYPE_ENUM, getPriorityTranslated } from 'enums';

type EditErrorCodeSelectPriorityProps = {
  onChange: Dispatch<SetStateAction<EditErrorCodeFormParams>>;
  formParams: EditErrorCodeFormParams;
};

const EditErrorCodeSelectPriority = ({ onChange, formParams }: EditErrorCodeSelectPriorityProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const alertPriority = Number(event.target.value);
    onChange((prevState: EditErrorCodeFormParams) => ({ ...prevState, alertPriority }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-priority-dialog-label"
      id="select-priority-dialog"
      value={String(formParams.alertPriority)}
      defaultValue={String(formParams.alertPriority)}
      onChange={handleChange}>
      {(Object.keys(PRIORITY_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof PRIORITY_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={PRIORITY_TYPE_ENUM[key]}>
            {getPriorityTranslated(PRIORITY_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default EditErrorCodeSelectPriority;
