import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { SelectCompaniesState } from './select-companies.types';

const selectSelectCompaniesReducer = (state: RootState): SelectCompaniesState => state.selectCompanies;

export const selectFetchCompaniesInitialized = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.selectedCompaniesInitialized
);

export const selectLoading = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.isLoading
);

export const selectCompanies = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.companies
);

export const selectSelectedCompanies = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.selectedCompanies
);

export const selectIsDropdownOpen = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.isDropdownOpen
);

export const selectFade = createSelector([selectSelectCompaniesReducer], (selectCompanies) => selectCompanies.fade);

export const selectSelectedTaxNumbers = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.selectedTaxNumbers
);

export const selectSelectedGroupCompanies = createSelector(
  [selectSelectCompaniesReducer],
  (selectCompanies) => selectCompanies.selectedGroupCompanies
);
