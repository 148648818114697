import { css, styled } from 'styled-components';
import { bgDropdownOption } from 'assets/style-helpers/colors';

export const CompanyFilterCardContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;

  color: #1a1a1ade;
  background-color: ${bgDropdownOption};
  box-shadow: 0px 10px 27px 8px rgba(255, 255, 255, 0.83);

  border-bottom: 1px solid rgba(30, 30, 30, 0.12);
  height: 115px;
`;

export const InputContainer = styled.div`
  width: 94%;
  margin: auto;
  padding-top: 5px;
`;

export const ButtonContainer = styled.div`
  margin: -16px auto;
  padding: 6px;
  width: 94%;
`;

export const additionalButtonStyle = css`
  line-height: 18px;
`;
