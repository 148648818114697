import request from 'library/request';
import {
  AddUserTextfieldName,
  AddUserSelectRoleType,
  AddUserTextfieldEmail,
  AddUserTextfieldSurname,
  AddUserTextfieldPassword,
  AddUserTextfieldUserName,
  AddUserCheckboxUseTelegram,
  AddUserSelectGroupCompanies,
  AddUserTextfieldTelegramUserId,
  AddUserTextfieldTelegramUserName,
} from 'containers/elements/users-add';
import { StyledPaper } from './styles';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Card, Widget, Text } from 'components';
import Authentication from 'utils/authentication';
import { Box, Grid, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import { ROLE_API_NAME_ENUM, ROLE_TYPE_ENUM } from 'enums';
import usersActions from 'store/features/users/users.actions';
import FormButtons from 'containers/elements/@commons/form-buttons';

const { createUser } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Kullanıcılar',
    path: '/users',
  },
  {
    name: 'Kullanıcı Ekle',
    path: '/users/add',
  },
];

export type AddUserFormParams = {
  name: string;
  email: string;
  surname: string;
  userName: string;
  password: string;
  roleType: number;
  useTelegram: boolean;
  telegramUserId: string;
  telegramUserName: string;
  companyGroupIds: number[];
};

const UsersAdd = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<AddUserFormParams>({
    name: '',
    email: '',
    surname: '',
    roleType: 1,
    userName: '',
    password: '',
    companyGroupIds: [],
    telegramUserName: '',
    telegramUserId: '',
    useTelegram: false,
  });

  const onClear = () => {
    setFormParams({
      name: '',
      email: '',
      surname: '',
      roleType: 1,
      userName: '',
      password: '',
      companyGroupIds: [],
      telegramUserName: '',
      telegramUserId: '',
      useTelegram: false,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (
      formParams.name === '' ||
      formParams.email === '' ||
      formParams.surname === '' ||
      formParams.userName === '' ||
      formParams.password === ''
    ) {
      setErrorMessage(
        formParams.name === ''
          ? 'Ad boş geçilemez.'
          : formParams.surname === ''
          ? 'Soyad boş geçilemez.'
          : formParams.userName === ''
          ? 'Kullanıcı Adı boş geçilemez.'
          : formParams.password === ''
          ? 'Parola boş geçilemez.'
          : formParams.email === ''
          ? 'E-Posta boş geçilemez.'
          : ''
      );
      return setError(true);
    }

    if (formParams.useTelegram && (formParams.telegramUserName === '' || formParams.telegramUserId === '')) {
      setErrorMessage('Varsayılan telegram olarak seçili iken telegram kullanıcı adı veya id boş bırakılamaz.');
      return setError(true);
    }

    setLoading(true);

    createUser(formParams)
      .then((res) => {
        if (res.message === 'Bazı hatalar oluştu') {
          setErrorMessage(res.validationErrors?.[0].errorMessage as string);
          setError(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        dispatch(usersActions.getUsers());
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  useEffect(() => {
    if (formParams.roleType === 2) {
      setFormParams((prevState) => ({ ...prevState, companyGroupIds: [] }));
    }
  }, [formParams.roleType]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Kullanıcı Ekle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Ad</span>
                  <StyledPaper>
                    <AddUserTextfieldName onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Soyad</span>
                  <StyledPaper>
                    <AddUserTextfieldSurname onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Rol</span>
                  <StyledPaper>
                    <AddUserSelectRoleType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Kullanıcı Adı</span>
                  <StyledPaper>
                    <AddUserTextfieldUserName onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Parola</span>
                  <StyledPaper>
                    <AddUserTextfieldPassword onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>E-Posta</span>
                  <StyledPaper>
                    <AddUserTextfieldEmail onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Telegram Kullanıcı Adı</span>
                  <StyledPaper>
                    <AddUserTextfieldTelegramUserName onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Telegram Kullanıcı Id</span>
                  <StyledPaper>
                    <AddUserTextfieldTelegramUserId onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                {formParams.roleType !== ROLE_TYPE_ENUM.ADMIN ? (
                  <Grid item xs={12} md={4}>
                    <span>Grup Şirketler</span>
                    <StyledPaper>
                      <AddUserSelectGroupCompanies onChange={setFormParams} formParams={formParams} />
                    </StyledPaper>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4}>
                    <span>Grup Şirketler</span>
                    <StyledPaper>
                      <TextField
                        sx={{
                          '& div': {
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: 'medium',
                          },
                        }}
                        size="small"
                        fullWidth
                        id="outlined-read-only-input-groupCompaniesAll"
                        defaultValue={'Tüm Grup Şirketler İçin Yetki Verilecektir'}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </StyledPaper>
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <AddUserCheckboxUseTelegram onChange={setFormParams} formParams={formParams} />
                </Grid>
              </Grid>
              <FormButtons
                loading={loading}
                actionName={'Oluştur'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default UsersAdd;
