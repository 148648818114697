import App from './App';
import store from 'store/store';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary, Version } from 'providers';
import reportWebVitals from 'utils/reportWebVitals';

const rootElement = document.getElementById('app-root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <Version>
        <Provider store={store}>
          <App />
        </Provider>
      </Version>
    </ErrorBoundary>
  </StrictMode>
);

reportWebVitals();
