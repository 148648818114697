import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { AddNotificationRuleFormParams } from 'pages/notification-panel-add';

type AddNotificationRuleTextfieldNotificationIntervalInHoursProps = {
  onChange: Dispatch<SetStateAction<AddNotificationRuleFormParams>>;
  formParams: AddNotificationRuleFormParams;
  error: boolean;
};

const AddNotificationRuleTextfieldNotificationIntervalInHours = ({
  onChange,
  formParams,
  error,
}: AddNotificationRuleTextfieldNotificationIntervalInHoursProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const notificationIntervalInHours = Number(event.target.value);
    onChange((prevState: AddNotificationRuleFormParams) => ({ ...prevState, notificationIntervalInHours }));
  };
  return (
    <TextField
      size="small"
      sx={
        error && formParams.notificationIntervalInHours <= 0
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      type="number"
      fullWidth
      onChange={handleChange}
      id="outlined-basic"
      variant="outlined"
    />
  );
};

export default AddNotificationRuleTextfieldNotificationIntervalInHours;
