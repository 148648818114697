import { styled } from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 6vh;
  padding: 10px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #1e1e1e29;
  justify-content: center;
  align-items: center;
`;

export const HeaderSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: 17px;
  color: #1e1e1e;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const DetailSpan = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  height: 4vh;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const DetailSpanTitleSpan = styled.span`
  font-weight: 600;
`;

export const FullMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  line-height: 1.5;
  height: 12vh;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const MessageHeader = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: large;
  margin: 0 20px;
`;

export const MessageContentContainer = styled.div`
  display: flex;
  justify-content: left;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  height: 70px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ShortMessageContentContainer = styled(MessageContentContainer)`
  margin: 0;
`;

export const MessageContent = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: smaller;
  width: 100%;
`;

export const OperationSpan = styled(DetailSpan)`
  justify-content: center;

  @media (max-width: 500px) {
    font-weight: 600;
  }
`;

export const OperationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  background-color: #e8e8e840;

  height: 30vh;
  width: 100%;
  border: 0.1px solid #00000033;
  border-radius: 4px;
`;

export const CommentOutContainer = styled.div`
  position: absolute;
  width: 50vw;
  height: 10.5vh;
  border: 1px solid black;
  border-radius: 4px;
  margin: 10px 0px 0px 0px;
  padding: 10px;
  box-sizing: border-box;

  button[disabled] {
    border-radius: 12px;
    background-color: #0f62feaa;
    color: white;
    cursor: not-allowed;
    height: 30px;
    width: 90px;
    border: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;

    @media (max-width: 500px) {
      height: 25px;
      width: 45px;
      bottom: 5px;
      right: 5px;
      border-radius: 6px;
      font-size: 6px;
    }
  }

  @media (max-width: 500px) {
    width: 75vw;
  }
`;

export const InputContainer = styled.textarea`
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  resize: none;
`;

export const ButtonContainer = styled.button`
  font-family: 'Open Sans', sans-serif;
  color: white;
  background-color: #0f62fe;
  height: 30px;
  width: 90px;
  border-radius: 12px;
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 12px;

  @media (max-width: 500px) {
    height: 25px;
    width: 45px;
    bottom: 5px;
    right: 5px;
    border-radius: 6px;
    font-size: 6px;
  }
`;
