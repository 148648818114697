import * as Icons from 'assets/icons';
import styled from 'styled-components';

const isBiggerScreen = window.innerWidth > 1560;

export const CenterContainer = styled.div`
  position: relative;
  font-family: 'Open Sans', sans-serif;
`;

export const CardContainer = styled.div`
  width: ${isBiggerScreen ? '440px' : '408px'};
  height: 240px;
  background-color: #fff;
  background: linear-gradient(#f8f8f8, #fff);
  // box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &:hover aside {
    width: 100%;
    border-radius: 0 5px 5px 0;
  }

  @media (max-width: 428px) {
    width: 368.6px;
  }
`;

type AdditionalContainerProps = {
  $isError: boolean;
};

export const AdditionalContainer = styled.aside<AdditionalContainerProps>`
  position: absolute;
  width: ${isBiggerScreen ? '146.1px' : '135.5px'};
  height: 100%;
  transition: width 0.4s;
  overflow: hidden;
  z-index: 2;

  background: ${({ $isError }) => ($isError ? '#DC685F' : '#97c1ab')};

  @media (max-width: 428px) {
    width: 122.5px;
  }
`;

export const UserCardContainer = styled.div`
  width: ${isBiggerScreen ? '146.1px' : '135.5px'};
  height: 100%;
  position: relative;
  float: left;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 10%;
    right: -2px;
    height: 80%;
    border-left: 2px solid rgba(0, 0, 0, 0.025);
  }

  @media (max-width: 428px) {
    width: 122.5px;
  }
`;

export const TopSideContainer = styled.div`
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 13px;
  padding-top: 30px;
  box-sizing: border-box;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    right: 7px;
    height: 13px;
    width: 13px;
    border-top: 1px solid #d9d9d9;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    right: 7px;
    height: 13px;
    width: 13px;
    border-right: 1px solid #d9d9d9;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100px;
    left: 7px;
    height: 13px;
    width: 13px;
    border-bottom: 1px solid #d9d9d9;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100px;
    left: 7px;
    height: 13px;
    width: 13px;
    border-left: 1px solid #d9d9d9;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CompanyIcon = styled(Icons.GroupCompaniesSvg)`
  height: 24px;
  width: 24px;
  color: #fafafa;
`;

export const CompanyCountSpan = styled.span`
  display: flex;
  color: #fafafa;
  font-size: 24px;
  font-weight: 600;
`;

export const CompanyTextSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 11px;
  padding-left: 5px;
  margin-top: 15px;
`;

export const BottomSideContainer = styled.div`
  height: 50%;
  width: 100%;
  position: absolute;
  top: 120px;
  left: 0px;
  padding: 13px;
  box-sizing: border-box;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    height: 13px;
    width: 13px;
    border-top: 1px solid #d9d9d9;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    height: 13px;
    width: 13px;
    border-left: 1px solid #d9d9d9;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100px;
    right: 7px;
    height: 13px;
    width: 13px;
    border-bottom: 1px solid #d9d9d9;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100px;
    right: 7px;
    height: 13px;
    width: 13px;
    border-right: 1px solid #d9d9d9;
  }
`;

export const IntegrationContainer = styled(CompanyContainer)``;

export const IntegrationIcon = styled(Icons.IntegrationsSvg)`
  height: 24px;
  width: 24px;
  color: #fafafa;
`;

export const IntegrationCountSpan = styled(CompanyCountSpan)``;

export const IntegrationTextSpan = styled(CompanyTextSpan)`
  letter-spacing: 0;
  padding-left: 0;
`;

export const IntegrationDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const IntegrationDetailContainer = styled.div`
  cursor: pointer;
  height: 20px;
  width: 50px;
  background-color: #ffffff40;
  border-radius: 8px;
  padding: 0 5px;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const SuccessIntegrationsIcon = styled(Icons.SuccessSvg)`
  color: #fafafa;
  height: 14px;
  width: 14px;
`;

export const SuccessIntegrationCountSpan = styled.span`
  display: flex;
  color: #fafafa;
  font-size: 12px;
  font-weight: 600;
`;

export const UnsuccessIntegrationsIcon = styled(Icons.UnsuccessSvg)`
  color: #fafafa;
  height: 14px;
  width: 14px;
`;

export const UnsuccessIntegrationCountSpan = styled(SuccessIntegrationCountSpan)``;

export const MoreInfoContainer = styled.div`
  width: ${isBiggerScreen ? '292px' : '271px'};
  float: left;
  position: absolute;
  left: ${isBiggerScreen ? '146.1px' : '135.5px'};
  height: 100%;

  @media (max-width: 428px) {
    width: 244.5px;
    left: 128.7px;
  }
`;

export const HideTitleSpan = styled.span`
  position: absolute;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  left: 20px;
  top: 25px;
`;

export const ErrorMessagesContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

type ErrorMessagesIconProps = {
  $hide: boolean;
};

export const ErrorMessagesIcon = styled(Icons.InformationSvg)<ErrorMessagesIconProps>`
  height: 20px;
  width: 20px;

  color: ${({ $hide }) => ($hide ? '#fff' : '#DC685F')};
`;

export const ConsumptionsContainer = styled.div`
  position: absolute;
  top: 90px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ConsumptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WhiteTextContainer = styled.div`
  height: 14px;
  width: fit-content;
  background-color: #f1f1f140;
  border-radius: 8px;
  padding: 0 8px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  margin-left: 25px;
  margin-bottom: 5px;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const MemoryIcon = styled(Icons.MemorySvg)`
  color: #fafafa;
  height: 18px;
  width: 18px;
`;

export const ConsumptionRateSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #fafafa;
`;

export const DiskIcon = styled(Icons.DiskSvg)`
  color: #fafafa;
  height: 18px;
  width: 18px;
`;

export const CpuIcon = styled(Icons.CpuSvg)`
  color: #fafafa;
  height: 18px;
  width: 18px;
`;

export const GeneralContainer = styled.div`
  width: ${isBiggerScreen ? '292px' : '271px'};
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  box-sizing: border-box;
  padding-top: 0;

  @media (max-width: 428px) {
    width: 246.5px;
  }
`;

type VisibleTitleSpanProps = {
  $isError: boolean;
};

export const VisibleTitleSpan = styled.span<VisibleTitleSpanProps>`
  position: absolute;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  left: 20px;
  top: 25px;

  color: ${({ $isError }) => ($isError ? '#DC685F' : '#97c1ab')};
`;

export const VisibleDetailsContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const VisibleDetailContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

type CloudinaryIconProps = {
  $isError: boolean;
};

export const CloudinaryIcon = styled(Icons.CloudinarySvg)<CloudinaryIconProps>`
  height: 24px;
  width: 24px;

  color: ${({ $isError }) => ($isError ? '#DC685F' : '#97c1ab')};
`;

export const GreyTextContainer = styled.div`
  height: 20px;
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 0 8px;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type IpAddressIconProps = {
  $isError: boolean;
};

export const IpAddressIcon = styled(Icons.IpAddressSvg)<IpAddressIconProps>`
  height: 24px;
  width: 24px;

  color: ${({ $isError }) => ($isError ? '#DC685F' : '#97c1ab')};
`;

type SupportIconProps = {
  $isError: boolean;
};

export const SupportIcon = styled(Icons.SupportSvg)<SupportIconProps>`
  height: 24px;
  width: 24px;

  color: ${({ $isError }) => ($isError ? '#DC685F' : '#97c1ab')};
`;

export const LastAccessDateContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 15px;
  display: flex;
  gap: 10px;
`;

type SuccessIconProps = {
  $isError: boolean;
};

export const SuccessIcon = styled(Icons.SuccessSvg)<SuccessIconProps>`
  height: 14px;
  width: 14px;

  color: ${({ $isError }) => ($isError ? '#DC685F' : '#97c1ab')};
`;

export const UnsuccessIcon = styled(Icons.UnsuccessSvg)`
  color: #dc685f;
  height: 14px;
  width: 14px;
`;

export const MoreSpan = styled.div`
  font-size: 12px;
  font-weight: 500;
`;
