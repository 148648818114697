import 'moment/locale/tr';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { FilterParams } from 'pages/integrations';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type DatepickerProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const DatepickerStartDate = ({ onChange, filterParams }: DatepickerProps) => {
  const handleChange = (value: any) => {
    const spreadDate = { ...value };
    const date = spreadDate._d;
    const formattedDate = moment(date).format();

    onChange((prevState: FilterParams) => ({ ...prevState, startDate: formattedDate }));
  };

  const value = typeof filterParams.startDate === 'string' ? moment(filterParams.startDate) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
      <DatePicker
        slotProps={{ textField: { size: 'small', fullWidth: true } }}
        value={value}
        label="Başlangıç Tarihi"
        onChange={(value) => handleChange(value)}
        sx={{
          '& div': {
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 'medium',
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatepickerStartDate;
