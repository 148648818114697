import { useAppSelector } from 'store/hooks';
import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { selectUsersMap } from 'store/features/users/users.selector';
import { EditGroupCompanyFormParams } from 'pages/group-companies-edit';

type EditGroupCompanySelectSupportPersonProps = {
  onChange: Dispatch<SetStateAction<EditGroupCompanyFormParams>>;
  formParams: EditGroupCompanyFormParams;
};

const EditGroupCompanySelectSupportPerson = ({ onChange, formParams }: EditGroupCompanySelectSupportPersonProps) => {
  const users = useAppSelector(selectUsersMap);

  const handleChange = (event: SelectChangeEvent) => {
    const defaultSupportPerson = event.target.value as string;
    onChange((prevState: EditGroupCompanyFormParams) => ({ ...prevState, defaultSupportPerson }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-support-person-edit-label"
      id="select-support-person-edit"
      value={formParams.defaultSupportPerson}
      defaultValue={formParams.defaultSupportPerson}
      onChange={handleChange}>
      {users.map((user, index) => (
        <MenuItem key={index.toString()} value={user.value}>
          {user.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EditGroupCompanySelectSupportPerson;
