import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { UpdateUserState } from './update-user.types';

const selectUpdateUserReducer = (state: RootState): UpdateUserState => state.updateUser;

export const selectUser = createSelector([selectUpdateUserReducer], (updateUser) => updateUser.user);

export const selectUserName = createSelector([selectUpdateUserReducer], (updateUser) => updateUser.userName);

export const selectErrorUpdate = createSelector([selectUpdateUserReducer], (updateUser) => updateUser.errorUpdate);

export const selectLoadingUpdate = createSelector([selectUpdateUserReducer], (updateUser) => updateUser.loadingUpdate);

export const selectIsDeletePopUpOpen = createSelector(
  [selectUpdateUserReducer],
  (updateUser) => updateUser.isPopUpOpen
);

export const selectUserWithGroupCompanies = createSelector(
  [selectUpdateUserReducer],
  (updateUser) => updateUser.userWithGroupCompanies
);
