import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { DrawerSolutionsState } from './drawer-solutions.types';

const selectDrawerSolutionsReducer = (state: RootState): DrawerSolutionsState => state.drawerSolutions;

export const selectIsLoading = createSelector(
  [selectDrawerSolutionsReducer],
  (drawerSolutions) => drawerSolutions.isLoading
);

export const selectSelectedIntegration = createSelector(
  [selectDrawerSolutionsReducer],
  (drawerSolutions) => drawerSolutions.selectedIntegration
);

export const selectMarkedCommentId = createSelector(
  [selectDrawerSolutionsReducer],
  (drawerSolutions) => drawerSolutions.markedCommentId
);

export const selectSelectedCompanyIntegrationId = createSelector(
  [selectDrawerSolutionsReducer],
  (drawerSolutions) => drawerSolutions.selectedCompanyIntegrationId
);
