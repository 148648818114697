import { createSlice } from '@reduxjs/toolkit';
import { UpdateUserState } from './update-user.types';
import updateUserActions from './update-user.action';

const initialState: UpdateUserState = {
  user: null,
  error: null,
  userName: null,
  isLoading: false,
  isPopUpOpen: false,
  errorUpdate: false,
  loadingUpdate: false,
  userWithGroupCompanies: null,
};

const updateUserSlice = createSlice({
  initialState,
  name: 'update-user',
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
    setUserName(state, { payload }) {
      state.userName = payload;
    },
    setErrorUpdate(state, { payload }) {
      state.errorUpdate = payload;
    },
    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
    setIsDeletePopUpOpen(state, { payload }) {
      state.isPopUpOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserActions.getUserByUsername.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserActions.getUserByUsername.fulfilled, (state, { payload }) => {
      state.userWithGroupCompanies = payload;
      state.isLoading = false;
    });

    builder.addCase(updateUserActions.getUserByUsername.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export const { setUser, setUserName, setErrorUpdate, setLoadingUpdate, setIsDeletePopUpOpen } = updateUserSlice.actions;

export default updateUserSlice.reducer;
