import { createSlice } from '@reduxjs/toolkit';
import { Company } from 'library/request/types';
import { getDistincTaxNumber } from 'utils/format-tax-numbers';
import { SelectCompaniesState } from './select-companies.types';
import selectCompaniesActions from './select-companies.actions';

const initialState: SelectCompaniesState = {
  fade: 1,
  error: null,
  companies: [],
  isLoading: false,
  selectedCompanies: [],
  isDropdownOpen: false,
  selectedTaxNumbers: [],
  selectedGroupCompanies: [],
  selectedCompaniesInitialized: false,
};

export const selectCompaniesSlice = createSlice({
  initialState,
  name: 'selectCompanies',
  reducers: {
    fadeOut: (state, { payload }) => {
      state.fade = payload;
    },
    onFilterCompany: (state, { payload }) => {
      state.selectedCompanies = payload;

      payload[0]?.isAll ? (state.selectedTaxNumbers = []) : (state.selectedTaxNumbers = getDistincTaxNumber(payload));

      const allGroups = state.companies.filter((groupCompany) => groupCompany.isNode);

      payload[0]?.isAll
        ? (state.selectedGroupCompanies = allGroups)
        : (state.selectedGroupCompanies =
            payload.filter((selectedCompany: Company) => selectedCompany.defaultSupportPerson).length > 0
              ? payload.filter((selectedCompany: Company) => selectedCompany.defaultSupportPerson)
              : allGroups);
    },
    setIsDropdownOpen: (state, { payload }) => {
      state.isDropdownOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectCompaniesActions.initialize.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      selectCompaniesActions.initialize.fulfilled,
      (state, { payload: { companies, selectedCompanies, selectedGroupCompanies } }) => {
        state.error = null;
        state.isLoading = false;
        state.companies = companies;
        state.selectedCompaniesInitialized = true;
        state.selectedCompanies = selectedCompanies;
        state.selectedGroupCompanies = selectedGroupCompanies;
      }
    );

    builder.addCase(selectCompaniesActions.initialize.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export const { setIsDropdownOpen, fadeOut, onFilterCompany } = selectCompaniesSlice.actions;

export default selectCompaniesSlice.reducer;
