import { TextContainer } from './styles';
import { HTMLAttributes, ReactNode } from 'react';

type TextProps = {
  onClick?: () => void;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Text = ({ children, onClick = () => {}, ...props }: TextProps) => {
  return (
    <TextContainer onClick={onClick} {...props}>
      {children}
    </TextContainer>
  );
};

export default Text;
