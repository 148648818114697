import { ReactNode } from 'react';
import { ContentContainer } from './styles';

type ContentProps = {
  children: ReactNode;
  $pt?: string;
};

const Content = ({ children, $pt }: ContentProps) => {
  return <ContentContainer $pt={$pt}>{children}</ContentContainer>;
};

export default Content;
