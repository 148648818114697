import { Dispatch, SetStateAction } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AddNotificationRuleFormParams } from 'pages/notification-panel-add';

type AddNotificationRuleCheckboxNotNotifySupportStaffProps = {
  onChange: Dispatch<SetStateAction<AddNotificationRuleFormParams>>;
  formParams: AddNotificationRuleFormParams;
};
const AddNotificationRuleCheckboxNotNotifySupportStaff = ({
  onChange,
  formParams,
}: AddNotificationRuleCheckboxNotNotifySupportStaffProps) => {
  const handleChange = (event: any) => {
    const notNotifySupportStaff = event.target.checked;
    onChange((prevState) => ({ ...prevState, notNotifySupportStaff }));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      sx={{
        fontFamily: '"Open Sans", sans-serif',
      }}
      labelPlacement="end"
      label="Destek Personeli'ne Bildirim Gitmesin."
      checked={formParams.notNotifySupportStaff}
      onChange={handleChange}
    />
  );
};

export default AddNotificationRuleCheckboxNotNotifySupportStaff;
