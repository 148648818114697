import * as Icons from 'assets/icons';
import { styled } from 'styled-components';
import { bgHeader } from 'assets/style-helpers/colors';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 0px 0px 12px 12px;
  padding: 30px 20px;
  max-height: 75px;
  box-sizing: border-box;
  background-color: ${bgHeader};

  @media (max-width: 500px) {
    padding: 30px 10px !important;
  }
`;

export const HeaderDefaultContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderDefaultLeftContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  @media (max-width: 500px) {
    padding-left: 10px;
  }
`;

export const HeaderDefaultRightContainer = styled.div`
  display: flex;
  flex-grow: 11;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 24px;

  > div {
    margin: 0px 8px;
  }
`;

export const IconContainer = styled.div`
  margin: 0px 15px !important;
`;

export const SettingsIcon = styled(Icons.SettingsSvg)`
  width: 24px;
  height: 24px;
  color: #121212ab;
`;

export const NotificationIcon = styled(Icons.NotificationSvg)`
  width: 24px;
  height: 24px;
`;

export const UserContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #1a1a1a;

  @media (max-width: 500px) {
    display: none;
  }

  &::first-letter {
    text-transform: capitalize;
  }
`;
