import { Route, Routes } from 'react-router-dom';
import { MyProfileMain, MyProfileEdit } from '..';

const MyProfile = () => {
  return (
    <Routes>
      <Route index element={<MyProfileMain />} />
      <Route path="edit" element={<MyProfileEdit />} />
    </Routes>
  );
};

export default MyProfile;
