import { styled } from 'styled-components';

export const TextContainer = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  font-style: normalize;
  font-stretch: normal;
  color: '#000000de';
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1.05px;
  }
`;
