import { useAppDispatch } from 'store/hooks';
import { User } from 'library/request/types';
import { useNavigate } from 'react-router-dom';
import Authentication from 'utils/authentication';
import { UsersFilterParams } from 'pages/users-main';
import { renderFormattedTime } from 'utils/format-time';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { CustomPagination } from 'containers/elements/@commons';
import updateUserActions from 'store/features/update-user/update-user.action';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { EditIcon, DeleteIcon, StyledTableCell, StyledTableRow } from './styles';
import { ROLE_API_NAME_ENUM, getRoleTranslated, getStatusTranslated } from 'enums';
import { setIsDeletePopUpOpen, setUserName } from 'store/features/update-user/update-user.slice';
import { Table, Paper, Tooltip, TableRow, TableBody, TableHead, TableFooter, TableContainer } from '@mui/material';

type UsersTableProps = {
  totalUser: number;
  userList: User[];
  filterParams: UsersFilterParams;
  setFilterParams: Dispatch<SetStateAction<UsersFilterParams>>;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
};

const UsersTable = ({
  userList: rows,
  totalUser,
  filterParams,
  setFilterParams,
  onApplyPaginationFilter,
}: UsersTableProps) => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToEditUserHandler = useCallback(() => navigate('edit'), []);

  return (
    <TableContainer
      sx={{
        width: '100%',
        overflow: 'hidden',
        overflowX: 'scroll',
        transform: 'rotateX(180deg)',

        '&::-webkit-scrollbar': {
          display: 'block',
          width: '7px',
          height: '7px',
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: '12px',
          backgroundColor: '#0f62fe',
        },

        '&::-webkit-scrollbar-track': {
          backgroundColor: '#dfdfdf57',
          borderRadius: '12px',

          '&::-webkit-scrollbar-thumb:hover': {
            background: '#5490ff',
          },
        },
      }}
      component={Paper}>
      <Table sx={{ width: '1800px', transform: 'rotateX(180deg)' }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
              <>
                <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                <StyledTableCell
                  colSpan={1}
                  sx={{ borderRight: '1px solid #1e1e1e20' }}
                  align="center"></StyledTableCell>
              </>
            )}
            <StyledTableCell align="center">Ad</StyledTableCell>
            <StyledTableCell align="center">Soyad</StyledTableCell>
            <StyledTableCell align="center">Kullanıcı Adı</StyledTableCell>
            <StyledTableCell align="center">Rol</StyledTableCell>
            <StyledTableCell align="center">Durum</StyledTableCell>
            <StyledTableCell align="center">E-Posta</StyledTableCell>
            <StyledTableCell align="center">Telegram Kullanıcı Adı</StyledTableCell>
            <StyledTableCell align="center">Telegram Kullanıcı Id</StyledTableCell>
            <StyledTableCell align="center">Oluşturulma Tarihi</StyledTableCell>
            <StyledTableCell align="center">Son Oturum Tarihi</StyledTableCell>
            <StyledTableCell align="center">Son IP Adresi</StyledTableCell>
          </TableRow>
        </TableHead>
        {!totalUser ? (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? 13 : 11}>
                Kullanıcı bulunmamaktadır.
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index.toString()}>
                  {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
                    <>
                      <Tooltip placement="top" title="Sil" arrow followCursor>
                        <StyledTableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            dispatch(setUserName(row.userName));
                            dispatch(setIsDeletePopUpOpen(true));
                          }}
                          padding="none"
                          align="center">
                          <DeleteIcon />
                        </StyledTableCell>
                      </Tooltip>
                      <Tooltip placement="top" title="Düzenle" arrow followCursor>
                        <StyledTableCell
                          padding="none"
                          sx={{
                            borderRight: '1px solid #1e1e1e20',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            dispatch(updateUserActions.getUserByUsername(row.userName));
                            goToEditUserHandler();
                          }}
                          align="center">
                          <EditIcon />
                        </StyledTableCell>
                      </Tooltip>
                    </>
                  )}
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.surname}</StyledTableCell>
                  <StyledTableCell align="center">{row.userName}</StyledTableCell>
                  <StyledTableCell align="center">{getRoleTranslated(row.roleType)}</StyledTableCell>
                  <StyledTableCell align="center">{getStatusTranslated(row.statusType)}</StyledTableCell>
                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="center">{row.telegramUserName || '-'}</StyledTableCell>
                  <StyledTableCell align="center">{row.telegramUserId || '-'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.createdDate ? renderFormattedTime(row.createdDate) : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.lastLoginDate ? renderFormattedTime(row.lastLoginDate) : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.lastIp || '-'}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow sx={{ cursor: 'default' }}>
                <CustomPagination
                  colSpan={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? 9 : 7}
                  totalCount={totalUser}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  onApplyPaginationFilter={onApplyPaginationFilter}
                />
              </StyledTableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
