import { Paper, TextField } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export const StyledPaper = styledMui(Paper)({ borderRadius: '8px' });

export const StyledTextField = styledMui(TextField)({
  '& div': {
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'medium',
  },
});
