import { createSlice } from '@reduxjs/toolkit';
import { UpdateErrorCodeState } from './update-error-code.types';

const initialState: UpdateErrorCodeState = {
  code: null,
  errorCode: null,
  isPopUpOpen: false,
  errorUpdate: false,
  loadingUpdate: false,
};

const updateErrorCodeSlice = createSlice({
  initialState,
  name: 'update-error-code',
  reducers: {
    setCode(state, { payload }) {
      state.code = payload;
    },
    setErrorCode(state, { payload }) {
      state.errorCode = payload;
    },
    setErrorUpdate(state, { payload }) {
      state.errorUpdate = payload;
    },
    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
    setIsDeletePopUpOpen(state, { payload }) {
      state.isPopUpOpen = payload;
    },
  },
});

export const { setErrorCode, setCode, setErrorUpdate, setLoadingUpdate, setIsDeletePopUpOpen } =
  updateErrorCodeSlice.actions;

export default updateErrorCodeSlice.reducer;
