import { BanksState } from './banks.types';
import banksActions from './banks.actions';
import { createSlice } from '@reduxjs/toolkit';

const initialState: BanksState = {
  error: null,
  banksMap: [],
  isLoading: false,
};

export const banksSlice = createSlice({
  initialState,
  name: 'banks',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(banksActions.getBanks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(banksActions.getBanks.fulfilled, (state, { payload }) => {
      state.banksMap = payload;
      state.isLoading = false;
    });

    builder.addCase(banksActions.getBanks.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export default banksSlice.reducer;
