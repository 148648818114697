import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TitleContainer = styled.div`
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.18px;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #000000de;
`;

export const BreadCrumbListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BreadCrumbContainer = styled(NavLink)`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 20px;
  margin-left: 4px;
  color: #00000061;
  text-decoration: none;

  &:first-child {
    margin-left: 0px;
  }
`;
