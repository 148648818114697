import * as Styles from './styles';
import { Tooltip } from '@mui/material';
import { ProgressBar } from './components';
import { getServerTranslated } from 'enums';
import { useNavigate } from 'react-router-dom';
import { Company } from 'library/request/types';
import { renderFormattedTime } from 'utils/format-time';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDistincTaxNumber } from 'utils/format-tax-numbers';
import { selectUsersMap } from 'store/features/users/users.selector';
import { renderFormattedConsumptionRate } from 'utils/format-consumption-rate';
import { onFilterCompany } from 'store/features/select-companies/select-companies.slice';

type SummaryCardProps = {
  groupCompany: Company;
};

const SummaryCard = ({ groupCompany }: SummaryCardProps) => {
  const dispatch = useAppDispatch();
  const usersMap = useAppSelector(selectUsersMap);

  const navigate = useNavigate();

  const [user] = usersMap.filter((user) => user.value === groupCompany.defaultSupportPerson);

  const diskConsumption = renderFormattedConsumptionRate(groupCompany.diskCapacity, groupCompany.diskUsedSpace);
  const memoryConsumption = renderFormattedConsumptionRate(groupCompany.memoryCapacity, groupCompany.memoryUsedSpace);
  const CPUConsumption = groupCompany.cpuUsage ? Number(groupCompany.cpuUsage.replaceAll(',', '.')) : 0;

  const infoMessages = groupCompany.errors?.length
    ? groupCompany.errors
        .map((error) => error.errorMessage)
        .reduce((accumulator, currentValue) => `${accumulator}, ${currentValue}`)
    : '';

  const goToIntegrations = (integrationStatus: number) => {
    navigate('/integrations', {
      state: {
        taxNumbers: getDistincTaxNumber([groupCompany]),
        integrationStatus: integrationStatus,
      },
    });
    dispatch(onFilterCompany([groupCompany]));
  };

  return (
    <Styles.CenterContainer>
      <Styles.CardContainer>
        <Styles.AdditionalContainer $isError={groupCompany.isError as boolean}>
          <Styles.UserCardContainer>
            <Styles.TopSideContainer>
              <Styles.CompanyContainer>
                <Styles.CompanyIcon />
                <Styles.CompanyCountSpan>{groupCompany.companies?.length}</Styles.CompanyCountSpan>
              </Styles.CompanyContainer>
              <Styles.CompanyTextSpan>ŞİRKET</Styles.CompanyTextSpan>
            </Styles.TopSideContainer>
            <Styles.BottomSideContainer>
              <Styles.IntegrationContainer>
                <Styles.IntegrationIcon />
                <Styles.IntegrationCountSpan>{groupCompany.integrationCount}</Styles.IntegrationCountSpan>
              </Styles.IntegrationContainer>
              <Styles.IntegrationTextSpan>ENTEGRASYON</Styles.IntegrationTextSpan>
              <Styles.IntegrationDetailsContainer>
                <Styles.IntegrationDetailContainer onClick={() => goToIntegrations(1)}>
                  <Styles.SuccessIntegrationsIcon />
                  <Styles.SuccessIntegrationCountSpan>
                    {groupCompany.successIntegrationCount}
                  </Styles.SuccessIntegrationCountSpan>
                </Styles.IntegrationDetailContainer>
                <Styles.IntegrationDetailContainer onClick={() => goToIntegrations(2)}>
                  <Styles.UnsuccessIntegrationsIcon />
                  <Styles.UnsuccessIntegrationCountSpan>
                    {groupCompany.errorIntegrationCount}
                  </Styles.UnsuccessIntegrationCountSpan>
                </Styles.IntegrationDetailContainer>
              </Styles.IntegrationDetailsContainer>
            </Styles.BottomSideContainer>
          </Styles.UserCardContainer>
          <Styles.MoreInfoContainer>
            <Styles.HideTitleSpan>{groupCompany.title}</Styles.HideTitleSpan>
            {groupCompany.isError && (
              <Styles.ErrorMessagesContainer>
                <Tooltip arrow followCursor placement="top" title={infoMessages}>
                  <Styles.ErrorMessagesIcon $hide />
                </Tooltip>
              </Styles.ErrorMessagesContainer>
            )}
            <Styles.ConsumptionsContainer>
              <Styles.ConsumptionContainer>
                <Styles.WhiteTextContainer>RAM</Styles.WhiteTextContainer>
                <Styles.ProgressBarContainer>
                  <Styles.MemoryIcon />
                  <ProgressBar value={memoryConsumption} isError={groupCompany.isError as boolean} />
                  <Styles.ConsumptionRateSpan>{`${Math.trunc(memoryConsumption)} %`}</Styles.ConsumptionRateSpan>
                </Styles.ProgressBarContainer>
              </Styles.ConsumptionContainer>
              <Styles.ConsumptionContainer>
                <Styles.WhiteTextContainer>DİSK</Styles.WhiteTextContainer>
                <Styles.ProgressBarContainer>
                  <Styles.DiskIcon />
                  <ProgressBar value={diskConsumption} isError={groupCompany.isError as boolean} />
                  <Styles.ConsumptionRateSpan>{`${Math.trunc(diskConsumption)} %`}</Styles.ConsumptionRateSpan>
                </Styles.ProgressBarContainer>
              </Styles.ConsumptionContainer>
              <Styles.ConsumptionContainer>
                <Styles.WhiteTextContainer>CPU</Styles.WhiteTextContainer>
                <Styles.ProgressBarContainer>
                  <Styles.CpuIcon />
                  <ProgressBar value={CPUConsumption} isError={groupCompany.isError as boolean} />
                  <Styles.ConsumptionRateSpan>{`${CPUConsumption.toFixed(0)} %`}</Styles.ConsumptionRateSpan>
                </Styles.ProgressBarContainer>
              </Styles.ConsumptionContainer>
            </Styles.ConsumptionsContainer>
          </Styles.MoreInfoContainer>
        </Styles.AdditionalContainer>
        <Styles.GeneralContainer>
          <Styles.VisibleTitleSpan $isError={groupCompany.isError as boolean}>
            {groupCompany.title}
          </Styles.VisibleTitleSpan>
          {groupCompany.isError && (
            <Styles.ErrorMessagesContainer>
              <Styles.ErrorMessagesIcon $hide={false} />
            </Styles.ErrorMessagesContainer>
          )}
          <Styles.VisibleDetailsContainer>
            <Styles.VisibleDetailContainer>
              <Styles.CloudinaryIcon $isError={groupCompany.isError as boolean} />
              <Styles.GreyTextContainer>
                {getServerTranslated(groupCompany.serverType as number)}
              </Styles.GreyTextContainer>
            </Styles.VisibleDetailContainer>
            <Styles.VisibleDetailContainer>
              <Styles.IpAddressIcon $isError={groupCompany.isError as boolean} />
              <Styles.GreyTextContainer>{groupCompany.ipAddress || '-'}</Styles.GreyTextContainer>
            </Styles.VisibleDetailContainer>
            <Styles.VisibleDetailContainer>
              <Styles.SupportIcon $isError={groupCompany.isError as boolean} />
              <Styles.GreyTextContainer>{user?.label || '-'}</Styles.GreyTextContainer>
            </Styles.VisibleDetailContainer>
          </Styles.VisibleDetailsContainer>
          <Styles.LastAccessDateContainer>
            {/* {isAccessible ? ( */}
            <Styles.SuccessIcon $isError={groupCompany.isError as boolean} />
            {/* ) : (
              <Styles.UnsuccessIcon />
            )} */}
            <Styles.MoreSpan>
              Son Erişim Tarihi:{' '}
              {groupCompany.lastAccessDate ? renderFormattedTime(groupCompany.lastAccessDate) : '--.--.----'}
            </Styles.MoreSpan>
          </Styles.LastAccessDateContainer>
        </Styles.GeneralContainer>
      </Styles.CardContainer>
    </Styles.CenterContainer>
  );
};

export default SummaryCard;
