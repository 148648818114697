import {
  TitleSpan,
  SortUpIcon,
  SortDownIcon,
  StyledTableRow,
  TitleContainer,
  StyledTableCell,
  FilterContainer,
  CardTopContainer,
  additionalInfoCardStyle,
  additionalInfoCardContentStyle,
} from './styles';
import {
  Paper,
  Table,
  Button,
  Select,
  Drawer,
  Tooltip,
  TableRow,
  MenuItem,
  TextField,
  TableHead,
  TableBody,
  TableFooter,
  TableContainer,
} from '@mui/material';
import request from 'library/request';
import { Card, Loader } from 'components';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import DrawerContent from './drawer-content';
import { SortType } from 'pages/integrations';
import { sortHandler } from 'utils/render-sort';
import { CustomPagination } from 'containers/elements/@commons';
import { renderFormattedPriority } from 'utils/format-priority';
import { selectUsersMap } from 'store/features/users/users.selector';
import { renderFormattedIconCompany } from 'utils/format-icon-company';
import { IntegrationByFilter, OngoingIntegration } from 'library/request/types';
import { TableHeads } from 'containers/elements/integrations/integration-table';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { ORDER_TYPE_ENUM, PRIORITY_TYPE_ENUM, getPriorityTranslated } from 'enums';

const { getOngoingIntegrationErrors, getIntegrationById } = request;

export type OngoingIntegrationErrorsFilterParams = {
  pageNumber: number;
  pageSize: number;
  filterText: string;
  alertPriority: number | null;
  selector: string;
  orderByType: number | null;
};

const UnsuccessIntegrationsTable = () => {
  const usersMap = useAppSelector(selectUsersMap);

  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [totalIntegration, setTotalIntegration] = useState(0);
  const [integrationList, setIntegrationList] = useState<OngoingIntegration[]>([]);
  const [loadingClickedIntegration, setLoadingClickedIntegration] = useState(false);
  const [clickedIntegration, setClickedIntegration] = useState<IntegrationByFilter | null>(null);
  const [filterParams, setFilterParams] = useState<OngoingIntegrationErrorsFilterParams>({
    pageNumber: 1,
    pageSize: 5,
    alertPriority: null,
    filterText: '',
    selector: '',
    orderByType: null,
  });

  const tableHeads: TableHeads[] = [
    {
      label: 'Şirket Grubu',
      key: 'companyGroupName',
      isSort: filterParams.selector === 'companyGroupName',
      sortType: filterParams.selector === 'companyGroupName' ? filterParams.orderByType : null,
    },
    {
      label: 'Şirket Adı',
      key: 'companyName',
      isSort: filterParams.selector === 'companyName',
      sortType: filterParams.selector === 'companyName' ? filterParams.orderByType : null,
    },
    {
      label: 'Banka Adı',
      key: 'bankName',
      isSort: filterParams.selector === 'bankName',
      sortType: filterParams.selector === 'bankName' ? filterParams.orderByType : null,
    },
    {
      label: 'Hata Kodu',
      key: 'errorCode',
      isSort: filterParams.selector === 'errorCode',
      sortType: filterParams.selector === 'errorCode' ? filterParams.orderByType : null,
    },
    {
      label: 'Öncelik',
      key: 'alertPriority',
      isSort: filterParams.selector === 'alertPriority',
      sortType: filterParams.selector === 'alertPriority' ? filterParams.orderByType : null,
    },
    {
      label: 'Geçen Süre',
      key: 'lastNotificationSentDate',
      isSort: filterParams.selector === 'lastNotificationSentDate',
      sortType: filterParams.selector === 'lastNotificationSentDate' ? filterParams.orderByType : null,
    },
    {
      label: 'Son Başarılı Tarih',
      key: 'lastSuccessfulDate',
      isSort: filterParams.selector === 'lastSuccessfulDate',
      sortType: filterParams.selector === 'lastSuccessfulDate' ? filterParams.orderByType : null,
    },
    {
      label: 'Destek Personeli',
      key: 'defaultSupportPerson',
      isSort: filterParams.selector === 'defaultSupportPerson',
      sortType: filterParams.selector === 'defaultSupportPerson' ? filterParams.orderByType : null,
    },
  ];

  const getValues = async (newParams: any = null) => {
    setLoading(true);

    const { data } = newParams
      ? await getOngoingIntegrationErrors({ ...filterParams, ...newParams })
      : await getOngoingIntegrationErrors(filterParams);
    if (!data) return;

    setTotalIntegration(data.totalCount);
    setIntegrationList(data.result);
    setLoading(false);
  };

  const onApplySortFilter = ({ selector, orderByType }: SortType) => getValues({ selector, orderByType });

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) =>
    getValues({ pageSize, pageNumber });

  useEffect(() => {
    getValues();
  }, []);

  return (
    <>
      <Paper elevation={5} sx={{ borderRadius: '15px' }}>
        <Card $additionalContainerStyle={additionalInfoCardStyle} $additionalStyle={additionalInfoCardContentStyle}>
          <CardTopContainer>
            <TitleContainer>
              <TitleSpan>Bildirim Sonrası Devam Eden Hatalı Entegrasyonlar</TitleSpan>
            </TitleContainer>
            <FilterContainer>
              <Paper
                sx={{
                  width: '200px',
                  height: '32px',
                  borderRadius: '8px',
                }}
                elevation={2}>
                <TextField
                  sx={{
                    width: '200px',
                    height: '32px',
                    borderRadius: '8px',
                    '& .MuiInputBase-root': {
                      height: '32px',
                    },
                    '& fieldset': {
                      borderRadius: '8px',
                      borderColor: '#00000080',
                    },
                  }}
                  onChange={(e) => setFilterParams((prevState) => ({ ...prevState, filterText: e.target.value }))}
                  placeholder="Grup Şirket"
                  id="outlined-basic-group-company"
                  variant="outlined"
                />
              </Paper>
              <Paper
                sx={{
                  width: '200px',
                  height: '32px',
                  borderRadius: '8px',
                }}
                elevation={2}>
                <Select
                  sx={{
                    width: '200px',
                    height: '32px',
                    borderRadius: '8px',
                    '& .MuiInputBase-root': {
                      height: '32px',
                    },
                    '& fieldset': {
                      borderRadius: '8px',
                      borderColor: '#00000080',
                    },
                  }}
                  onChange={(event) => {
                    const alertPriority = event.target.value === 'Tümü' ? null : Number(event.target.value);
                    setFilterParams((prevState) => ({ ...prevState, alertPriority }));
                  }}
                  placeholder="Öncelik"
                  labelId="outlined-basic-alert-priority-label"
                  id="outlined-basic-alert-priority"
                  variant="outlined"
                  value={filterParams.alertPriority ? String(filterParams.alertPriority) : 'Tümü'}
                  defaultValue={filterParams.alertPriority ? String(filterParams.alertPriority) : 'Tümü'}>
                  <MenuItem value={'Tümü'}>Tümü</MenuItem>
                  {(
                    Object.keys(PRIORITY_TYPE_ENUM).filter((v) =>
                      isNaN(Number(v))
                    ) as (keyof typeof PRIORITY_TYPE_ENUM)[]
                  ).map((key, index) => (
                    <MenuItem key={index.toString()} value={PRIORITY_TYPE_ENUM[key]}>
                      {getPriorityTranslated(PRIORITY_TYPE_ENUM[key])}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
              <Paper
                sx={{
                  width: '200px',
                  height: '32px',
                  borderRadius: '8px',
                }}
                elevation={3}>
                <Button
                  sx={{
                    width: '200px',
                    height: '32px',
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 'medium',
                    backgroundColor: '#1877F2',
                  }}
                  onClick={() => getValues()}
                  variant="contained">
                  Filtrele
                </Button>
              </Paper>
            </FilterContainer>
          </CardTopContainer>
          <TableContainer sx={{ boxShadow: 'none', backgroundColor: 'inherit' }} component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead sx={{ borderRadius: '12px' }} component={Paper}>
                <TableRow>
                  {tableHeads.map((tableHead, index) => (
                    <StyledTableCell
                      key={index.toString()}
                      sx={{
                        cursor: tableHead.isSort !== 'none' ? 'pointer' : 'default',
                        fontSize: '16px',
                        color: '#1e1e1ee9 !important',
                        fontWeight: 'medium',
                      }}
                      onClick={() => sortHandler({ tableHead, setFilterParams, onApplySortFilter })}
                      align="center">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxSizing: 'border-box',
                        }}>
                        <span style={{ marginRight: '5px' }}>{tableHead.label}</span>
                        {tableHead.isSort &&
                          tableHead.isSort !== 'none' &&
                          tableHead.sortType === ORDER_TYPE_ENUM.ASC && <SortDownIcon />}
                        {tableHead.isSort &&
                          tableHead.isSort !== 'none' &&
                          tableHead.sortType === ORDER_TYPE_ENUM.DESC && <SortUpIcon />}
                      </div>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={tableHeads.length} sx={{ cursor: 'default' }}>
                      <Loader />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : !totalIntegration ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={tableHeads.length} sx={{ cursor: 'default' }}>
                      Bildirim sonrası devam eden hatalı entegrasyon bulunmamaktadır.
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  integrationList.map((integration: OngoingIntegration, index: number) => {
                    const [user] = usersMap.filter((user) => user.value === integration.defaultSupportPerson);

                    const timestampSinceNotification = integration.lastNotificationSentDate
                      ? new Date().getTime() - new Date(integration.lastNotificationSentDate).getTime()
                      : null;

                    const timestampSinceError = integration.lastSuccessfulDate
                      ? new Date().getTime() - new Date(integration.lastSuccessfulDate).getTime()
                      : null;

                    const formattedFractionlessTime = (timestamp: number | null) => {
                      if (!timestamp) return '-';

                      return timestamp >= 365 * 24 * 60 * 60 * 1000
                        ? `${(timestamp / (365 * 24 * 60 * 60 * 1000)).toFixed(0)} yıl`
                        : timestamp >= 31 * 24 * 60 * 60 * 1000
                        ? `${Math.trunc(timestamp / (31 * 24 * 60 * 60 * 1000))} ay`
                        : timestamp >= 7 * 24 * 60 * 60 * 1000
                        ? `${Math.trunc(timestamp / (7 * 24 * 60 * 60 * 1000))} hafta`
                        : timestamp >= 24 * 60 * 60 * 1000
                        ? `${Math.trunc(timestamp / (24 * 60 * 60 * 1000))} gün`
                        : timestamp >= 60 * 60 * 1000
                        ? `${Math.trunc(timestamp / (60 * 60 * 1000))} saat`
                        : timestamp >= 60 * 1000
                        ? `${Math.trunc(timestamp / (60 * 1000))} dakika`
                        : `Henüz`;
                    };

                    return (
                      <StyledTableRow
                        onClick={() => {
                          setLoadingClickedIntegration(true);

                          getIntegrationById({ id: integration.companyIntegrationId })
                            .then((res) => {
                              setClickedIntegration(res);
                              setIsDrawerOpen(true);
                            })
                            .finally(() => {
                              setLoadingClickedIntegration(false);
                            });
                        }}
                        key={index.toString()}>
                        <StyledTableCell>
                          {renderFormattedIconCompany(
                            integration.companyGroupName,
                            integration.companyGroupUsername,
                            20
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">{integration.companyName}</StyledTableCell>
                        <Tooltip arrow followCursor placement="top" title={`Banka Kodu: ${integration.bankCode}`}>
                          <StyledTableCell align="center">{integration.bankName}</StyledTableCell>
                        </Tooltip>
                        <Tooltip arrow followCursor placement="top" title={integration.errorCodeDescription}>
                          <StyledTableCell align="center">{integration.errorCode}</StyledTableCell>
                        </Tooltip>
                        <StyledTableCell width="80px" align="center">
                          {renderFormattedPriority(integration.alertPriority)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {formattedFractionlessTime(timestampSinceNotification)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {formattedFractionlessTime(timestampSinceError)}
                        </StyledTableCell>
                        <StyledTableCell align="center">{user?.label || '-'}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
              </TableBody>
              {!loading && totalIntegration > 0 && (
                <TableFooter>
                  <StyledTableRow sx={{ cursor: 'default' }}>
                    <CustomPagination
                      sx={{ borderBottom: 'none', borderTop: '1px solid #00000040' }}
                      options={[5, 20, 50, 100, { label: 'Hepsi', value: -1 }]}
                      colSpan={tableHeads.length}
                      totalCount={totalIntegration}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                      onApplyPaginationFilter={onApplyPaginationFilter}
                    />
                  </StyledTableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Paper>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DrawerContent
          setIsDrawerOpen={setIsDrawerOpen}
          clickedIntegration={clickedIntegration}
          loadingClickedIntegration={loadingClickedIntegration}
        />
      </Drawer>
    </>
  );
};

export default UnsuccessIntegrationsTable;
