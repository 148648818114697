import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { PRIORITY_TYPE_ENUM, getPriorityTranslated } from 'enums';
import { EditNotificationRuleFormParams } from 'pages/notification-panel-edit';

type EditNotificationRuleSelectPriorityProps = {
  onChange: Dispatch<SetStateAction<EditNotificationRuleFormParams>>;
  formParams: EditNotificationRuleFormParams;
};

const EditNotificationRuleSelectPriority = ({ onChange, formParams }: EditNotificationRuleSelectPriorityProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const alertPriority = event.target.value === 'Tümü' ? null : Number(event.target.value);
    onChange((prevState: EditNotificationRuleFormParams) => ({ ...prevState, alertPriority }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-priority-dialog-label"
      id="select-priority-dialog"
      value={formParams.alertPriority ? String(formParams.alertPriority) : 'Tümü'}
      defaultValue={formParams.alertPriority ? String(formParams.alertPriority) : 'Tümü'}
      onChange={handleChange}>
      <MenuItem value={'Tümü'}>Tümü</MenuItem>
      {(Object.keys(PRIORITY_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof PRIORITY_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={PRIORITY_TYPE_ENUM[key]}>
            {getPriorityTranslated(PRIORITY_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default EditNotificationRuleSelectPriority;
