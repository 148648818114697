import * as Icons from 'assets/icons';
import { RuleSet, styled } from 'styled-components';

export const ExpandIconClose = styled(Icons.NavigationCloseSvg)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-left: 4px;
  color: #1e1e1e !important;
`;

export const ExpandIconMore = styled(Icons.NavigationExpandMoreSvg)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-left: 4px;
  color: #1e1e1e !important;
`;

export const ExpandIconLess = styled(Icons.NavigationExpandLessSvg)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-left: 4px;
  color: #1e1e1e !important;
`;

type SelectContainerProps = {
  $additionalSelectContainerStyle?: RuleSet;
};

export const SelectContainer = styled.div<SelectContainerProps>`
  outline: none;
  caret-color: rgb(190, 190, 190);
  color: rgb(85, 85, 85);
  display: flex;
  align-items: center;
  width: max-content;
  height: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  padding: 4px 8px;

  ${({ $additionalSelectContainerStyle }) => $additionalSelectContainerStyle && $additionalSelectContainerStyle}
`;

type SelectItemSelectedContainerProps = {
  $additionalSelectItemSelectedContainerStyle?: RuleSet;
};

export const SelectItemSelectedContainer = styled.div<SelectItemSelectedContainerProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-bottom: unset;
  padding: unset;
  display: inline-block;

  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #1e1e1e;

  ${({ $additionalSelectItemSelectedContainerStyle }) =>
    $additionalSelectItemSelectedContainerStyle && $additionalSelectItemSelectedContainerStyle}
`;

type SelectItemContainerProps = {
  $additionalSelectItemContainerStyle?: RuleSet;
};

export const SelectItemContainer = styled.ul<SelectItemContainerProps>`
  list-style: none;
  position: absolute;
  display: inline-block;
  border-radius: 12px;
  margin: 0;
  padding: 0;
  opacity: 0;
  top: -100vw;
  visibility: hidden;
  z-index: 1001;
  overflow: auto;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: #fff;

  ${({ $additionalSelectItemContainerStyle }) =>
    $additionalSelectItemContainerStyle && $additionalSelectItemContainerStyle}
`;
