import List from '@mui/material/List';
import { styled } from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const StyledList = styledMui(List)(() => ({
  width: '100%',
  backgroundColor: '#e8e8e840',
  overflow: 'hidden',
  overflowY: 'scroll',

  '&::-webkit-scrollbar': {
    display: 'block',
    width: '7px',
    height: '7px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
    backgroundColor: '#0f62fe',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#dfdfdf57',
    borderRadius: '12px',

    '&::-webkit-scrollbar-thumb:hover': {
      background: '#5490ff',
    },
  },
}));

export const TextContainer = styled.div`
  height: 32vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;
