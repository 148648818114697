import { TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { ErrorCodesFilterParams } from 'pages/error-codes-main';

type ErrorCodesTextfieldDescriptionProps = {
  onChange: Dispatch<SetStateAction<ErrorCodesFilterParams>>;
  filterParams: ErrorCodesFilterParams;
};

const ErrorCodesTextfieldDescription = ({ onChange, filterParams }: ErrorCodesTextfieldDescriptionProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const description = event.target.value;
    onChange((prevState: ErrorCodesFilterParams) => ({ ...prevState, description }));
  };
  return (
    <TextField
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      size="small"
      fullWidth
      onChange={handleChange}
      value={filterParams.description}
      id="outlined-basic"
      label="Açıklama"
      variant="outlined"
    />
  );
};

export default ErrorCodesTextfieldDescription;
