import * as Types from './types';
import { axiosInstance as axios } from '..';
import { FilterParams } from 'pages/integrations';
import { formatUsers } from '../utils/format-users';
import { AddUserFormParams } from 'pages/users-add';
import { EditUserFormParams } from 'pages/users-edit';
import { SolutionsFilterParams } from 'pages/solutions';
import { formatCompanies } from '../utils/format-companies';
import { AddErrorCodeFormParams } from 'pages/error-codes-add';
import { EditErrorCodeFormParams } from 'pages/error-codes-edit';
import { AddGroupCompanyFormParams } from 'pages/group-companies-add';
import { GroupCompaniesFilterParams } from 'pages/group-companies-main';
import { EditGroupCompanyFormParams } from 'pages/group-companies-edit';
import { AddNotificationRuleFormParams } from 'pages/notification-panel-add';
import { NotificationPanelFilterParams } from 'pages/notification-panel-main';
import { EditNotificationRuleFormParams } from 'pages/notification-panel-edit';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { ErrorLogsFilterParams } from 'containers/elements/dashboard/error-logs-table';
import { NetworkOffFilterParams } from 'containers/elements/dashboard/network-off-table';
import { CriticalResourceFilterParams } from 'containers/elements/dashboard/critical-consumption-table';
import { OngoingIntegrationErrorsFilterParams } from 'containers/elements/dashboard/unsuccess-integrations-table';

const request = {
  // Account

  logIn: async (params: { userName: string; password: string }): Promise<string> => {
    const response = await axios.post('Account/login', params);
    return response.data.token;
  },

  // Bank

  getBanksMap: async (): Promise<Types.BankMap[]> => {
    const response = await axios.get('Bank/list');
    return response.data.data;
  },

  // CompanyGroup

  getCompaniesMap: async (): Promise<Types.Company[]> => {
    const response = await axios.get('CompanyGroup/list');
    return formatCompanies(response.data.data);
  },

  getGroupCompaniesMapByFilter: async (
    params: GroupCompaniesFilterParams
  ): Promise<Types.ResponseCompaniesByFilter> => {
    const response = await axios.post('CompanyGroup/list', params);
    return response.data;
  },

  createCompanyGroup: async (params: AddGroupCompanyFormParams): Promise<Types.ResponseAddGroupCompany> => {
    const response = await axios.post('CompanyGroup/create', params);
    return response.data;
  },

  updateCompanyGroup: async (params: EditGroupCompanyFormParams): Promise<Types.ResponseUpdateGroupCompany> => {
    const response = await axios.post('CompanyGroup/update', params);
    return response.data;
  },

  deleteCompanyGroup: async (params: { id: number }): Promise<Types.ResponseUpdateGroupCompany> => {
    const response = await axios.post('CompanyGroup/delete', params);
    return response.data;
  },

  // CompanyIntegration

  getIntegrationsMapByFilter: async (params: FilterParams): Promise<Types.IntegrationsMapByFilter> => {
    const response = await axios.post('CompanyIntegration/list', params);
    return response.data.data;
  },

  getIntegrationById: async (params: { id: number }): Promise<Types.IntegrationByFilter> => {
    const response = await axios.post('CompanyIntegration/getbyid', params);
    return response.data.data;
  },

  assignToUser: async (params: {
    companyIntegrationId: number;
    userName: string;
  }): Promise<Types.IntegrationByFilter> => {
    const response = await axios.post('CompanyIntegration/assigneToUser', params);
    return response.data.data;
  },

  changeIntegrationStatus: async (params: {
    companyIntegrationId: number;
    activityType: number;
    autoEmailContentToCustomer: string;
  }) => await axios.post('CompanyIntegration/changeIntegrationStatus', params),

  changeStatus: async (params: {
    companyIntegrationId: number;
    statusType: number;
  }): Promise<Types.ResponseChangeActivity> => {
    const response = await axios.post('CompanyIntegration/changeStatus', params);
    return response.data;
  },

  // CompanyIntegrationComment

  getCommentsByErrorId: async (params: {
    pageNumber: number;
    pageSize: number;
    companyIntegrationId: number;
  }): Promise<Types.ErrorCommentsMap> => {
    const response = await axios.post('CompanyIntegrationComment/list', params);
    return response.data.data;
  },

  commentOut: async (params: {
    companyIntegrationId: number;
    description: string;
  }): Promise<Types.CommentOutResponse> => {
    const response = await axios.post('CompanyIntegrationComment/create', params);
    return response.data.data;
  },

  updateComment: async (params: {
    id: number;
    description: string;
    markAsSolution: boolean;
  }): Promise<Types.CommentOutResponse> => {
    const response = await axios.post('CompanyIntegrationComment/update', params);
    return response.data.data;
  },

  deleteComment: async (params: { id: number }): Promise<string> => {
    const response = await axios.post('CompanyIntegrationComment/delete', params);
    return response.data.message;
  },

  // Dashboard

  getDashboardInfo: async (): Promise<Types.ResponseDashboardInfo> => {
    const response = await axios.get('Dashboard/getdashboardinfo');
    return response.data;
  },

  getCriticalResource: async (params: CriticalResourceFilterParams): Promise<Types.ResponseCriticalResource> => {
    const response = await axios.post('Dashboard/getcriticalresource', params);
    return response.data;
  },

  getOngoingIntegrationErrors: async (
    params: OngoingIntegrationErrorsFilterParams
  ): Promise<Types.ResponseOngoingIntegration> => {
    const response = await axios.post('Dashboard/getongoingintegrationerrors', params);
    return response.data;
  },

  getNonaccessibleCompanyGroup: async (
    params: NetworkOffFilterParams
  ): Promise<Types.ResponseNonaccessibleCompanyGroup> => {
    const response = await axios.post('Dashboard/getnonaccessiblecompanygroup', params);
    return response.data;
  },

  getErrorLogs: async (params: ErrorLogsFilterParams): Promise<Types.ResponseErrorLogs> => {
    const response = await axios.post('Dashboard/geterrorlogs', params);
    return response.data;
  },

  // ErrorCode

  getErrorCodeMap: async (params: PaginationFilterParams): Promise<Types.ErrorCodeMap> => {
    const response = await axios.post('ErrorCode/list', params);
    return response.data.data;
  },

  getErrorCodes: async (): Promise<Types.ErrorCode[]> => {
    const response = await axios.get('ErrorCode/geterrorcodes');
    return response.data.data;
  },

  createErrorCode: async (params: AddErrorCodeFormParams): Promise<Types.ResponseAddErrorCode> => {
    const response = await axios.post('ErrorCode/create', params);
    return response.data;
  },

  updateErrorCode: async (params: EditErrorCodeFormParams): Promise<Types.ResponseUpdateErrorCode> => {
    const response = await axios.post('ErrorCode/update', params);
    return response.data.data;
  },

  deleteErrorCode: async (params: { code: string }): Promise<Types.ResponseDeleteErrorCode> => {
    const response = await axios.post('ErrorCode/delete', params);
    return response.data;
  },

  // ErrorSolution

  getErrorSolutionMapByFilter: async (params: SolutionsFilterParams): Promise<Types.ErrorSolutionMap> => {
    const response = await axios.post('ErrorSolution/list', params);
    return response.data.data;
  },

  // NotificationRule

  getNotificationRulesMapByFilter: async (
    params: NotificationPanelFilterParams
  ): Promise<Types.NotificationRulesMapByFilter> => {
    const response = await axios.post('NotificationRule/list', params);
    return response.data.data;
  },

  createNotificationRule: async (params: AddNotificationRuleFormParams): Promise<Types.ResponseAddNotificationRule> => {
    const response = await axios.post('NotificationRule/create', params);
    return response.data;
  },

  updateNotificationRule: async (
    params: EditNotificationRuleFormParams
  ): Promise<Types.ResponseUpdateNotificationRule> => {
    const response = await axios.post('NotificationRule/update', params);
    return response.data;
  },

  deleteNotificationRule: async (params: { id: number }): Promise<Types.ResponseDeleteNotificationRule> => {
    const response = await axios.post('NotificationRule/delete', params);
    return response.data;
  },

  // User

  getUsersMap: async (params: PaginationFilterParams): Promise<Types.UsersMap> => {
    const response = await axios.post('User/list', params);
    return formatUsers(response.data.data);
  },

  createUser: async (params: AddUserFormParams): Promise<Types.ResponseAddUser> => {
    const response = await axios.post('User/create', params);
    return response.data;
  },

  updateUser: async (params: EditUserFormParams): Promise<Types.ResponseUpdateUser> => {
    const response = await axios.post('User/update', params);
    return response.data;
  },

  deleteUser: async (params: { userName: string }): Promise<Types.ResponseDeleteUser> => {
    const response = await axios.post('User/delete', params);
    return response.data;
  },

  getUserByUserName: async (params: { userName: string }): Promise<Types.UserWithGroupCompanies> => {
    const response = await axios.post('User/getuser', params);
    return response.data.data;
  },
};

export default request;
