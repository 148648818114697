import TextField from '@mui/material/TextField';
import { InputAdornment, Tooltip } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EditMyProfileFormParams } from 'pages/my-profile-edit';

type EditMyProfileTextfieldTelegramUserIdProps = {
  onChange: Dispatch<SetStateAction<EditMyProfileFormParams>>;
  formParams: EditMyProfileFormParams;
  error: boolean;
};

const EditMyProfileTextfieldTelegramUserId = ({
  onChange,
  formParams,
  error,
}: EditMyProfileTextfieldTelegramUserIdProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const telegramUserId = event.target.value;
    onChange((prevState: EditMyProfileFormParams) => ({ ...prevState, telegramUserId }));
  };

  return (
    <TextField
      size="small"
      fullWidth
      sx={
        error && formParams.useTelegram && !formParams.telegramUserId
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.telegramUserId}
      id="outlined-basic-telegram-user-id"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip
              placement="top"
              title="Kullanıcı Id Telegram üzerinden alınacaktır. https://t.me/userinfobot adresinde bulunan bota /start şeklinde mesaj gönderilerek alınacaktır!">
              <InfoOutlinedIcon />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default EditMyProfileTextfieldTelegramUserId;
