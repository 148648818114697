import { useAppDispatch } from 'store/hooks';
import { sortHandler } from 'utils/render-sort';
import { Dispatch, SetStateAction } from 'react';
import { renderFormattedTime } from 'utils/format-time';
import { formattedLongText } from 'utils/format-long-text';
import { FilterParams, SortType } from 'pages/integrations';
import { IntegrationByFilter } from 'library/request/types';
import { renderFormattedPriority } from 'utils/format-priority';
import { CustomPagination } from 'containers/elements/@commons';
import { setIsDrawerOpen } from 'store/features/drawer/drawer.slice';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { SortDownIcon, SortUpIcon, StyledTableCell, StyledTableRow } from './styles';
import { ORDER_TYPE_ENUM, getActivityTranslated, getIntegrationStatusIcon, getStatusTranslated } from 'enums';
import { setSelectedCompanyIntegrationId } from 'store/features/drawer-integrations/drawer-integrations.slice';
import { Table, Paper, Tooltip, TableRow, TableBody, TableHead, TableFooter, TableContainer } from '@mui/material';

export type TableHeads = {
  key: string;
  style?: any;
  label: string;
  sortType: number | null;
  isSort: boolean | string;
};

type IntegrationTableProps = {
  totalIntegration: number;
  filterParams: FilterParams;
  integrationList: IntegrationByFilter[];
  setFilterParams: Dispatch<SetStateAction<FilterParams>>;
  onApplySortFilter: ({ selector, orderByType }: SortType) => Promise<void>;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
};

const IntegrationTable = ({
  integrationList: rows,
  totalIntegration,
  filterParams,
  setFilterParams,
  onApplySortFilter,
  onApplyPaginationFilter,
}: IntegrationTableProps) => {
  const dispatch = useAppDispatch();

  const tableHeads: TableHeads[] = [
    {
      label: 'Tarih',
      key: 'errorDate',
      isSort: filterParams.selector === 'errorDate',
      sortType: filterParams.selector === 'errorDate' ? filterParams.orderByType : null,
    },
    {
      label: 'Hata Kodu',
      key: 'errorCode',
      isSort: filterParams.selector === 'errorCode',
      sortType: filterParams.selector === 'errorCode' ? filterParams.orderByType : null,
    },
    {
      label: 'Şirket Grubu',
      key: 'companyGroupName',
      isSort: filterParams.selector === 'companyGroupName',
      sortType: filterParams.selector === 'companyGroupName' ? filterParams.orderByType : null,
    },
    {
      label: 'Şirket Adı',
      key: 'companyName',
      isSort: filterParams.selector === 'companyName',
      sortType: filterParams.selector === 'companyName' ? filterParams.orderByType : null,
    },
    {
      label: 'Banka Adı',
      key: 'bankName',
      isSort: filterParams.selector === 'bankName',
      sortType: filterParams.selector === 'bankName' ? filterParams.orderByType : null,
    },
    {
      label: 'UID',
      key: 'uid',
      isSort: filterParams.selector === 'uid',
      sortType: filterParams.selector === 'uid' ? filterParams.orderByType : null,
    },
    {
      label: 'Ticket ID',
      key: 'ticketID',
      isSort: filterParams.selector === 'ticketID',
      sortType: filterParams.selector === 'ticketID' ? filterParams.orderByType : null,
    },
    {
      label: 'Öncelik',
      key: 'alertPriority',
      isSort: filterParams.selector === 'alertPriority',
      sortType: filterParams.selector === 'alertPriority' ? filterParams.orderByType : null,
    },
    {
      label: 'Aktivite',
      key: 'activityType',
      isSort: filterParams.selector === 'activityType',
      sortType: filterParams.selector === 'activityType' ? filterParams.orderByType : null,
    },
    {
      label: 'Durum',
      key: 'statusType',
      isSort: filterParams.selector === 'statusType',
      sortType: filterParams.selector === 'statusType' ? filterParams.orderByType : null,
    },
    {
      label: 'Ent.',
      key: 'integrationStatus',
      isSort: filterParams.selector === 'integrationStatus',
      sortType: filterParams.selector === 'integrationStatus' ? filterParams.orderByType : null,
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {tableHeads.map((tableHead, index) => (
              <StyledTableCell
                key={index.toString()}
                sx={{ cursor: tableHead.isSort !== 'none' ? 'pointer' : 'default' }}
                onClick={() => sortHandler({ tableHead, setFilterParams, onApplySortFilter })}
                align="center">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                  }}>
                  <span style={{ marginRight: '5px' }}>{tableHead.label}</span>
                  {tableHead.isSort && tableHead.isSort !== 'none' && tableHead.sortType === ORDER_TYPE_ENUM.ASC && (
                    <SortDownIcon />
                  )}
                  {tableHead.isSort && tableHead.isSort !== 'none' && tableHead.sortType === ORDER_TYPE_ENUM.DESC && (
                    <SortUpIcon />
                  )}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {!totalIntegration ? (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={tableHeads.length} sx={{ cursor: 'default' }}>
                Entegrasyon bulunmamaktadır.
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow
                  onClick={() => {
                    dispatch(setSelectedCompanyIntegrationId(row.id));
                    dispatch(setIsDrawerOpen(true));
                  }}
                  key={index.toString()}>
                  <StyledTableCell width="125px" align="center">
                    {renderFormattedTime(row.errorDate)}
                  </StyledTableCell>
                  <Tooltip arrow followCursor placement="top" title={row.errorCodeDescription}>
                    <StyledTableCell width="80px" align="center">
                      {row.errorCode || '-'}
                    </StyledTableCell>
                  </Tooltip>
                  <StyledTableCell width="130px" align="center">
                    {row.companyGroupName}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.companyName}</StyledTableCell>
                  <Tooltip arrow followCursor placement="top" title={`Banka Kodu: ${row.bankCode}`}>
                    <StyledTableCell align="center">{row.bankName}</StyledTableCell>
                  </Tooltip>
                  <StyledTableCell align="center">{formattedLongText(row.uid, 17)}</StyledTableCell>
                  <StyledTableCell width="80px" align="center">
                    {row.ticketID || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="center">{renderFormattedPriority(row.alertPriority)}</StyledTableCell>
                  <StyledTableCell align="center">{getActivityTranslated(row.activityType)}</StyledTableCell>
                  <StyledTableCell align="center">{getStatusTranslated(row.statusType)}</StyledTableCell>
                  <StyledTableCell align="center">{getIntegrationStatusIcon(row.integrationStatus)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow sx={{ cursor: 'default' }}>
                <CustomPagination
                  colSpan={10}
                  totalCount={totalIntegration}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  onApplyPaginationFilter={onApplyPaginationFilter}
                />
              </StyledTableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default IntegrationTable;
