import * as Icons from 'assets/icons';
import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { bgSidebar } from 'assets/style-helpers/colors';

export const DashboardIcon = styled(Icons.DashboardSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const IntegrationsIcon = styled(Icons.IntegrationsSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const IntegrationSummaryIcon = styled(Icons.IntegrationSummarySvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const SolutionsIcon = styled(Icons.SolutionsSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const ErrorCodesIcon = styled(Icons.ErrorCodesSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const GroupCompaniesIcon = styled(Icons.GroupCompaniesSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const NotificationIcon = styled(Icons.NotificationSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const UsersIcon = styled(Icons.UsersSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const SettingsIcon = styled(Icons.SettingsSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const GeneralContainer = styled.div`
  display: block;
`;

export const CompanyContainer = styled.div`
  margin-bottom: 10px;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px 0px 20px;
  border-radius: 12px;
  margin-right: 16px;
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  height: fit-content;
  box-sizing: border-box;
  background-color: ${bgSidebar};

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  position: relative;

  @media (max-width: 500px) {
    &-item {
      display: flex;
      flex-direction: row;
      margin: 0px 0px 20px 0px;
      cursor: pointer;
    }
  }

  a.active {
    color: #000000e6 !important;
    svg {
      color: #000000e6;
    }
  }
`;

export const SidebarItemPolyContainer = styled(NavLink)`
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;
  text-decoration: none;
  cursor: pointer;
  color: #00000073;

  &:hover {
    color: #000000e6 !important;
    svg {
      color: #000000e6;
    }
  }
`;

export const SidebarTextContainer = styled.div`
  > div {
    margin-left: 10px;
    align-items: flex-start;
    font-size: 16px !important;
    letter-spacing: 0.15px;
    line-height: 1.5;
  }
`;

export const SidebarItemMonoContainerLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;
  text-decoration: none;
  cursor: pointer;
  color: #00000073;

  &:hover {
    color: #000000e6 !important;
    svg {
      color: #000000e6;
    }
  }
`;

export const SidebarItemMonoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;
  text-decoration: none;
  cursor: pointer;
  color: #00000073;

  &:hover {
    color: #000000e6 !important;
    svg {
      color: #000000e6;
    }
  }
`;

export const SignoutIcon = styled(Icons.SignoutSvg)`
  font-size: 24px !important;
  color: #00000073;
`;

export const ExternalTextContainer = styled.div`
  > div {
    margin-left: 10px;
    align-items: flex-start;
    font-size: 16px !important;
    letter-spacing: 0.15px;
    line-height: 1.5;
    color: inherit !important;

    &:hover {
      color: #000000e6 !important;
    }
  }
`;
