import jwtDecode from 'jwt-decode';

export type DecodedType = {
  aud: string;
  exp: number;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage': string;
  'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/username': string;
  'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/vkn': string;
  iss: string;
};

class Token {
  getDecoded(token: string): DecodedType {
    return jwtDecode(token);
  }

  isExpired(token: string) {
    const { exp } = this.getDecoded(token) as any;
    const expirationDate = exp * 1000;
    const now = new Date().getTime();

    return now > expirationDate;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Token();
