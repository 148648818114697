import { ReactNode } from 'react';
import { GeneralContainer, PageContainer } from './styles';

type PageProps = {
  children: ReactNode;
};

const Page = ({ children }: PageProps) => {
  return (
    <GeneralContainer>
      <PageContainer>{children}</PageContainer>
    </GeneralContainer>
  );
};

export default Page;
