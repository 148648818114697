import {
  CompaniesIcon,
  GroupCompaniesIcon,
  FailedIntegrationsIcon,
  SuccessIntegrationsIcon,
  additionalInfoWidgetStyle,
} from './styles';
import { Grid } from '@mui/material';
import request from 'library/request';
import {
  InfoCard,
  CustomPieChart,
  ErrorLogsTable,
  NetworkOffTable,
  CriticalConsumptionTable,
  UnsuccessIntegrationsTable,
} from 'containers/elements/dashboard';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Loader, Widget } from 'components';
import { Breadcrumb } from 'containers/elements/@commons';
import { SERVER_TYPE_ENUM, STATUS_TYPE_ENUM } from 'enums';

const { getDashboardInfo } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Genel Bakış',
    path: '/dashboard',
  },
];

type InfoData = {
  totalCompanyGroup: number;
  totalCloudCompanyGroup: number;
  totalOnpremCompanyGroup: number;
  totalCompanies: number;
  totalSuccessfulIntegration: number;
  successfulIntegrationRate: number;
  totalFailedIntegration: number;
  failedIntegrationRate: number;
  veryUrgent: number | undefined;
  urgent: number | undefined;
  nonUrgent: number | undefined;
  totalCompaniesAddedThisWeek: number;
  totalCompaniesAddedToday: number;
};

const Dashboard = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [infoData, setInfoData] = useState<InfoData>({
    totalCompanyGroup: 0,
    totalCloudCompanyGroup: 0,
    totalOnpremCompanyGroup: 0,
    totalCompanies: 0,
    totalSuccessfulIntegration: 0,
    successfulIntegrationRate: 0,
    totalFailedIntegration: 0,
    failedIntegrationRate: 0,
    veryUrgent: 0,
    urgent: 0,
    nonUrgent: 0,
    totalCompaniesAddedThisWeek: 0,
    totalCompaniesAddedToday: 0,
  });

  const onClickPrimaryExplanationHandlerForGroupCompaniesCard = () =>
    navigate('/group-companies', {
      state: {
        serverType: SERVER_TYPE_ENUM.CLOUD,
        statusType: STATUS_TYPE_ENUM.ACTIVE,
      },
    });

  const onClickSecondaryExplanationHandlerForGroupCompaniesCard = () =>
    navigate('/group-companies', {
      state: {
        serverType: SERVER_TYPE_ENUM.ON_PREM,
        statusType: STATUS_TYPE_ENUM.ACTIVE,
      },
    });

  const onClickPrimaryExplanationHandlerForSuccessIntegrationsCard = () =>
    navigate('/integrations', {
      state: { integrationStatus: 1 },
    });

  const onClickSecondaryExplanationHandlerForFailedIntegrationsCard = () => navigate('/integrations');

  useEffect(() => {
    setLoading(true);

    getDashboardInfo().then(({ data }) => {
      if (!data) return;

      const successfulIntegrationRate = Number(
        ((data.totalSuccessfulIntegration / data.totalIntegration) * 100).toFixed(0)
      );
      const failedIntegrationRate = Number(((data.totalFailedIntegration / data.totalIntegration) * 100).toFixed(0));
      const nonUrgent = data.failedIntegrations.find((el) => el.alertPriority === 1)?.integrationCount as number;
      const urgent = data.failedIntegrations.find((el) => el.alertPriority === 2)?.integrationCount as number;
      const veryUrgent = data.failedIntegrations.find((el) => el.alertPriority === 3)?.integrationCount as number;

      setInfoData((prevState) => ({
        ...prevState,
        totalCompanyGroup: data.totalCompanyGroup,
        totalCloudCompanyGroup: data.totalCloudCompanyGroup,
        totalOnpremCompanyGroup: data.totalOnpremCompanyGroup,
        totalCompanies: data.totalCompanies,
        totalSuccessfulIntegration: data.totalSuccessfulIntegration,
        successfulIntegrationRate,
        totalFailedIntegration: data.totalFailedIntegration,
        failedIntegrationRate,
        veryUrgent,
        urgent,
        nonUrgent,
        totalCompaniesAddedThisWeek: data.totalCompaniesAddedThisWeek,
        totalCompaniesAddedToday: data.totalCompaniesAddedToday,
      }));
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Widget $additionalStyle={additionalInfoWidgetStyle}>
        <Breadcrumb title="Genel Bakış" breadcrumbs={breadcrumbs} />
      </Widget>

      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <InfoCard
                Icon={<GroupCompaniesIcon />}
                iconBackgroundColor={'#F6F8A8'}
                mainTitle={'Şirket Grubu'}
                mainTitleAmount={infoData.totalCompanyGroup}
                primaryExplanationAmount={infoData.totalCloudCompanyGroup}
                primaryExplanation={'cloud'}
                onClickPrimaryExplanationHandler={onClickPrimaryExplanationHandlerForGroupCompaniesCard}
                onClickSecondaryExplanationHandler={onClickSecondaryExplanationHandlerForGroupCompaniesCard}
                secondaryExplanationAmount={infoData.totalOnpremCompanyGroup}
                secondaryExplanation={'on-prem'}
                secondaryExplanationAmountColor={'#4caf50'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InfoCard
                Icon={<CompaniesIcon />}
                iconBackgroundColor={'#3E97EF'}
                mainTitle={'Tüm Şirketler'}
                mainTitleAmount={infoData.totalCompanies}
                primaryExplanationAmount={infoData.totalCompaniesAddedThisWeek}
                primaryExplanation={'bu hafta'}
                primaryExplanationAmountColor={infoData.totalCompaniesAddedThisWeek ? '#4caf50' : '#de5462'}
                secondaryExplanationAmount={infoData.totalCompaniesAddedToday}
                secondaryExplanation={'bugün'}
                secondaryExplanationAmountColor={infoData.totalCompaniesAddedToday ? '#4caf50' : '#de5462'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InfoCard
                Icon={<SuccessIntegrationsIcon />}
                iconBackgroundColor={'#5EB562'}
                mainTitle={'Başarılı Entegrasyon'}
                mainTitleAmount={infoData.totalSuccessfulIntegration}
                rate={true}
                onClickPrimaryExplanationHandler={onClickPrimaryExplanationHandlerForSuccessIntegrationsCard}
                primaryExplanationAmount={infoData.successfulIntegrationRate}
                primaryExplanation={'tüm entegrasyonlara oranı'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InfoCard
                Icon={<FailedIntegrationsIcon />}
                iconBackgroundColor={'#E73772'}
                mainTitle={'Başarısız Entegrasyon'}
                mainTitleAmount={infoData.totalFailedIntegration}
                rate={true}
                onClickSecondaryExplanationHandler={onClickSecondaryExplanationHandlerForFailedIntegrationsCard}
                secondaryExplanationAmount={infoData.failedIntegrationRate}
                secondaryExplanation={'tüm entegrasyonlara oranı'}
              />
            </Grid>
          </Grid>
        )}
      </Widget>

      <Widget>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <NetworkOffTable />
          </Grid>
          <Grid item xs={12} md={3}>
            {loading ? (
              <Card>
                <Loader />
              </Card>
            ) : (
              <CustomPieChart
                urgent={infoData.urgent || 0}
                nonUrgent={infoData.nonUrgent || 0}
                veryUrgent={infoData.veryUrgent || 0}
              />
            )}
          </Grid>
        </Grid>
      </Widget>

      <Widget>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ErrorLogsTable />
          </Grid>
        </Grid>
      </Widget>

      <Widget>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <UnsuccessIntegrationsTable />
          </Grid>
        </Grid>
      </Widget>

      <Widget>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CriticalConsumptionTable />
          </Grid>
        </Grid>
      </Widget>
    </>
  );
};

export default Dashboard;
