import {
  SettingsIcon,
  IconContainer,
  UserContainer,
  HeaderContainer,
  NotificationIcon,
  HeaderDefaultContainer,
  HeaderDefaultLeftContainer,
  HeaderDefaultRightContainer,
} from './styles';
import { Loader, Logo } from 'components';
import Authentication from 'utils/authentication';
import { stringAvatar } from 'utils/string-avatar';
import { useEffect, useState, useCallback } from 'react';
import Token, { DecodedType } from 'library/helpers/token';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import usersActions from 'store/features/users/users.actions';
import { Menu, Tooltip, MenuItem, IconButton, Avatar } from '@mui/material';
import { selectIsLoading, selectUsersMap } from 'store/features/users/users.selector';

const avatarStyleObj = {
  width: '28px',
  height: '28px',
  overflow: 'none',
  fontSize: '11px',
  lineHeight: '2',
  marginRight: '5px',
  textAlign: 'center',
};

const Header = () => {
  const dispatch = useAppDispatch();
  const usersMap = useAppSelector(selectUsersMap);
  const loading = useAppSelector(selectIsLoading);

  const token = Authentication.getToken() as string;
  const decodedUser = Token.getDecoded(token) as DecodedType;
  const [user] = usersMap.filter(
    (user) => user.value === decodedUser['http://schemas.xmlsoap.org/ws/2009/09/identity/claims/username']
  );

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const goToMyProfilePageHandler = useCallback(() => navigate('my-profile'), []);

  useEffect(() => {
    if (!usersMap.length) {
      dispatch(usersActions.getUsers());
    }
  }, [usersMap]);

  useEffect(() => {
    if (user) {
      Authentication.setLoggedUser({
        ...user,
        role: decodedUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
      });
      dispatch(usersActions.logUser(user.value));
    }
  }, [user]);

  return (
    <HeaderContainer>
      <HeaderDefaultContainer>
        <HeaderDefaultLeftContainer>
          <Link to="/dashboard">
            <Logo />
          </Link>
        </HeaderDefaultLeftContainer>
        <HeaderDefaultRightContainer>
          <IconContainer>
            <NotificationIcon />
          </IconContainer>
          <IconContainer>
            {/* <NavLink to="settings"> */}
            <SettingsIcon />
            {/* </NavLink> */}
          </IconContainer>
          <>
            <Tooltip title="Profilim">
              <div
                onClick={handleClick}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                <IconButton
                  size="small"
                  sx={{ ml: 1, mb: 0.5 }}
                  aria-controls={open ? 'profile-picker' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}>
                  <Avatar component="span" {...stringAvatar(user?.label, avatarStyleObj)} />
                </IconButton>
                <UserContainer>{loading ? <Loader /> : user?.label}</UserContainer>
              </div>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="profile-picker"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <MenuItem onClick={goToMyProfilePageHandler}>Profilim</MenuItem>
              <MenuItem onClick={() => Authentication.logOut()}>Çıkış Yap</MenuItem>
            </Menu>
          </>
        </HeaderDefaultRightContainer>
      </HeaderDefaultContainer>
    </HeaderContainer>
  );
};

export default Header;
