export const renderFormattedConsumptionRate = (
  capacity: string | undefined,
  usedSpace: string | undefined,
  fraction: boolean = true
) => {
  const formattedCapacity = capacity ? Number(capacity.replaceAll(',', '.')) : 100;
  const formattedUsedSpace = usedSpace ? Number(usedSpace.replaceAll(',', '.')) : 0;

  const resultWithFraction = Number(((formattedUsedSpace / formattedCapacity) * 100).toFixed(2));
  const fractionlessResult = (formattedUsedSpace / formattedCapacity) * 100;

  return fraction ? resultWithFraction : fractionlessResult;
};
