import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import {
  GroupCompaniesTable,
  GroupCompaniesFilters,
  GroupCompaniesDeletePopUp,
} from 'containers/elements/group-companies';
import { useAppSelector } from 'store/hooks';
import { SortType } from 'pages/integrations';
import { Company } from 'library/request/types';
import { Card, Loader, Widget } from 'components';
import Authentication from 'utils/authentication';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { AddGroupCompanyIcon, RightItemContainer, TextSpan } from './styles';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { selectLoadingUpdate } from 'store/features/update-group-company/update-group-company.selector';

const { getGroupCompaniesMapByFilter } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Grup Şirketler',
    path: '/group-companies',
  },
];

export type GroupCompaniesFilterParams = {
  title: string;
  pageSize: number;
  selector: string;
  pageNumber: number;
  statusType: number | null;
  serverType: number | null;
  orderByType: number | null;
  defaultSupportPerson: string;
};

const GroupCompaniesMain = () => {
  const loggedUser = Authentication.getLoggedUser();

  const loadingUpdate = useAppSelector(selectLoadingUpdate);

  const location = useLocation();
  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);
  const goToAddGroupCompanyHandler = useCallback(() => navigate('add'), []);

  const [loading, setLoading] = useState(false);
  const [totalGroupCompany, setTotalGroupCompany] = useState(0);
  const [groupCompanyList, setGroupCompanyList] = useState<Company[]>([]);
  const [filterParams, setFilterParams] = useState<GroupCompaniesFilterParams>({
    title: '',
    pageSize: 20,
    selector: '',
    pageNumber: 1,
    statusType: null,
    serverType: null,
    orderByType: null,
    defaultSupportPerson: '',
  });

  const onClear = () => {
    setFilterParams({
      title: '',
      pageSize: 20,
      selector: '',
      pageNumber: 1,
      statusType: null,
      serverType: null,
      orderByType: null,
      defaultSupportPerson: '',
    });
  };

  const getValues = async (newParams: any = null) => {
    setLoading(true);

    const { data } = newParams
      ? await getGroupCompaniesMapByFilter({ ...filterParams, ...newParams })
      : await getGroupCompaniesMapByFilter(filterParams);
    if (!data) return;

    setTotalGroupCompany(data.totalCount);
    setGroupCompanyList(data.result);
    setLoading(false);
  };

  const onApplySortFilter = ({ selector, orderByType }: SortType) => getValues({ selector, orderByType });

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) =>
    getValues({ pageSize, pageNumber });

  const resetLocationState = () => {
    return new Promise((resolve) => {
      navigate(location.pathname, { replace: true });
      resolve(true);
    });
  };

  useLayoutEffect(() => {
    if (location.state) {
      const locationState = { ...location.state };
      const { serverType = null, statusType = null, title = '' } = locationState;

      resetLocationState().then(() => {
        setFilterParams((prevState) => ({ ...prevState, serverType, statusType, title }));
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state) {
      setFilterParams((prevState) => ({
        ...prevState,
        serverType: location.state.serverType,
        statusType: location.state.statusType,
        title: location.state.title,
      }));
      getValues({
        pageNumber: 1,
        serverType: location.state?.serverType !== undefined ? location.state?.serverType : filterParams.serverType,
        statusType: location.state?.statusType !== undefined ? location.state?.statusType : filterParams.statusType,
        title: location.state?.title !== undefined ? location.state?.title : filterParams.title,
      });
      return;
    }
    getValues();
  }, [loadingUpdate]);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'group-companies');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Grup Şirketler" breadcrumbs={breadcrumbs} />
      </Widget>
      <GroupCompaniesFilters
        onClear={onClear}
        filterParams={filterParams}
        onApplyFilter={getValues}
        setFilterParams={setFilterParams}
      />
      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Card
            leftItem={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? <div /> : null}
            rightItem={
              loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? (
                <RightItemContainer onClick={goToAddGroupCompanyHandler}>
                  <AddGroupCompanyIcon />
                  <TextSpan>Grup Şirket Ekle</TextSpan>
                </RightItemContainer>
              ) : null
            }>
            <GroupCompaniesTable
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              groupCompanyList={groupCompanyList}
              totalGroupCompany={totalGroupCompany}
              onApplySortFilter={onApplySortFilter}
              onApplyPaginationFilter={onApplyPaginationFilter}
            />
          </Card>
        )}
      </Widget>
      {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && <GroupCompaniesDeletePopUp />}
    </>
  );
};

export default GroupCompaniesMain;
