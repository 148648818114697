import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Widget, Card, Text } from 'components';
import {
  EditGroupCompanyTextfieldTitle,
  EditGroupCompanySelectServerType,
  EditGroupCompanySelectStatusType,
  EditGroupCompanyTextfieldPassword,
  EditGroupCompanyTextfieldUsername,
  EditGroupCompanySelectSupportPerson,
  EditGroupCompanyCheckboxSendAutoEmail,
  EditGroupCompanyTextfieldAutoNotificationEmailAddresses,
} from 'containers/elements/group-companies-edit';
import Authentication from 'utils/authentication';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import FormButtons from 'containers/elements/@commons/form-buttons';
import { selectGroupCompany } from 'store/features/update-group-company/update-group-company.selector';

const { updateCompanyGroup } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Grup Şirketler',
    path: '/group-companies',
  },
  {
    name: 'Grup Şirket Düzenle',
    path: '/group-companies/edit',
  },
];

export type EditGroupCompanyFormParams = {
  id: number;
  title: string;
  userName: string;
  password: string;
  serverType: number;
  statusType: number;
  sendAutoEmail: boolean;
  defaultSupportPerson: string;
  autoNotificationEmailAddresses: string[];
};

const GroupCompaniesEdit = () => {
  const loggedUser = Authentication.getLoggedUser();

  const selectedGroupCompany = useAppSelector(selectGroupCompany);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<EditGroupCompanyFormParams>({
    title: selectedGroupCompany?.title || '',
    id: selectedGroupCompany?.id || 0,
    password: selectedGroupCompany?.password || '',
    userName: selectedGroupCompany?.userName || '',
    serverType: selectedGroupCompany?.serverType || 1,
    statusType: selectedGroupCompany?.statusType || 1,
    sendAutoEmail: selectedGroupCompany?.sendAutoEmail || false,
    defaultSupportPerson: selectedGroupCompany?.defaultSupportPerson || '',
    autoNotificationEmailAddresses: selectedGroupCompany?.autoNotificationEmailAddresses
      ? selectedGroupCompany.autoNotificationEmailAddresses
      : [],
  });

  const onClear = () => {
    setFormParams({
      title: selectedGroupCompany?.title || '',
      id: selectedGroupCompany?.id || 0,
      password: selectedGroupCompany?.password || '',
      userName: selectedGroupCompany?.userName || '',
      serverType: selectedGroupCompany?.serverType || 1,
      statusType: selectedGroupCompany?.statusType || 1,
      sendAutoEmail: selectedGroupCompany?.sendAutoEmail || false,
      defaultSupportPerson: selectedGroupCompany?.defaultSupportPerson || '',
      autoNotificationEmailAddresses: selectedGroupCompany?.autoNotificationEmailAddresses
        ? selectedGroupCompany.autoNotificationEmailAddresses
        : [],
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.title === '' || formParams.userName === '') {
      setErrorMessage(
        formParams.title === ''
          ? 'Şirket Grubu boş geçilemez.'
          : formParams.userName === ''
          ? 'API Kullanıcı Adı boş geçilemez.'
          : ''
      );
      return setError(true);
    }

    if (formParams.sendAutoEmail && !formParams.autoNotificationEmailAddresses.length) {
      setErrorMessage('Otomatik gönderim için en az 1 adet e-posta girilmelidir.');
      return setError(true);
    }

    setLoading(true);

    updateCompanyGroup(formParams)
      .then((res) => {
        setLoading(false);
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN || !selectedGroupCompany) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  useEffect(() => {
    if (!formParams.sendAutoEmail) {
      setFormParams((prevState) => ({ ...prevState, autoNotificationEmailAddresses: [] }));
    }
  }, [formParams.sendAutoEmail]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Grup Şirket Düzenle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Şirket Grubu</span>
                  <StyledPaper>
                    <EditGroupCompanyTextfieldTitle onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>API Kullanıcı Adı</span>
                  <StyledPaper>
                    <EditGroupCompanyTextfieldUsername onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Parola</span>
                  <StyledPaper>
                    <EditGroupCompanyTextfieldPassword onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Destek Personeli</span>
                  <StyledPaper>
                    <EditGroupCompanySelectSupportPerson onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Sunucu</span>
                  <StyledPaper>
                    <EditGroupCompanySelectServerType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Durum</span>
                  <StyledPaper>
                    <EditGroupCompanySelectStatusType onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                {formParams.sendAutoEmail && (
                  <Grid item xs={12} md={4}>
                    <span>Mail Adresleri</span>
                    <StyledPaper>
                      <EditGroupCompanyTextfieldAutoNotificationEmailAddresses
                        onChange={setFormParams}
                        formParams={formParams}
                        error={error}
                      />
                    </StyledPaper>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <EditGroupCompanyCheckboxSendAutoEmail onChange={setFormParams} formParams={formParams} />
              </Grid>
              <FormButtons
                loading={loading}
                actionName={'Kaydet'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default GroupCompaniesEdit;
