import { styled } from 'styled-components';
import { DeleteSvg, EditSvg } from 'assets/icons';
import { ListItemText, Checkbox } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export const StyledCheckbox = styledMui(Checkbox)({
  '& .MuiSvgIcon-root': {
    '@media(max-width: 500px)': {
      fontSize: '1rem',
    },
  },
});

export const StyledListItemText = styledMui(ListItemText)({
  '& .MuiListItemText-primary': {
    '@media(max-width: 500px)': {
      fontSize: '12px',
    },
  },
  '& .MuiListItemText-secondary': {
    '@media(max-width: 500px)': {
      fontSize: '10px',
    },
  },
});

export const DeleteIcon = styled(DeleteSvg)`
  color: red;

  @media (max-width: 500px) {
    width: 16px;
    height: 16px;
  }
`;

export const EditIcon = styled(EditSvg)`
  @media (max-width: 500px) {
    width: 16px;
    height: 16px;
  }
`;

export const DeleteIconContainer = styled.span`
  width: 42px;
  height: 42px;
  padding: 9px;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 30px;
    height: 30px;
  }
`;

export const EditIconContainer = styled(DeleteIconContainer)``;
