import { BreadCrumbContainer, BreadCrumbListContainer, TitleContainer, WrapperContainer } from './styles';

type BreadcrumbProps = {
  title: string;
  breadcrumbs: { name: string; path: string }[];
};

const Breadcrumb = ({ title, breadcrumbs }: BreadcrumbProps) => {
  return (
    <WrapperContainer>
      <TitleContainer>{title}</TitleContainer>
      <BreadCrumbListContainer>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((item, index) => {
            const isLastItem = index === breadcrumbs.length - 1;
            return (
              <BreadCrumbContainer to={item.path} key={index.toString()}>
                {item.name} {!isLastItem && '/'}
              </BreadCrumbContainer>
            );
          })}
      </BreadCrumbListContainer>
    </WrapperContainer>
  );
};

export default Breadcrumb;
