import { Route, Routes } from 'react-router-dom';
import { GroupCompaniesAdd, GroupCompaniesEdit, GroupCompaniesMain } from '..';

const GroupCompanies = () => {
  return (
    <Routes>
      <Route index element={<GroupCompaniesMain />} />
      <Route path="add" element={<GroupCompaniesAdd />} />
      <Route path="edit" element={<GroupCompaniesEdit />} />
    </Routes>
  );
};

export default GroupCompanies;
