import request from 'library/request';
import Drawer from '@mui/material/Drawer';
import { Card, Loader, Widget } from 'components';
import Authentication from 'utils/authentication';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { IntegrationByFilter } from 'library/request/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsDrawerOpen } from 'store/features/drawer/drawer.slice';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { selectIsDrawerOpen } from 'store/features/drawer/drawer.selector';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { setRows } from 'store/features/drawer-integrations/drawer-integrations.slice';
import { Filters, IntegrationTable, DrawerContent } from 'containers/elements/integrations';
import { selectSelectedTaxNumbers } from 'store/features/select-companies/select-companies.selector';
import { selectIsChangedActivity } from 'store/features/drawer-integrations/drawer-integrations.selector';

const { getIntegrationsMapByFilter } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Entegrasyonlar',
    path: '/integrations',
  },
];

export type FilterParams = {
  pageSize: number;
  selector: string;
  userName: string;
  ticketID: string;
  pageNumber: number;
  taxNumbers: string[];
  endDate: string | null;
  startDate: string | null;
  orderByType: number | null;
  bankCodes: string | string[];
  errorCodes: string | string[];
  statusType: string | number | null;
  activityType: string | number | null;
  alertPriority: string | number | null;
  integrationStatus: string | number | null;
};

export type SortType = {
  selector: string;
  orderByType: number | null;
};

const Integrations = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const isChangedActivity = useAppSelector(selectIsChangedActivity);
  const selectedTaxNumbers = useAppSelector(selectSelectedTaxNumbers);

  const navigate = useNavigate();
  const location = useLocation();
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [loading, setLoading] = useState(false);
  const [totalIntegration, setTotalIntegration] = useState(0);
  const [integrationList, setIntegrationList] = useState<IntegrationByFilter[]>([]);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    pageSize: 20,
    userName: '',
    selector: '',
    ticketID: '',
    pageNumber: 1,
    bankCodes: [],
    endDate: null,
    statusType: 1,
    errorCodes: [],
    startDate: null,
    orderByType: null,
    activityType: null,
    alertPriority: null,
    integrationStatus: 2,
    taxNumbers: selectedTaxNumbers,
  });

  const onClear = () => {
    setFilterParams({
      pageSize: 20,
      userName: '',
      selector: '',
      ticketID: '',
      pageNumber: 1,
      bankCodes: [],
      endDate: null,
      statusType: 1,
      errorCodes: [],
      startDate: null,
      orderByType: null,
      activityType: null,
      alertPriority: null,
      integrationStatus: 2,
      taxNumbers: selectedTaxNumbers,
    });
  };

  const getFilteredIntegrationsMap = () => {
    setLoading(true);
    getIntegrationsMapByFilter(filterParams).then((res) => {
      setTotalIntegration(res.totalCount);
      setIntegrationList(res.result);
      dispatch(setRows(res.result));
      setLoading(false);
    });
  };

  const getFilteredIntegrationsMapForApply = () => {
    setLoading(true);

    getIntegrationsMapByFilter({
      ...filterParams,
      pageNumber: 1,
      taxNumbers: location.state?.taxNumbers || selectedTaxNumbers,
      integrationStatus:
        location.state?.integrationStatus !== undefined
          ? location.state?.integrationStatus
          : filterParams.integrationStatus,
      statusType: location.state?.statusType !== undefined ? location.state?.statusType : filterParams.statusType,
      alertPriority:
        location.state?.alertPriority !== undefined ? location.state?.alertPriority : filterParams.alertPriority,
      errorCodes: location.state?.errorCodes !== undefined ? location.state?.errorCodes : filterParams.errorCodes,
      bankCodes: location.state?.bankCodes !== undefined ? location.state?.bankCodes : filterParams.bankCodes,
    }).then((res) => {
      setTotalIntegration(res.totalCount);
      setIntegrationList(res.result);
      dispatch(setRows(res.result));
      setLoading(false);
    });
  };

  const onApplySortFilter = async ({ selector, orderByType }: SortType) => {
    setLoading(true);

    try {
      const response = await getIntegrationsMapByFilter({ ...filterParams, selector, orderByType });

      setTotalIntegration(response.totalCount);
      setIntegrationList(response.result);
      dispatch(setRows(response.result));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) => {
    setLoading(true);
    getIntegrationsMapByFilter({ ...filterParams, pageSize, pageNumber }).then((res) => {
      setTotalIntegration(res.totalCount);
      setIntegrationList(res.result);
      dispatch(setRows(res.result));
      setLoading(false);
    });
  };

  const resetLocationState = () => {
    return new Promise((resolve) => {
      navigate(location.pathname, { replace: true });
      resolve(true);
    });
  };

  useLayoutEffect(() => {
    if (location.state) {
      const locationState = { ...location.state };
      const {
        taxNumbers = selectedTaxNumbers,
        integrationStatus = 2,
        alertPriority = null,
        statusType = 1,
        errorCodes = [],
        bankCodes = [],
      } = locationState;

      resetLocationState().then(() => {
        setFilterParams((prevState) => ({
          ...prevState,
          taxNumbers,
          integrationStatus,
          alertPriority,
          statusType,
          errorCodes,
          bankCodes,
        }));
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state) {
      setFilterParams((prevState) => ({
        ...prevState,
        taxNumbers: location.state.taxNumbers,
        integrationStatus: location.state.integrationStatus,
        alertPriority: location.state.alertPriority,
        statusType: location.state.statusType,
        errorCodes: location.state.errorCodes,
        bankCodes: location.state.bankCodes,
      }));
      getFilteredIntegrationsMapForApply();
      return;
    }
    getFilteredIntegrationsMap();
  }, []);

  useEffect(() => {
    setFilterParams((prevState) => ({ ...prevState, taxNumbers: selectedTaxNumbers, pageNumber: 1 }));
    getFilteredIntegrationsMapForApply();
  }, [selectedTaxNumbers]);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'integrations');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Entegrasyonlar" breadcrumbs={breadcrumbs} />
      </Widget>
      <Filters
        onClear={() => onClear()}
        filterParams={filterParams}
        onApplyFilter={getFilteredIntegrationsMap}
        setFilterParams={setFilterParams}
      />
      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Card>
            <IntegrationTable
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              integrationList={integrationList}
              totalIntegration={totalIntegration}
              onApplySortFilter={onApplySortFilter}
              onApplyPaginationFilter={onApplyPaginationFilter}
            />
          </Card>
        )}
      </Widget>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          if (isChangedActivity) {
            getFilteredIntegrationsMap();
          }
          dispatch(setIsDrawerOpen(false));
        }}>
        <DrawerContent />
      </Drawer>
    </>
  );
};

export default Integrations;
