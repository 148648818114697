import {
  LoaderContainer,
  WrapperContainer,
  additionalSelectContainerStyle,
  additionalSelectItemContainerStyle,
  additionalSelectItemSelectedContainerStyle,
} from './styles';
import { Loader, Select } from 'components';
import { Company } from 'library/request/types';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  selectFade,
  selectLoading,
  selectCompanies,
  selectIsDropdownOpen,
  selectSelectedCompanies,
} from 'store/features/select-companies/select-companies.selector';
import { CompanyFilterCard, CompanySelectItem, CompanySelectLabel } from './components';
import { fadeOut, onFilterCompany, setIsDropdownOpen } from 'store/features/select-companies/select-companies.slice';

const SelectCompany = () => {
  const dispatch = useAppDispatch();
  const fade = useAppSelector(selectFade);
  const loading = useAppSelector(selectLoading);
  const isOpen = useAppSelector(selectIsDropdownOpen);
  const formattedCompanies = useAppSelector(selectCompanies);
  const selectedCompanies = useAppSelector(selectSelectedCompanies);

  const [searchTextbox, setSearchTextbox] = useState('');
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [tempCompany, setTempCompany] = useState<Company[]>([]);

  const handleCompany = (company: Company) => {
    dispatch(fadeOut(3));

    if (company.isAll) return setTempCompany([company]);

    const result = tempCompany.find((item) => item.optionUIName === company.optionUIName);

    if (!result) {
      const tempArray = tempCompany.filter((item) => !item.isAll);
      return setTempCompany([...tempArray, company]);
    }

    const withoutAllArray = tempCompany.filter((item) => !item.isAll);
    const tempArray = withoutAllArray.filter((item) => item.optionUIName !== company.optionUIName);
    return setTempCompany(tempArray);
  };

  const clearApply = () => {
    dispatch(onFilterCompany([formattedCompanies[0]]));
  };

  const handleClose = () => {
    if (selectContainerRef.current && isOpen) {
      selectContainerRef.current.style.zIndex = '0';
      dispatch(setIsDropdownOpen(false));
      document.dispatchEvent(new CustomEvent('closeSelects', { detail: true }));
    }
  };

  const clearSelectedItems = () => {
    handleCompany(formattedCompanies[0]);
    clearApply();
    handleClose();
  };

  const filterApply = () => {
    dispatch(onFilterCompany(tempCompany));
  };

  const selectedTreshold = () => {
    const isAllSelected = tempCompany.find((sc) => sc.isAll);
    if (isAllSelected) return 1;

    return 0;
  };

  const handleClick = () => {
    if (selectContainerRef.current && isOpen) {
      handleClose();
    }

    if (selectContainerRef.current && !isOpen) {
      selectContainerRef.current.style.zIndex = '9999';
      dispatch(setIsDropdownOpen(true));
    }
  };

  useEffect(() => {
    fade === 2 && setTempCompany(selectedCompanies);
  }, [fade]);

  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <WrapperContainer ref={selectContainerRef} onClick={handleClick}>
      <Select
        portalBlur
        multiple
        placeholder={<CompanySelectLabel />}
        $additionalSelectItemSelectedContainerStyle={additionalSelectItemSelectedContainerStyle}
        selected={selectedCompanies}
        selectedTreshold={selectedTreshold()}
        $additionalSelectContainerStyle={additionalSelectContainerStyle}
        $additionalSelectItemContainerStyle={additionalSelectItemContainerStyle}
        clearSelectedItems={() => clearSelectedItems()}>
        <CompanyFilterCard
          filterApplyButton={() => filterApply()}
          filterCompanyText={(text) => setSearchTextbox(text)}
          searchText={searchTextbox}
        />
        <CompanySelectItem
          items={formattedCompanies}
          selectedItems={tempCompany}
          onHandleCompany={(item) => handleCompany(item)}
          searchText={searchTextbox}
        />
      </Select>
    </WrapperContainer>
  );
};

export default SelectCompany;
