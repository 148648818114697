import * as Icons from 'assets/icons';
import { css, keyframes, styled } from 'styled-components';
import { bgDropdownOption, dropdownOptionBorder } from 'assets/style-helpers/colors';

//
type OptionItemGeneralContainerProps = {
  $isAll: boolean | undefined;
  $isHalfSelected: boolean;
};

const isAllStyle = css`
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const isHalfSelectedStyle = css`
  background-color: rgba(#0f62fe, 0.2) !important;

  &:hover {
    background-color: rgba(#0f62fe, 0.3) !important;
  }
`;

export const OptionItemGeneralContainer = styled.div<OptionItemGeneralContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  height: 60px;
  padding: 8px;
  color: rgba(#1a1a1a, 0.87);
  background-color: ${bgDropdownOption};
  border-bottom: 1px solid ${dropdownOptionBorder};

  &:hover {
    background-color: rgba(77, 77, 77, 0.5) !important;
  }

  abbr[title] {
    flex: 1;
    text-decoration: none;
  }

  ${({ $isAll }) => $isAll && isAllStyle}
  ${({ $isHalfSelected }) => $isHalfSelected && isHalfSelectedStyle}
`;

//

type OptionItemContainerProps = {
  $isNode: boolean | undefined;
  $isSelected: boolean;
};

const isNodeStyle = css`
  margin-right: 40px;
`;

const isSelectedStyle = css`
  background-color: #0f62fe;
  color: rgba(#fff, 0.87);
`;

export const OptionItemContainer = styled.div<OptionItemContainerProps>`
  flex: 1;
  border-radius: 4px;
  padding: 8px 4px 8px 4px;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${({ $isNode }) => $isNode && isNodeStyle}
  ${({ $isSelected }) => $isSelected && isSelectedStyle}
`;

//

type OptionItemShowMoreWrapperContainerProps = {
  $isNode: boolean | undefined;
};

const isNodeShowMoreWrapperStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionItemShowMoreWrapperContainer = styled.div<OptionItemShowMoreWrapperContainerProps>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;

  ${({ $isNode }) => $isNode && isNodeShowMoreWrapperStyle}
`;

//

type OptionItemShowMoreContainerProps = {
  $isNode: boolean | undefined;
  $openStatus: boolean;
};

const isNodeShowMoreStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const isOpenShowMoreStyle = css`
  background-color: rgba(77, 77, 77, 0.75);

  > span {
    color: #fff !important;
  }
`;

export const OptionItemShowMoreContainer = styled.div<OptionItemShowMoreContainerProps>`
  display: none;
  border-radius: 4px;
  background-color: rgba(rgb(77, 77, 77), 0.15);
  z-index: 99;
  width: 30px;
  height: 30px;

  ${({ $isNode }) => $isNode && isNodeShowMoreStyle}
  ${({ $openStatus }) => $openStatus && isOpenShowMoreStyle}
`;

//
export const ExpandIconMore = styled(Icons.NavigationExpandMoreSvg)`
  width: 24px;
  height: 24px;
`;

export const ExpandIconLess = styled(Icons.NavigationExpandLessSvg)`
  width: 24px;
  height: 24px;
`;

//

type OptionSubItemContainerProps = {
  $openStatus: boolean;
};

const fadeIn = keyframes`
  from {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  50% {
    visibility: visible;
    opacity: 0.1;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
`;

const isOpenStyle = css`
  display: block;
  animation: ${fadeIn} 200ms ease-in-out forwards;
`;

export const OptionSubItemContainer = styled.div<OptionSubItemContainerProps>`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ $openStatus }) => $openStatus && isOpenStyle}
`;
