import request from 'library/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { getIntegrationById } = request;

const getIntegrationByCompanyIntegrationId = createAsyncThunk(
  'getIntegrationByCompanyIntegrationId',
  async (companyIntegrationId: number) => {
    return await getIntegrationById({ id: companyIntegrationId });
  }
);

const drawerSolutionsActions = {
  getIntegrationByCompanyIntegrationId,
};

export default drawerSolutionsActions;
