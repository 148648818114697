import request from 'library/request';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import { Card, Loader, Widget } from 'components';
import Authentication from 'utils/authentication';
import { ErrorSolution } from 'library/request/types';
import { useState, useEffect, useCallback } from 'react';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsDrawerOpen } from 'store/features/drawer/drawer.slice';
import { selectIsDrawerOpen } from 'store/features/drawer/drawer.selector';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { SolutionsFilters, SolutionsTable, SolutionsDrawerContent } from 'containers/elements/solutions';

const { getErrorSolutionMapByFilter } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Çözümler',
    path: '/solutions',
  },
];

export type SolutionsFilterParams = {
  pageSize: number;
  pageNumber: number;
  bankCodes: string | string[];
  errorCodes: string | string[];
};

const Solutions = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);

  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [loading, setLoading] = useState(false);
  const [totalSolution, setTotalSolution] = useState(0);
  const [solutionList, setSolutionList] = useState<ErrorSolution[]>([]);
  const [filterParams, setFilterParams] = useState<SolutionsFilterParams>({
    pageSize: 20,
    pageNumber: 1,
    bankCodes: [],
    errorCodes: [],
  });

  const onClear = () => {
    setFilterParams({
      pageNumber: 1,
      pageSize: 20,
      bankCodes: [],
      errorCodes: [],
    });
  };

  const onApplyFilter = () => {
    setLoading(true);
    getErrorSolutionMapByFilter(filterParams).then((res) => {
      setTotalSolution(res.totalCount);
      setSolutionList(res.result);
      setLoading(false);
    });
  };

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) => {
    setLoading(true);
    getErrorSolutionMapByFilter({ ...filterParams, pageSize, pageNumber }).then((res) => {
      setTotalSolution(res.totalCount);
      setSolutionList(res.result);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getErrorSolutionMapByFilter(filterParams).then((res) => {
      setTotalSolution(res.totalCount);
      setSolutionList(res.result);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'solutions');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Çözümler" breadcrumbs={breadcrumbs} />
      </Widget>
      <SolutionsFilters
        onClear={onClear}
        filterParams={filterParams}
        onApplyFilter={onApplyFilter}
        setFilterParams={setFilterParams}
      />
      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Card>
            <SolutionsTable
              solutionList={solutionList}
              totalSolution={totalSolution}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              onApplyPaginationFilter={onApplyPaginationFilter}
            />
          </Card>
        )}
      </Widget>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => dispatch(setIsDrawerOpen(false))}>
        <SolutionsDrawerContent />
      </Drawer>
    </>
  );
};

export default Solutions;
