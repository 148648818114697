import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

type BorderLinearProgressProps = {
  fillcolor: string;
};

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(({ fillcolor }) => ({
  height: '10px',
  borderRadius: '12px',
  border: '1px solid #fafafab3',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'inherit',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: `12px 0px 0px 12px`,
    backgroundColor: fillcolor,
  },
}));

export default BorderLinearProgress;
