import { createSlice } from '@reduxjs/toolkit';
import { ErrorCodesState } from './error-codes.types';
import errorCodesActions from './error-codes.actions';

const initialState: ErrorCodesState = {
  error: null,
  isLoading: false,
  errorCodesMap: [],
};

export const errorCodesSlice = createSlice({
  initialState,
  name: 'error-codes',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(errorCodesActions.getCodes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(errorCodesActions.getCodes.fulfilled, (state, { payload }) => {
      state.errorCodesMap = payload;
      state.isLoading = false;
    });

    builder.addCase(errorCodesActions.getCodes.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export default errorCodesSlice.reducer;
