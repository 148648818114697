import { useAppSelector } from 'store/hooks';
import { Autocomplete, TextField } from '@mui/material';
import { Option } from 'store/features/users/users.types';
import { selectUsersMap } from 'store/features/users/users.selector';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { GroupCompaniesFilterParams } from 'pages/group-companies-main';

const allItem = { value: '', label: 'Tümü' };

type GroupCompaniesSelectSupportPersonProps = {
  onChange: Dispatch<SetStateAction<GroupCompaniesFilterParams>>;
  filterParams: GroupCompaniesFilterParams;
};

const GroupCompaniesSelectSupportPerson = ({ onChange, filterParams }: GroupCompaniesSelectSupportPersonProps) => {
  const userMap = useAppSelector(selectUsersMap);
  const users = [allItem, ...userMap];

  const [selectedUser, setSelectedUser] = useState<Option>(allItem);

  useEffect(() => {
    if (!filterParams.defaultSupportPerson) {
      setSelectedUser(allItem);
    }
  }, [filterParams.defaultSupportPerson]);

  return (
    <Autocomplete
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      size="small"
      value={selectedUser}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disablePortal
      id="combo-box-demo"
      options={users}
      onChange={(event: any, newValue: Option | null) => {
        const value = newValue ?? allItem;
        setSelectedUser(value);

        const defaultSupportPerson = value.value;
        onChange((prevState: GroupCompaniesFilterParams) => ({ ...prevState, defaultSupportPerson }));
      }}
      renderInput={(params) => <TextField {...params} label="Kullanıcı" />}
      ListboxProps={{
        style: {
          maxHeight: '155px',
        },
      }}
    />
  );
};

export default GroupCompaniesSelectSupportPerson;
