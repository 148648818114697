import { keyframes, styled } from 'styled-components';
import { black as blackStyle, white } from 'assets/style-helpers/colors';

export const WrapperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const chaseAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const ChaseContainer = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  animation: ${chaseAnimation} 2.5s infinite linear both;
`;

const dotAnimation = keyframes`
  80%,
  100% {
    transform: rotate(360deg);
  }
`;

const dotBeforeAnimation = keyframes`
  50% {
    transform: scale(0.4);
  }

  100%,
  0% {
    transform: scale(1);
  }
`;

type DotContainerProps = {
  $white?: boolean;
};

const DotContainer = styled.div<DotContainerProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${dotAnimation} 2s infinite ease-in-out both;

  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: ${({ $white }) => ($white ? white : blackStyle)};

    border-radius: 100%;
    animation: ${dotBeforeAnimation} 2s infinite ease-in-out both;
  }
`;

export const ChildDotContainer = styled(DotContainer)`
  &:nth-child(1) {
    animation-delay: -1.1s;
  }

  &:nth-child(2) {
    animation-delay: -1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }

  &:nth-child(4) {
    animation-delay: -0.8s;
  }

  &:nth-child(5) {
    animation-delay: -0.7s;
  }

  &:nth-child(6) {
    animation-delay: -0.6s;
  }

  &:nth-child(1):before {
    animation-delay: -1.1s;
  }

  &:nth-child(2):before {
    animation-delay: -1s;
  }

  &:nth-child(3):before {
    animation-delay: -0.9s;
  }

  &:nth-child(4):before {
    animation-delay: -0.8s;
  }

  &:nth-child(5):before {
    animation-delay: -0.7s;
  }

  &:nth-child(6):before {
    animation-delay: -0.6s;
  }
`;
