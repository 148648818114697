import {
  ExpandIconLess,
  ExpandIconMore,
  ExpandIconClose,
  SelectContainer,
  SelectItemContainer,
  SelectItemSelectedContainer,
} from './styles';
import { createPortal } from 'react-dom';
import { RuleSet } from 'styled-components';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useRef, useEffect, MouseEvent, ReactNode } from 'react';
import { selectIsDropdownOpen } from 'store/features/select-companies/select-companies.selector';
import { fadeOut, setIsDropdownOpen } from 'store/features/select-companies/select-companies.slice';

type SelectProps = {
  offset?: number;
  position?: string;
  multiple?: boolean;
  showIcon?: boolean;
  children: ReactNode;
  selected: any[];
  portalBlur?: boolean;
  placeholder: ReactNode;
  selectedTreshold?: number;
  clearSelectedItems: () => void;
  $additionalSelectContainerStyle: RuleSet;
  $additionalSelectItemContainerStyle: RuleSet;
  $additionalSelectItemSelectedContainerStyle?: RuleSet;
};

const Select = (props: SelectProps) => {
  const {
    portalBlur = false,
    multiple = false,
    placeholder = '',
    $additionalSelectItemSelectedContainerStyle,
    selected,
    selectedTreshold = 0,
    $additionalSelectContainerStyle,
    $additionalSelectItemContainerStyle,
    clearSelectedItems,
    children,
    offset = 5,
    showIcon = true,
    position = 'top',
    ...restProps
  } = props;

  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsDropdownOpen);

  const selectContainerRef = useRef<HTMLDivElement>(null);
  const selectItemContainerRef = useRef<HTMLUListElement>(null);
  // const appRootElement = document.getElementById('app-root') as HTMLElement;

  useOnClickOutside(selectItemContainerRef, () => closeSelectContainer());

  const closeSelectContainer = () => {
    if (selectContainerRef.current && selectItemContainerRef.current && isOpen) {
      // appRootElement.classList.remove('blurred');
      selectItemContainerRef.current.classList.remove('fade-in');

      dispatch(setIsDropdownOpen(false));
      dispatch(fadeOut(2));
    }
  };

  const closeSelectWithEventHandler = () => {
    // appRootElement.classList.remove('blurred');
    selectItemContainerRef.current?.classList.remove('fade-in');

    dispatch(fadeOut(2));
    dispatch(setIsDropdownOpen(false));
  };

  useEffect(() => {
    document.addEventListener('closeSelects', closeSelectWithEventHandler, false);

    return () => {
      document.removeEventListener('closeSelects', closeSelectWithEventHandler, false);
    };
  }, []);

  const handleClick = () => {
    if (isOpen) {
      closeSelectContainer();
    }

    if (selectContainerRef.current && selectItemContainerRef.current && !isOpen) {
      // if (portalBlur) appRootElement.classList.add('blurred');
      selectItemContainerRef.current.classList.add('fade-in');

      dispatch(setIsDropdownOpen(true));

      const selectContainerRect = selectContainerRef.current.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();

      if (position === 'top') {
        selectItemContainerRef.current.setAttribute(
          'style',
          `
            top:${selectContainerRect.y + selectContainerRect.height + offset - bodyRect.y}px;
            left:${selectContainerRect.x}px;
            width:${selectContainerRect.width}px;
            `
        );
      }

      if (position === 'bottom') {
        const selectItemContainerRect = selectItemContainerRef.current.getBoundingClientRect();
        selectItemContainerRef.current.setAttribute(
          'style',
          `
            top:${selectContainerRect.y - selectItemContainerRect.height - offset}px;
            left:${selectContainerRect.x}px;
            width:${selectContainerRect.width}px;
            `
        );
      }
    }
  };

  const renderIcon = () => {
    if (multiple && selected) {
      if (Array.isArray(selected) && selected.length > selectedTreshold) {
        const handleClick = (e: MouseEvent) => {
          e.stopPropagation();
          clearSelectedItems();
        };

        return <ExpandIconClose onClick={handleClick} />;
      }
    }

    return isOpen ? <ExpandIconLess /> : <ExpandIconMore />;
  };

  const handleClickItemContainer = () => {
    closeSelectContainer();
  };

  return (
    <SelectContainer
      $additionalSelectContainerStyle={$additionalSelectContainerStyle}
      ref={selectContainerRef}
      onClick={handleClick}
      {...restProps}>
      {placeholder && (
        <SelectItemSelectedContainer
          $additionalSelectItemSelectedContainerStyle={$additionalSelectItemSelectedContainerStyle}>
          {placeholder}
        </SelectItemSelectedContainer>
      )}

      {showIcon && renderIcon()}

      {createPortal(
        <SelectItemContainer
          $additionalSelectItemContainerStyle={$additionalSelectItemContainerStyle}
          ref={selectItemContainerRef}
          onClick={handleClickItemContainer}>
          {children}
        </SelectItemContainer>,
        document.getElementById('select-root') as HTMLElement
      )}
    </SelectContainer>
  );
};

export default Select;
