import { Dispatch, SetStateAction } from 'react';
import { FilterParams } from 'pages/integrations';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, InputLabel, FormControl } from '@mui/material';
import { INTEGRATION_STATUS_TYPE_ENUM, getIntegrationStatusTranslated } from 'enums';

type SelectIntegrationStatusProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const SelectIntegrationStatus = ({ onChange, filterParams }: SelectIntegrationStatusProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const integrationStatus = event.target.value === 'Tümü' ? null : event.target.value;
    onChange((prevState: FilterParams) => ({ ...prevState, integrationStatus }));
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="demo-simple-select-label">
        Entegrasyon
      </InputLabel>
      <Select
        sx={{
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        }}
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filterParams.integrationStatus ? String(filterParams.integrationStatus) : 'Tümü'}
        label="Entegrasyon"
        defaultValue={filterParams.integrationStatus ? String(filterParams.integrationStatus) : 'Tümü'}
        onChange={handleChange}>
        <MenuItem value={'Tümü'}>Tümü</MenuItem>
        {(
          Object.keys(INTEGRATION_STATUS_TYPE_ENUM).filter((v) =>
            isNaN(Number(v))
          ) as (keyof typeof INTEGRATION_STATUS_TYPE_ENUM)[]
        ).map((key, index) => (
          <MenuItem key={index.toString()} value={INTEGRATION_STATUS_TYPE_ENUM[key]}>
            {getIntegrationStatusTranslated(INTEGRATION_STATUS_TYPE_ENUM[key])}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectIntegrationStatus;
