import * as Icons from 'assets/icons';
import TableRow from '@mui/material/TableRow';
import styled, { css } from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const additionalInfoCardStyle = css`
  padding: 0px 10px !important;
  border-radius: 15px !important;
`;

export const additionalInfoCardContentStyle = css`
  justify-content: center !important;
  align-items: center !important;
`;

export const StyledTableCell = styledMui(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1e1e1e19',
    color: '#1e1e1e',
    fontFamily: '"Open Sans", sans-serif',
    border: 'none',
    '&:nth-of-type(1)': {
      borderRadius: '12px 0px 0px 12px',
    },
    '&:nth-last-of-type(1)': {
      borderRadius: '0px 12px 12px 0px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: '"Open Sans", sans-serif',
    borderBottom: 'none',
  },
}));

export const StyledTableRow = styledMui(TableRow)(() => ({
  cursor: 'pointer',
  /*
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffffff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f7f7f7ff',
  },
  */
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}));

export const SortDownIcon = styled(Icons.SortDownSvg)`
  height: 16px;
  width: 16px;
`;

export const SortUpIcon = styled(Icons.SortUpSvg)`
  height: 16px;
  width: 16px;
`;

export const CardTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 20px 10px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const TitleSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  color: #00000080;
  font-weight: 500;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
`;

export const ProgressContainer = styled.div`
  min-width: 220px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
