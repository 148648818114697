import { ReactNode } from 'react';
import { styled } from 'styled-components';
import { getPriorityIcon, getPriorityTranslated } from 'enums';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const renderFormattedPriority = (value: number | null): ReactNode => {
  if (value === null) return '-';

  return (
    <Container>
      {getPriorityIcon(value)}
      {getPriorityTranslated(value)}
    </Container>
  );
};
