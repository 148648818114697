import { createSlice } from '@reduxjs/toolkit';
import { DrawerSolutionsState } from './drawer-solutions.types';
import drawerSolutionsActions from './drawer-solutions.actions';

const initialState: DrawerSolutionsState = {
  error: null,
  isLoading: false,
  isDrawerOpen: false,
  loadingUpdate: false,
  markedCommentId: null,
  selectedIntegration: null,
  selectedCompanyIntegrationId: null,
};

const drawerSolutionsSlice = createSlice({
  initialState,
  name: 'drawer-solutions',
  reducers: {
    setIsDrawerOpen(state, { payload }) {
      state.isDrawerOpen = payload;
    },

    setMarkedCommentId(state, { payload }) {
      state.markedCommentId = payload;
    },

    setSelectedCompanyIntegrationId(state, { payload }) {
      state.selectedCompanyIntegrationId = payload;
    },

    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(drawerSolutionsActions.getIntegrationByCompanyIntegrationId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(drawerSolutionsActions.getIntegrationByCompanyIntegrationId.fulfilled, (state, { payload }) => {
      state.selectedIntegration = payload;
      state.isLoading = false;
    });

    builder.addCase(drawerSolutionsActions.getIntegrationByCompanyIntegrationId.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export const { setMarkedCommentId, setSelectedCompanyIntegrationId } = drawerSolutionsSlice.actions;

export default drawerSolutionsSlice.reducer;
