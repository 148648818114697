import { useCallback } from 'react';
import { Widget, Card } from 'components';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Authentication from 'utils/authentication';
import { stringAvatar } from 'utils/string-avatar';
import { StyledPaper, StyledTextField } from './styles';
import { Breadcrumb } from 'containers/elements/@commons';
import { Box, Grid, Avatar, Button } from '@mui/material';
import { DisplayGroupCompanies } from 'containers/elements/my-profile';
import { ROLE_TYPE_ENUM, getRoleTranslated, getStatusTranslated } from 'enums';

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Profilim',
    path: '/my-profile',
  },
];

const avatarStyleObj = {
  width: '175px',
  height: '175px',
  fontSize: '38px',
  textAlign: 'center',
  margin: '0 auto',
};

const MyProfile = () => {
  const user = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goToEditUserHandler = useCallback(() => navigate('edit'), []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Profilim" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget title="Profil Bilgileri">
        <Card>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item spacing={3} container xs={12} md={3}>
                <Grid justifyItems="center" item xs={12} md={12}>
                  <Avatar component="span" {...stringAvatar(user.label, avatarStyleObj)} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: '8px' }}
                    startIcon={<EditIcon />}
                    onClick={goToEditUserHandler}
                    fullWidth>
                    Profili Düzenle
                  </Button>
                </Grid>
              </Grid>

              <Grid item container spacing={2} xs={12} md={9}>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-name"
                      label="Ad"
                      defaultValue={user.name}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-surname"
                      label="Soyad"
                      defaultValue={user.surname}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-userName"
                      label="Kullanıcı Adı"
                      defaultValue={user.value}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-email"
                      label="E-Posta"
                      defaultValue={user.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-telegram-user-name"
                      label="Telegram Kullanıcı Adı"
                      defaultValue={user.telegramUserName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-telegram-user-id"
                      label="Telegram Kullanıcı Id"
                      defaultValue={user.telegramUserId}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-roleType"
                      label="Rol"
                      defaultValue={getRoleTranslated(user.roleType as number)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledPaper>
                    <StyledTextField
                      size="small"
                      fullWidth
                      id="outlined-read-only-input-statusType"
                      label="Durum"
                      defaultValue={getStatusTranslated(user.statusType as number)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </StyledPaper>
                </Grid>
                {user.roleType === ROLE_TYPE_ENUM.ADMIN ? (
                  <Grid item xs={12} md={12}>
                    <StyledPaper>
                      <StyledTextField
                        size="small"
                        fullWidth
                        id="outlined-read-only-input-groupCompaniesAll"
                        label="Grup Şirket"
                        defaultValue={'Tüm Grup Şirketler İçin Yetkilidir'}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </StyledPaper>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={12}>
                    <StyledPaper>
                      <DisplayGroupCompanies groupCompanies={user.companyGroupIds as number[]} />
                    </StyledPaper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default MyProfile;
