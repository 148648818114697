import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { ROLE_API_NAME_ENUM } from 'enums';
import {
  EditMyProfileTextfieldName,
  EditMyProfileTextfieldEmail,
  EditMyProfileSelectRoleType,
  EditMyProfileTextfieldSurname,
  EditMyProfileSelectStatusType,
  EditMyProfileTextfieldPassword,
  EditMyProfileCheckboxUseTelegram,
  EditMyProfileTextfieldTelegramUserId,
  EditMyProfileTextfieldTelegramUserName,
} from 'containers/elements/my-profile-edit';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Widget, Card, Text } from 'components';
import Authentication from 'utils/authentication';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import usersActions from 'store/features/users/users.actions';
import FormButtons from 'containers/elements/@commons/form-buttons';

const { updateUser } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Profilim',
    path: '/my-profile',
  },
  {
    name: 'Profil Düzenle',
    path: '/my-profile/edit',
  },
];

export type EditMyProfileFormParams = {
  name: string;
  email: string;
  surname: string;
  userName: string;
  roleType: number;
  statusType: number;
  password: string | null;
  companyGroupIds: number[];
  telegramUserName: string;
  telegramUserId: string;
  useTelegram: boolean;
};

const MyProfileEdit = () => {
  const dispatch = useAppDispatch();
  const selectedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<EditMyProfileFormParams>({
    password: '',
    companyGroupIds: selectedUser?.companyGroupIds as number[],
    name: selectedUser?.name as string,
    email: selectedUser?.email as string,
    surname: selectedUser?.surname as string,
    userName: selectedUser?.value as string,
    roleType: selectedUser?.roleType as number,
    statusType: selectedUser?.statusType as number,
    telegramUserName: selectedUser?.telegramUserName as string,
    telegramUserId: selectedUser?.telegramUserId as string,
    useTelegram: selectedUser?.useTelegram as boolean,
  });

  const onClear = () => {
    setFormParams({
      password: '',
      companyGroupIds: selectedUser?.companyGroupIds as number[],
      name: selectedUser?.name as string,
      email: selectedUser?.email as string,
      surname: selectedUser?.surname as string,
      userName: selectedUser?.value as string,
      roleType: selectedUser?.roleType as number,
      statusType: selectedUser?.statusType as number,
      telegramUserName: selectedUser?.telegramUserName as string,
      telegramUserId: selectedUser?.telegramUserId as string,
      useTelegram: selectedUser?.useTelegram as boolean,
    });
  };

  const handleCancel = () => {
    onClear();
    goToMainPage();
  };

  const handleSubmit = () => {
    if (formParams.name === '' || formParams.surname === '' || formParams.email === '') {
      setErrorMessage(
        formParams.name === ''
          ? 'Ad boş geçilemez.'
          : formParams.surname === ''
          ? 'Soyad boş geçilemez.'
          : formParams.email === ''
          ? 'E-Posta boş geçilemez.'
          : ''
      );
      return setError(true);
    }

    if (formParams.useTelegram && (formParams.telegramUserName === '' || formParams.telegramUserId === '')) {
      setErrorMessage('Varsayılan telegram olarak seçili iken telegram kullanıcı adı veya id boş bırakılamaz.');
      return setError(true);
    }

    setLoading(true);

    updateUser(formParams)
      .then((res) => {
        if (res.message === 'Bazı hatalar oluştu') {
          setErrorMessage(res.validationErrors?.[0].errorMessage as string);
          setError(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        dispatch(usersActions.getUsers());
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      });
  };

  useEffect(() => {
    setError(false);
  }, [formParams]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Profil Düzenle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <span>Ad</span>
                  <StyledPaper>
                    <EditMyProfileTextfieldName onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>Soyad</span>
                  <StyledPaper>
                    <EditMyProfileTextfieldSurname onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                {selectedUser?.role === ROLE_API_NAME_ENUM.ADMIN && (
                  <Grid item xs={12} md={4}>
                    <span>Rol</span>
                    <StyledPaper>
                      <EditMyProfileSelectRoleType onChange={setFormParams} formParams={formParams} />
                    </StyledPaper>
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <span>Parola</span>
                  <StyledPaper>
                    <EditMyProfileTextfieldPassword onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span>E-Posta</span>
                  <StyledPaper>
                    <EditMyProfileTextfieldEmail onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                {selectedUser?.role === ROLE_API_NAME_ENUM.ADMIN && (
                  <Grid item xs={12} md={4}>
                    <span>Durum</span>
                    <StyledPaper>
                      <EditMyProfileSelectStatusType onChange={setFormParams} formParams={formParams} />
                    </StyledPaper>
                  </Grid>
                )}

                <Grid item xs={12} md={4}>
                  <span>Telegram Kullanıcı Adı</span>
                  <StyledPaper>
                    <EditMyProfileTextfieldTelegramUserName
                      onChange={setFormParams}
                      formParams={formParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <span>Telegram Kullanıcı Id</span>
                  <StyledPaper>
                    <EditMyProfileTextfieldTelegramUserId
                      onChange={setFormParams}
                      formParams={formParams}
                      error={error}
                    />
                  </StyledPaper>
                </Grid>

                <Grid item xs={12} md={12}>
                  <EditMyProfileCheckboxUseTelegram onChange={setFormParams} formParams={formParams} />
                </Grid>
              </Grid>
              <FormButtons
                loading={loading}
                actionName={'Kaydet'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default MyProfileEdit;
