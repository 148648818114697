import { Dispatch, SetStateAction } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AddGroupCompanyFormParams } from 'pages/group-companies-add';

type AddGroupCompanyCheckboxSendAutoEmailProps = {
  onChange: Dispatch<SetStateAction<AddGroupCompanyFormParams>>;
  formParams: AddGroupCompanyFormParams;
};
const AddGroupCompanyCheckboxSendAutoEmail = ({ onChange, formParams }: AddGroupCompanyCheckboxSendAutoEmailProps) => {
  const handleChange = (event: any) => {
    const sendAutoEmail = event.target.checked;
    onChange((prevState) => ({ ...prevState, sendAutoEmail }));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      sx={{
        fontFamily: '"Open Sans", sans-serif',
      }}
      labelPlacement="end"
      label="Belirlenen adreslere otomatik olarak e-posta gönderilsin."
      checked={formParams.sendAutoEmail}
      onChange={handleChange}
    />
  );
};

export default AddGroupCompanyCheckboxSendAutoEmail;
