import {
  additionalRowStyle,
  OperationsContainer,
  additionalButtonStyle,
  additionalWidgetStyle,
  additionalContainerStyle,
  additionalMainContainerStyle,
} from './styles';
import { Box, Grid, Paper } from '@mui/material';
import { SetStateAction, Dispatch } from 'react';
import { Card, Widget, Button } from 'components';
import { ErrorCodesFilterParams } from 'pages/error-codes-main';
import { ErrorCodesSelectPriority, ErrorCodesSelectErrorCodes, ErrorCodesTextfieldDescription } from './components';

type ErrorCodesFiltersProps = {
  onClear: () => void;
  onApplyFilter: () => void;
  filterParams: ErrorCodesFilterParams;
  setFilterParams: Dispatch<SetStateAction<ErrorCodesFilterParams>>;
};

const ErrorCodesFilters = ({ filterParams, setFilterParams, onClear, onApplyFilter }: ErrorCodesFiltersProps) => {
  return (
    <>
      <Widget $additionalStyle={additionalWidgetStyle} $additionalRowStyle={additionalRowStyle}>
        <Card $additionalContainerStyle={additionalMainContainerStyle}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <ErrorCodesTextfieldDescription onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <ErrorCodesSelectPriority onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <ErrorCodesSelectErrorCodes onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Widget>
      <Card $additionalContainerStyle={additionalContainerStyle}>
        <OperationsContainer>
          <Button
            onClick={onClear}
            $additionalStyle={additionalButtonStyle}
            style={{
              width: '100px',
              fontSize: '12px',
              backgroundColor: 'inherit',
              marginLeft: '10px',
            }}>
            Temizle
          </Button>
          <Button
            $additionalStyle={additionalButtonStyle}
            style={{
              backgroundColor: '#0f62fe',
              color: '#fff',
              width: '100px',
              marginRight: '10px',
            }}
            onClick={() => {
              onApplyFilter();
            }}>
            Filtrele
          </Button>
        </OperationsContainer>
      </Card>
    </>
  );
};

export default ErrorCodesFilters;
