import './styles.css';
import { Dispatch, SetStateAction } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import { EditGroupCompanyFormParams } from 'pages/group-companies-edit';

type EditGroupCompanyTextfieldAutoNotificationEmailAddressesProps = {
  onChange: Dispatch<SetStateAction<EditGroupCompanyFormParams>>;
  formParams: EditGroupCompanyFormParams;
  error: boolean;
};

const EditGroupCompanyTextfieldAutoNotificationEmailAddresses = ({
  onChange,
  formParams,
  error,
}: EditGroupCompanyTextfieldAutoNotificationEmailAddressesProps) => {
  return (
    <ReactMultiEmail
      emails={formParams.autoNotificationEmailAddresses}
      onChange={(_emails: string[]) => {
        onChange((prevState) => ({ ...prevState, autoNotificationEmailAddresses: _emails }));
      }}
      getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
        return (
          <div data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        );
      }}
      style={error && !formParams.autoNotificationEmailAddresses.length ? { borderColor: 'red' } : {}}
    />
  );
};

export default EditGroupCompanyTextfieldAutoNotificationEmailAddresses;
