import {
  Select,
  Button,
  Dialog,
  MenuItem,
  Checkbox,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import { Loader } from 'components';
import request from 'library/request';
import { useAppSelector } from 'store/hooks';
import { SelectChangeEvent } from '@mui/material/Select';
import { IntegrationByFilter } from 'library/request/types';
import { ACTIVITY_TYPE_ENUM, getActivityTranslated } from 'enums';
import { selectSelectedIntegration } from 'store/features/drawer-solutions/drawer-solutions.selector';

const { changeIntegrationStatus } = request;

const SolutionsChangeActivity = () => {
  const selectedIntegration = useAppSelector(selectSelectedIntegration) as IntegrationByFilter;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [openMailDialog, setOpenMailDialog] = useState(false);
  const [newActivity, setNewActivity] = useState(String(selectedIntegration.activityType));

  const handleClickOpenMailDialog = () => {
    setOpenMailDialog(true);
  };

  const handleCloseMailDialog = () => {
    setOpenMailDialog(false);
    setError(false);
    setErrorMessage('');
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selectedStatus = event.target.value;

    if (Number(selectedStatus) === ACTIVITY_TYPE_ENUM.WAITING_ON_CUSTOMER) {
      handleClickOpenMailDialog();
    } else {
      setLoading(true);

      changeIntegrationStatus({
        companyIntegrationId: selectedIntegration.id,
        activityType: Number(selectedStatus),
        autoEmailContentToCustomer: '',
      }).then((res) => {
        setNewActivity(selectedStatus);
        setLoading(false);
      });
    }
  };

  const handleChangeNotifyCustomer = () => {
    setError(false);
    setErrorMessage('');
    setNotifyCustomer(!notifyCustomer);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Select
        sx={{ height: '30px' }}
        variant="standard"
        fullWidth
        size="small"
        labelId="change-activity-solutions-label"
        id="change-activity-solutions"
        value={newActivity}
        defaultValue={String(selectedIntegration.activityType)}
        onChange={handleChange}>
        {(Object.keys(ACTIVITY_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof ACTIVITY_TYPE_ENUM)[]).map(
          (key, index) => (
            <MenuItem key={index.toString()} value={ACTIVITY_TYPE_ENUM[key]}>
              {getActivityTranslated(ACTIVITY_TYPE_ENUM[key])}
            </MenuItem>
          )
        )}
      </Select>
      <Dialog
        fullWidth
        open={openMailDialog}
        onClose={handleCloseMailDialog}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const text = formJson.text;

            if (!text && notifyCustomer) {
              setError(true);
              setErrorMessage('Bu alanın doldurulması zorunludur.');
              return;
            }

            const activityType = ACTIVITY_TYPE_ENUM.WAITING_ON_CUSTOMER;

            setLoading(true);

            changeIntegrationStatus({
              companyIntegrationId: selectedIntegration.id,
              activityType,
              autoEmailContentToCustomer: text || '',
            }).then((res) => {
              setNewActivity(String(activityType));
              setLoading(false);
            });

            handleCloseMailDialog();
          },
        }}>
        <DialogTitle>Müşteriye Gönderilecek E-Posta İçeriği</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            disabled={!notifyCustomer}
            margin="dense"
            id="text"
            name="text"
            label={notifyCustomer ? 'E-Posta İçeriği' : 'Bu alan doldurulamaz'}
            type="text"
            fullWidth
            variant="outlined"
            rows={6}
            multiline
            error={error}
            helperText={errorMessage}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (event.target.value) {
                setError(false);
                setErrorMessage('');
              }
            }}
          />
          <FormControlLabel
            control={<Checkbox />}
            sx={{
              fontFamily: '"Open Sans", sans-serif',
            }}
            labelPlacement="end"
            label="Müşteriye Bildirim Gitmesin."
            checked={!notifyCustomer}
            onChange={handleChangeNotifyCustomer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMailDialog}>Vazgeç</Button>
          <Button variant="contained" type="submit">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SolutionsChangeActivity;
