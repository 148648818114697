import { useState } from 'react';
import { Loader } from 'components';
import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import { MenuItem, Avatar } from '@mui/material';
import { stringAvatar } from 'utils/string-avatar';
import { IntegrationByFilter } from 'library/request/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  selectRows,
  selectSelectedCompanyIntegrationId,
} from 'store/features/drawer-integrations/drawer-integrations.selector';
import { selectIsLoading, selectUsersMap } from 'store/features/users/users.selector';
import { setRows } from 'store/features/drawer-integrations/drawer-integrations.slice';

const avatarStyleObj = {
  height: '18px',
  width: '18px',
  fontSize: '8px',
  marginRight: '5px',
  display: 'inline-block',
  overflow: 'none',
  textAlign: 'center',
  lineHeight: '2.25',
};

const { assignToUser } = request;

type ChangeUserProps = {
  role: string;
};

const ChangeUser = ({ role }: ChangeUserProps) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersMap);
  const loading = useAppSelector(selectIsLoading);

  const rows = useAppSelector(selectRows) as IntegrationByFilter[];
  const id = useAppSelector(selectSelectedCompanyIntegrationId) as number;
  const restRows = rows.filter((row) => row.id !== id);
  const [selectedRow] = rows.filter((row) => row.id === id);

  const [newUser, setNewUser] = useState<string>(selectedRow.assignedUserName);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedUserName = event.target.value as string;
    assignToUser({ companyIntegrationId: selectedRow.id, userName: selectedUserName }).then((res) => {
      setNewUser(res.assignedUserName);
      dispatch(setRows([...restRows, { ...selectedRow, assignedUserName: res.assignedUserName }]));
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <Select
      sx={{ height: '30px' }}
      size="small"
      fullWidth
      readOnly={role === ROLE_API_NAME_ENUM.CUSTOMER}
      IconComponent={
        role === ROLE_API_NAME_ENUM.CUSTOMER
          ? () => (
              <ArrowDropDownIcon
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: role === ROLE_API_NAME_ENUM.CUSTOMER ? 'none' : 'block',
                }}
              />
            )
          : undefined
      }
      variant="standard"
      id="demo-simple-select-change-user"
      value={newUser}
      defaultValue={selectedRow.assignedUserName}
      onChange={handleChange}>
      {users.map((user, index) => (
        <MenuItem
          sx={{ display: 'flex', alignItems: 'center', height: '50px' }}
          key={index.toString()}
          value={user.value}>
          <div style={{ display: 'flex', height: 'auto' }}>
            <Avatar component="span" {...stringAvatar(user.label, avatarStyleObj)} />
            <span style={{ display: 'inline-flex', height: 'auto', overflow: 'hidden' }}>{user.label}</span>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChangeUser;
