import { useState } from 'react';
import { Loader } from 'components';
import request from 'library/request';
import { useAppSelector } from 'store/hooks';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { IntegrationByFilter } from 'library/request/types';
import { STATUS_TYPE_ENUM, getStatusTranslated } from 'enums';
import { selectSelectedIntegration } from 'store/features/drawer-solutions/drawer-solutions.selector';

const { changeStatus } = request;

const SolutionsChangeStatus = () => {
  const selectedIntegration = useAppSelector(selectSelectedIntegration) as IntegrationByFilter;

  const [newStatus, setNewStatus] = useState(String(selectedIntegration.statusType));
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setLoading(true);

    const status = event.target.value;
    changeStatus({ companyIntegrationId: selectedIntegration.id, statusType: Number(status) }).then((res) => {
      setNewStatus(status);
      setLoading(false);
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <Select
      sx={{ height: '30px' }}
      variant="standard"
      size="small"
      fullWidth
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={newStatus}
      defaultValue={String(selectedIntegration.statusType)}
      onChange={handleChange}>
      {(Object.keys(STATUS_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof STATUS_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={STATUS_TYPE_ENUM[key]}>
            {getStatusTranslated(STATUS_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default SolutionsChangeStatus;
