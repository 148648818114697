import { styled } from 'styled-components';

export const CompanySelectButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > p {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    text-align: center;
    padding: 0;
    margin: 0;
    color: #000000de !important;
  }

  abbr[title] {
    text-decoration: none;
  }
`;
