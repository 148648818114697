import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { EditMyProfileFormParams } from 'pages/my-profile-edit';

type EditMyProfileTextfieldNameProps = {
  onChange: Dispatch<SetStateAction<EditMyProfileFormParams>>;
  formParams: EditMyProfileFormParams;
  error: boolean;
};

const EditMyProfileTextfieldName = ({ onChange, formParams, error }: EditMyProfileTextfieldNameProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    onChange((prevState: EditMyProfileFormParams) => ({ ...prevState, name }));
  };

  return (
    <TextField
      fullWidth
      size="small"
      sx={
        error && !formParams.name
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.name}
      id="outlined-basic-name"
      variant="outlined"
    />
  );
};

export default EditMyProfileTextfieldName;
