import request from 'library/request';
import { StyledPaper } from './styles';
import { Box, Grid } from '@mui/material';
import { ROLE_API_NAME_ENUM } from 'enums';
import {
  AddErrorCodeTextfieldCode,
  AddErrorCodeSelectPriority,
  AddErrorCodeTextfieldDescription,
} from 'containers/elements/error-codes-add';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Card, Widget, Text } from 'components';
import Authentication from 'utils/authentication';
import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from 'containers/elements/@commons';
import FormButtons from 'containers/elements/@commons/form-buttons';
import errorCodesActions from 'store/features/error-codes/error-codes.actions';

const { createErrorCode } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Hata Kodları',
    path: '/error-codes',
  },
  {
    name: 'Hata Kodu Ekle',
    path: '/error-codes/add',
  },
];

export type AddErrorCodeFormParams = {
  code: string;
  description: string;
  alertPriority: number;
};

const ErrorCodesAdd = () => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);
  const goToMainPage = useCallback(() => navigate('/'), []);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formParams, setFormParams] = useState<AddErrorCodeFormParams>({
    code: '',
    description: '',
    alertPriority: 1,
  });

  const onClear = () => {
    setFormParams({
      code: '',
      description: '',
      alertPriority: 1,
    });
  };

  const handleCancel = () => {
    onClear();
    goBack();
  };

  const handleSubmit = () => {
    if (formParams.code === '' || formParams.description === '') {
      setErrorMessage(
        formParams.code === ''
          ? 'Hata Kodu boş geçilemez.'
          : formParams.description === ''
          ? 'Açıklama boş geçilemez.'
          : ''
      );
      return setError(true);
    }

    setLoading(true);

    (async () => {
      try {
        const response = await createErrorCode(formParams);
        setLoading(false);

        if (
          response.message === 'Bazı hatalar oluştu' &&
          response.validationErrors?.[0].errorMessage === 'Kod daha önce kullanılmış'
        ) {
          setErrorMessage('Kod daha önce kullanılmış');
          setError(true);
          return;
        }

        dispatch(errorCodesActions.getCodes());
        handleCancel();
      } catch (error: any) {
        setLoading(false);
        if (error.message === 'Network Error') {
          setErrorMessage('Sunucuya bağlanılamadı.');
          setError(true);
        }
      }
    })();
  };

  useEffect(() => {
    if (loggedUser.role !== ROLE_API_NAME_ENUM.ADMIN) {
      goToMainPage();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [formParams]);

  return (
    <>
      <Widget>
        <Breadcrumb title="Hata Kodu Ekle" breadcrumbs={breadcrumbs} />
      </Widget>
      <Widget>
        <Card>
          <Box sx={{ fontFamily: "'Open Sans', sans-serif" }}>
            <Grid container spacing={1}>
              {error && (
                <Grid container item>
                  <Grid item xs={12} md={12}>
                    <Text style={{ color: '#c52f2f', textAlign: 'center' }}>{errorMessage}</Text>
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} md={6}>
                  <span>Kod</span>
                  <StyledPaper>
                    <AddErrorCodeTextfieldCode onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span>Öncelik</span>
                  <StyledPaper>
                    <AddErrorCodeSelectPriority onChange={setFormParams} formParams={formParams} />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={12}>
                  <span>Açıklama</span>
                  <StyledPaper>
                    <AddErrorCodeTextfieldDescription onChange={setFormParams} formParams={formParams} error={error} />
                  </StyledPaper>
                </Grid>
              </Grid>
              <FormButtons
                loading={loading}
                actionName={'Oluştur'}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Box>
        </Card>
      </Widget>
    </>
  );
};

export default ErrorCodesAdd;
