import * as Styles from './styles';
import { Loader } from 'components';
import request from 'library/request';
import { useAppSelector } from 'store/hooks';
import Authentication from 'utils/authentication';
import { renderFormattedTime } from 'utils/format-time';
import { Grid, Paper, Tooltip, Box } from '@mui/material';
import { CommentBlock } from 'containers/elements/@commons';
import { IntegrationByFilter } from 'library/request/types';
import { renderFormattedPriority } from 'utils/format-priority';
import { ChangeEvent, useState, Dispatch, SetStateAction } from 'react';
import { ChangeActivity, ChangeStatus, ChangeUser } from './components';
import { selectLoadingUpdate } from 'store/features/drawer/drawer.selector';

const { commentOut } = request;

type DrawerContentProps = {
  loadingClickedIntegration: boolean;
  clickedIntegration: IntegrationByFilter | null;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

const DrawerContent = ({
  loadingClickedIntegration: isLoading,
  clickedIntegration: selectedIntegration,
  setIsDrawerOpen,
}: DrawerContentProps) => {
  const loggedUser = Authentication.getLoggedUser();

  const loadingUpdate = useAppSelector(selectLoadingUpdate);

  const [comment, setComment] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const disabled = comment === '';

  const handleSubmit = () => {
    if (!selectedIntegration) return;

    setLoadingSubmit(true);
    commentOut({ companyIntegrationId: selectedIntegration.id, description: comment }).then((res) => {
      setComment('');
      setLoadingSubmit(false);
    });
  };

  if (!selectedIntegration) {
    setIsDrawerOpen(false);
    return <></>;
  }

  return (
    <Box
      sx={{ width: { md: '50vw', xs: '75vw' }, height: '99vh', paddingLeft: '2px' }}
      role="presentation"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setIsDrawerOpen(false);
        }
      }}>
      {!selectedIntegration || isLoading ? (
        <Styles.LoaderContainer>
          <Loader />
        </Styles.LoaderContainer>
      ) : (
        <Grid container direction={'column'}>
          <Grid item xs={12} md={12}>
            <Paper>
              <Styles.HeaderContainer>
                <Styles.HeaderSpan>
                  {`${selectedIntegration.companyGroupName.toUpperCase()} - ${selectedIntegration.companyName.toUpperCase()} - ${
                    selectedIntegration.taxNumber
                  }`}
                </Styles.HeaderSpan>
              </Styles.HeaderContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper sx={{ width: '100%', margin: '10px 0px', paddingLeft: '20px' }}>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>UID:</Styles.DetailSpanTitleSpan>&nbsp;
                    {selectedIntegration.uid}
                  </Styles.DetailSpan>
                </Grid>

                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>Banka:</Styles.DetailSpanTitleSpan>&nbsp;
                    {`${selectedIntegration.bankName} - ${selectedIntegration.bankCode}`}
                  </Styles.DetailSpan>
                </Grid>

                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>Hata:</Styles.DetailSpanTitleSpan>&nbsp;
                    {selectedIntegration.errorCode ? (
                      <>
                        <Tooltip arrow followCursor placement="top" title={selectedIntegration.errorCodeDescription}>
                          <span>{selectedIntegration.errorCode}</span>
                        </Tooltip>
                        <span>&nbsp;-&nbsp;</span>
                        <span>{renderFormattedPriority(selectedIntegration.alertPriority)}</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </Styles.DetailSpan>
                </Grid>

                <Grid item xs={12}>
                  <Styles.DetailSpan>
                    <Styles.DetailSpanTitleSpan>Tarih:</Styles.DetailSpanTitleSpan>&nbsp;
                    {renderFormattedTime(selectedIntegration.errorDate)}
                  </Styles.DetailSpan>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Styles.FullMessageContainer>
              <Paper sx={{ marginBottom: '10px', paddingBottom: '5px' }}>
                <Styles.MessageContentContainer>
                  <Styles.MessageContent>
                    <Styles.DetailSpanTitleSpan>Açıklama:</Styles.DetailSpanTitleSpan>&nbsp;
                    {selectedIntegration.message}
                  </Styles.MessageContent>
                </Styles.MessageContentContainer>
              </Paper>
            </Styles.FullMessageContainer>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper sx={{ width: '100%', height: '16vh', margin: '10px 0px', padding: '0px 0px 10px 10px' }}>
              <Grid container spacing={1}>
                <Grid item container>
                  <Grid item xs={5} md={2}>
                    <Styles.OperationSpan>Kullanıcıya ata:</Styles.OperationSpan>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <ChangeUser selectedIntegration={selectedIntegration} role={loggedUser.role} />
                  </Grid>
                </Grid>

                <Grid item container>
                  <Grid item xs={5} md={2}>
                    <Styles.OperationSpan>Aktivite değiştir:</Styles.OperationSpan>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <ChangeActivity selectedIntegration={selectedIntegration} role={loggedUser.role} />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={5} md={2}>
                    <Styles.OperationSpan>Durum değiştir:</Styles.OperationSpan>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <ChangeStatus selectedIntegration={selectedIntegration} role={loggedUser.role} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper>
              <Styles.CommentsContainer>
                <CommentBlock
                  loadingSubmit={loadingSubmit}
                  loadingUpdate={loadingUpdate}
                  companyIntegrationId={selectedIntegration.id}
                />
              </Styles.CommentsContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            {loadingSubmit ? (
              <Styles.CommentOutContainer>
                <Loader />
              </Styles.CommentOutContainer>
            ) : (
              <Styles.CommentOutContainer>
                <Styles.InputContainer
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
                  placeholder="Yorum Ekle"
                />
                <Styles.ButtonContainer disabled={disabled} onClick={() => handleSubmit()} type="button">
                  Yorum Yap
                </Styles.ButtonContainer>
              </Styles.CommentOutContainer>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DrawerContent;
