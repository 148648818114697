import { TextField } from '@mui/material';
import { FilterParams } from 'pages/integrations';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

type TextfieldTicketIDProps = {
  onChange: Dispatch<SetStateAction<FilterParams>>;
  filterParams: FilterParams;
};

const TextfieldTicketID = ({ onChange, filterParams }: TextfieldTicketIDProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const ticketID = event.target.value;
    onChange((prevState: FilterParams) => ({ ...prevState, ticketID }));
  };
  return (
    <TextField
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      size="small"
      fullWidth
      onChange={handleChange}
      value={filterParams.ticketID}
      id="outlined-basic-ticket-id"
      label="Ticket ID"
      variant="outlined"
    />
  );
};

export default TextfieldTicketID;
