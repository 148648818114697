import { Dispatch, SetStateAction } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { EditGroupCompanyFormParams } from 'pages/group-companies-edit';

type EditGroupCompanyCheckboxSendAutoEmailProps = {
  onChange: Dispatch<SetStateAction<EditGroupCompanyFormParams>>;
  formParams: EditGroupCompanyFormParams;
};

const EditGroupCompanyCheckboxSendAutoEmail = ({
  onChange,
  formParams,
}: EditGroupCompanyCheckboxSendAutoEmailProps) => {
  const handleChange = (event: any) => {
    const sendAutoEmail = event.target.checked;
    onChange((prevState) => ({ ...prevState, sendAutoEmail }));
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      labelPlacement="end"
      label="Belirlenen adreslere otomatik olarak e-posta gönderilsin."
      checked={formParams.sendAutoEmail}
      onChange={handleChange}
    />
  );
};

export default EditGroupCompanyCheckboxSendAutoEmail;
