import { DrawerState } from './drawer.types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: DrawerState = {
  isDrawerOpen: false,
  loadingUpdate: false,
};

const drawerSlice = createSlice({
  initialState,
  name: 'drawer',
  reducers: {
    setIsDrawerOpen(state, { payload }) {
      state.isDrawerOpen = payload;
    },

    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
  },
});

export const { setIsDrawerOpen, setLoadingUpdate } = drawerSlice.actions;

export default drawerSlice.reducer;
