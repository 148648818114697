import { useState } from 'react';
import { Loader } from 'components';
import request from 'library/request';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { IntegrationByFilter } from 'library/request/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ROLE_API_NAME_ENUM, STATUS_TYPE_ENUM, getStatusTranslated } from 'enums';

const { changeStatus } = request;

type ChangeStatusProps = {
  selectedIntegration: IntegrationByFilter;
  role: string;
};

const ChangeStatus = ({ selectedIntegration, role }: ChangeStatusProps) => {
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(String(selectedIntegration.statusType));

  const handleChange = (event: SelectChangeEvent) => {
    setLoading(true);

    const status = event.target.value;
    changeStatus({ companyIntegrationId: selectedIntegration.id, statusType: Number(status) }).then((res) => {
      setNewStatus(status);
      setLoading(false);
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <Select
      sx={{ height: '30px' }}
      variant="standard"
      size="small"
      fullWidth
      readOnly={role === ROLE_API_NAME_ENUM.CUSTOMER}
      IconComponent={
        role === ROLE_API_NAME_ENUM.CUSTOMER
          ? () => (
              <ArrowDropDownIcon
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: role === ROLE_API_NAME_ENUM.CUSTOMER ? 'none' : 'block',
                }}
              />
            )
          : undefined
      }
      labelId="demo-simple-select-label-change-status"
      id="demo-simple-select-change-status"
      value={newStatus}
      defaultValue={String(selectedIntegration.statusType)}
      onChange={handleChange}>
      {(Object.keys(STATUS_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof STATUS_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={STATUS_TYPE_ENUM[key]}>
            {getStatusTranslated(STATUS_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default ChangeStatus;
