import { Comment } from '..';
import request from 'library/request';
import { useState, useEffect } from 'react';
import { ErrorComment } from 'library/request/types';
import { StyledList, Text, TextContainer } from './styles';

type CommentBlockProps = {
  loadingSubmit: boolean;
  loadingUpdate: boolean;
  companyIntegrationId: number;
};

const { getCommentsByErrorId } = request;

const CommentBlock = ({ companyIntegrationId, loadingSubmit, loadingUpdate }: CommentBlockProps) => {
  const [totalCount, setTotalCount] = useState(0);
  const [comments, setComments] = useState<ErrorComment[]>([]);

  useEffect(() => {
    getCommentsByErrorId({ pageNumber: 1, pageSize: 100, companyIntegrationId }).then((res) => {
      setTotalCount(res.totalCount);
      setComments(res.result);
    });
  }, [loadingSubmit, loadingUpdate]);

  return (
    <StyledList>
      {totalCount > 0 &&
        comments.map((comment, index) => (
          <Comment key={index.toString()} comment={comment} totalCount={totalCount} index={index} />
        ))}
      {!totalCount && (
        <TextContainer>
          <Text>Yorum bulunmamaktadır.</Text>
        </TextContainer>
      )}
    </StyledList>
  );
};

export default CommentBlock;
