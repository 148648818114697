import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { EditMyProfileFormParams } from 'pages/my-profile-edit';

type EditMyProfileTextfieldTelegramUserNameProps = {
  onChange: Dispatch<SetStateAction<EditMyProfileFormParams>>;
  formParams: EditMyProfileFormParams;
  error: boolean;
};

const EditMyProfileTextfieldTelegramUserName = ({
  onChange,
  formParams,
  error,
}: EditMyProfileTextfieldTelegramUserNameProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const telegramUserName = event.target.value;
    onChange((prevState: EditMyProfileFormParams) => ({ ...prevState, telegramUserName }));
  };

  return (
    <TextField
      size="small"
      fullWidth
      sx={
        error && formParams.useTelegram && !formParams.telegramUserName
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.telegramUserName}
      id="outlined-basic-telegram-user-name"
      variant="outlined"
    />
  );
};

export default EditMyProfileTextfieldTelegramUserName;
