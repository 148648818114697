import {
  additionalRowStyle,
  OperationsContainer,
  additionalButtonStyle,
  additionalWidgetStyle,
  additionalContainerStyle,
  additionalMainContainerStyle,
} from './styles';
import {
  SelectUser,
  SelectBank,
  SelectStatus,
  SelectActivity,
  SelectPriority,
  SelectErrorCodes,
  DatepickerEndDate,
  TextfieldTicketID,
  DatepickerStartDate,
  SelectIntegrationStatus,
} from './components';
import { Box, Grid, Paper } from '@mui/material';
import { SetStateAction, Dispatch } from 'react';
import { FilterParams } from 'pages/integrations';
import { Card, Widget, Button } from 'components';

type FiltersProps = {
  onClear: () => void;
  onApplyFilter: () => void;
  filterParams: FilterParams;
  setFilterParams: Dispatch<SetStateAction<FilterParams>>;
};

const Filters = ({ filterParams, setFilterParams, onClear, onApplyFilter }: FiltersProps) => {
  return (
    <>
      <Widget $additionalStyle={additionalWidgetStyle} $additionalRowStyle={additionalRowStyle}>
        <Card $additionalContainerStyle={additionalMainContainerStyle}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <DatepickerStartDate onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <DatepickerEndDate onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectIntegrationStatus onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectErrorCodes onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectPriority onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectBank onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectUser onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectStatus onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <SelectActivity onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12 / 5}>
                <Paper sx={{ borderRadius: '8px' }}>
                  <TextfieldTicketID onChange={setFilterParams} filterParams={filterParams} />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Widget>
      <Card $additionalContainerStyle={additionalContainerStyle}>
        <OperationsContainer>
          <Button
            onClick={onClear}
            $additionalStyle={additionalButtonStyle}
            style={{
              width: '100px',
              fontSize: '12px',
              backgroundColor: 'inherit',
              marginLeft: '10px',
            }}>
            Temizle
          </Button>
          <Button
            $additionalStyle={additionalButtonStyle}
            style={{
              backgroundColor: '#0f62fe',
              color: '#fff',
              width: '100px',
              marginRight: '10px',
            }}
            onClick={() => {
              onApplyFilter();
            }}>
            Filtrele
          </Button>
        </OperationsContainer>
      </Card>
    </>
  );
};

export default Filters;
