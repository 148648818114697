import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { AddUserFormParams } from 'pages/users-add';
import { SelectChangeEvent } from '@mui/material/Select';
import { ROLE_TYPE_ENUM, getRoleTranslated } from 'enums';

type AddUserSelectRoleTypeProps = {
  onChange: Dispatch<SetStateAction<AddUserFormParams>>;
  formParams: AddUserFormParams;
};

const AddUserSelectRoleType = ({ onChange, formParams }: AddUserSelectRoleTypeProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const roleType = Number(event.target.value);
    onChange((prevState: AddUserFormParams) => ({ ...prevState, roleType }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-role-type-dialog-label"
      id="select-role-type-dialog"
      value={String(formParams.roleType)}
      defaultValue={String(formParams.roleType)}
      onChange={handleChange}>
      {(Object.keys(ROLE_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof ROLE_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={ROLE_TYPE_ENUM[key]}>
            {getRoleTranslated(ROLE_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default AddUserSelectRoleType;
