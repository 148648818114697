import { ReactNode } from 'react';
import { styled } from 'styled-components';
import { AddGroupCompanySvg } from 'assets/icons';
import { GROUP_COMPANIES_ICONS_ENUM } from 'enums';

export const AddGroupCompanyIcon = styled(AddGroupCompanySvg)`
  height: 24px;
  width: 24px;
`;

type ContainerProps = {
  $pl?: number;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 10px;
  padding-left: ${({ $pl }) => ($pl ? `${$pl}px` : '0px')};

  @media (max-width: 500px) {
    padding-left: 0px;
    padding-right: 40px;
  }
`;

export const renderFormattedIconCompany = (label: string, userName: string, paddingLeft: number = 0): ReactNode => {
  const index = GROUP_COMPANIES_ICONS_ENUM.findIndex((groupCompany) => groupCompany.userName === userName);

  if (index === -1)
    return (
      <Container $pl={paddingLeft}>
        <AddGroupCompanyIcon />
        {label}
      </Container>
    );

  return (
    <Container $pl={paddingLeft}>
      {GROUP_COMPANIES_ICONS_ENUM[index].icon}
      {label}
    </Container>
  );
};
