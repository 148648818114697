import { Loader } from 'components';
import { Button, Grid } from '@mui/material';

type FormButtonsProps = {
  loading: boolean;
  actionName: string;
  handleCancel: () => void;
  handleSubmit: () => void;
};

const FormButtons = ({ loading, actionName, handleCancel, handleSubmit }: FormButtonsProps) => {
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12} md={10}></Grid>
      <Grid item xs={12} md={1}>
        <Button fullWidth sx={{ color: '#1976d2', fontFamily: '"Open Sans", sans-serif' }} onClick={handleCancel}>
          Vazgeç
        </Button>
      </Grid>
      <Grid item xs={12} md={1}>
        {loading ? (
          <Loader />
        ) : (
          <Button fullWidth sx={{ fontFamily: '"Open Sans", sans-serif' }} variant="contained" onClick={handleSubmit}>
            {actionName}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default FormButtons;
