import { RuleSet, css, styled } from 'styled-components';

export const SelectItemTextSpan = styled.span`
  padding: 8px;
  color: #1e1e1e;
`;

type SelectItemContainerProps = {
  $isActive?: boolean;
  $additionalStyle?: RuleSet;
};

const isActiveStyle = css`
  > span {
    background-color: #0f62fe !important;
    border-radius: 4px;
    color: white;
  }
`;

export const SelectItemContainer = styled.li<SelectItemContainerProps>`
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;

  ${({ $isActive }) => $isActive && isActiveStyle}
  ${({ $additionalStyle }) => $additionalStyle && $additionalStyle}
`;
