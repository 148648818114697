import { Route, Routes } from 'react-router-dom';
import { UsersAdd, UsersEdit, UsersMain } from '..';

const Users = () => {
  return (
    <Routes>
      <Route index element={<UsersMain />} />
      <Route path="add" element={<UsersAdd />} />
      <Route path="edit" element={<UsersEdit />} />
    </Routes>
  );
};

export default Users;
