import { RuleSet, styled } from 'styled-components';

type LogoContainerProps = {
  $additionalStyle?: RuleSet;
};

export const LogoContainer = styled.div<LogoContainerProps>`
  img {
    height: 38px;

    @media (max-width: 500px) {
      height: 30px;
    }
  }

  ${({ $additionalStyle }) => $additionalStyle && $additionalStyle}
`;
