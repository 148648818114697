import { styled } from 'styled-components';
import { bgContainer } from 'assets/style-helpers/colors';

export const GeneralContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${bgContainer};
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1640px;
  margin: 0 auto;
`;
