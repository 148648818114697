import { RootState } from '../../root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { UpdateErrorCodeState } from './update-error-code.types';

const selectUpdateErrorCodeReducer = (state: RootState): UpdateErrorCodeState => state.updateErrorCode;

export const selectCode = createSelector([selectUpdateErrorCodeReducer], (updateErrorCode) => updateErrorCode.code);

export const selectErrorCode = createSelector(
  [selectUpdateErrorCodeReducer],
  (updateErrorCode) => updateErrorCode.errorCode
);

export const selectErrorUpdate = createSelector(
  [selectUpdateErrorCodeReducer],
  (updateErrorCode) => updateErrorCode.errorUpdate
);

export const selectLoadingUpdate = createSelector(
  [selectUpdateErrorCodeReducer],
  (updateErrorCode) => updateErrorCode.loadingUpdate
);

export const selectIsDeletePopUpOpen = createSelector(
  [selectUpdateErrorCodeReducer],
  (updateErrorCode) => updateErrorCode.isPopUpOpen
);
