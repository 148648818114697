import {
  EditIcon,
  DeleteIcon,
  StyledCheckbox,
  EditIconContainer,
  StyledListItemText,
  DeleteIconContainer,
} from './styles';
import { Loader } from 'components';
import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useState, ChangeEvent } from 'react';
import Authentication from 'utils/authentication';
import { stringAvatar } from 'utils/string-avatar';
import { ErrorComment } from 'library/request/types';
import { renderFormattedTime } from 'utils/format-time';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUsersMap } from 'store/features/users/users.selector';
import { setLoadingUpdate } from 'store/features/drawer/drawer.slice';
import { ListItem, Divider, Tooltip, Avatar, TextField, Button } from '@mui/material';

const avatarStyleObj = {
  width: '35px',
  height: '35px',
  overflow: 'none',
  fontSize: '12px',
  marginTop: '5px',
  lineHeight: '1.8',
  marginRight: '5px',
  textAlign: 'center',
};

type CommentProps = {
  index: number;
  totalCount: number;
  comment: ErrorComment;
};

const { deleteComment, updateComment } = request;

const Comment = ({ comment, totalCount, index }: CommentProps) => {
  const loggedUser = Authentication.getLoggedUser();

  const dispatch = useAppDispatch();
  const usersMap = useAppSelector(selectUsersMap);

  const [loading, setLoading] = useState(false);
  const [loadingTop, setLoadingTop] = useState(false);
  const [isOpenEditBox, setIsOpenEditBox] = useState(false);
  const [checked, setChecked] = useState(comment.markAsSolution);
  const [newComment, setNewComment] = useState(comment.description);
  const [date, setDate] = useState(
    comment.updatedDate
      ? renderFormattedTime(comment.updatedDate)
      : comment.createdDate
      ? renderFormattedTime(comment.createdDate)
      : ''
  );

  const title = checked ? 'Çözüm olmaktan çıkar' : 'Çözüm olarak işaretle';
  const [user] = usersMap.filter((user) => user.value === comment.userName);

  const handleDeleteClick = () => {
    setLoading(true);
    dispatch(setLoadingUpdate(true));
    deleteComment({ id: comment.id }).then((res) => {
      setLoading(false);
      dispatch(setLoadingUpdate(false));
    });
  };

  const handleSubmit = () => {
    setLoadingTop(true);
    updateComment({ id: comment.id, description: newComment, markAsSolution: comment.markAsSolution }).then((res) => {
      res.updatedDate && setDate(renderFormattedTime(res.updatedDate));
      setIsOpenEditBox(false);
      setLoadingTop(false);
    });
  };

  const handelCancel = () => {
    setNewComment(comment.description);
    setIsOpenEditBox(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    updateComment({ id: comment.id, description: comment.description, markAsSolution: event.target.checked }).then(
      (res) => {
        setChecked(res.markAsSolution);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <Avatar component="span" {...stringAvatar(user?.label, avatarStyleObj)} />
        {loadingTop ? (
          <Loader />
        ) : isOpenEditBox ? (
          <StyledListItemText
            primary={`${user?.label} / ${date}`}
            secondary={
              <>
                <TextField
                  size="small"
                  multiline={true}
                  fullWidth
                  onChange={(e) => setNewComment(e.target.value)}
                  value={newComment}
                  id="outlined-basic"
                  variant="outlined"
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                  <Button size="small" sx={{ color: '#1976d2', marginRight: '10px' }} onClick={handelCancel}>
                    Vazgeç
                  </Button>
                  <Button size="small" variant="contained" onClick={handleSubmit}>
                    Gönder
                  </Button>
                </div>
              </>
            }
          />
        ) : (
          <StyledListItemText primary={`${user?.label} / ${date}`} secondary={newComment} />
        )}
      </ListItem>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {user.value === loggedUser.value && !isOpenEditBox && (
              <>
                <DeleteIconContainer onClick={handleDeleteClick}>
                  <Tooltip placement="top" title="Sil" arrow>
                    <DeleteIcon />
                  </Tooltip>
                </DeleteIconContainer>
                <EditIconContainer onClick={() => setIsOpenEditBox(true)}>
                  <Tooltip placement="top" title="Düzenle" arrow>
                    <EditIcon />
                  </Tooltip>
                </EditIconContainer>
              </>
            )}
            {!isOpenEditBox && loggedUser.role !== ROLE_API_NAME_ENUM.CUSTOMER && (
              <Tooltip placement="top" title={title} arrow>
                <StyledCheckbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
              </Tooltip>
            )}
          </>
        )}
      </div>
      {totalCount > index + 1 && <Divider variant="fullWidth" component="li" />}
    </>
  );
};

export default Comment;
