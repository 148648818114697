import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Card, Loader, Widget } from 'components';
import { ErrorCode } from 'library/request/types';
import Authentication from 'utils/authentication';
import { useEffect, useState, useCallback } from 'react';
import { sidebarItems } from 'containers/layout/sidebar';
import { Breadcrumb } from 'containers/elements/@commons';
import { AddErrorCodeIcon, RightItemContainer, TextSpan } from './styles';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { selectLoadingUpdate } from 'store/features/update-error-code/update-error-code.selector';
import { ErrorCodesDeletePopUp, ErrorCodesFilters, ErrorCodesTable } from 'containers/elements/error-codes';

const { getErrorCodeMap } = request;

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Hata Kodları',
    path: '/error-codes',
  },
];

export type ErrorCodesFilterParams = {
  code: string;
  pageSize: number;
  pageNumber: number;
  description: string;
  alertPriority: string | number | null;
};

const ErrorCodesMain = () => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const goToMainPage = useCallback(() => navigate('/'), []);
  const goToAddErrorCodeHandler = useCallback(() => navigate('add'), []);

  const loadingUpdate = useAppSelector(selectLoadingUpdate);

  const [loading, setLoading] = useState(false);
  const [totalErrorCode, setTotalErrorCode] = useState(0);
  const [errorCodeList, setErrorCodeList] = useState<ErrorCode[]>([]);
  const [filterParams, setFilterParams] = useState<ErrorCodesFilterParams>({
    code: '',
    pageSize: 20,
    pageNumber: 1,
    description: '',
    alertPriority: null,
  });

  const onClear = () => {
    setFilterParams({
      code: '',
      pageSize: 20,
      pageNumber: 1,
      description: '',
      alertPriority: null,
    });
  };

  const onApplyFilter = () => {
    setLoading(true);

    (async () => {
      const response = await getErrorCodeMap(filterParams);
      setTotalErrorCode(response.totalCount);
      setErrorCodeList(response.result);
      setLoading(false);
    })();
  };

  const onApplyPaginationFilter = ({ pageSize, pageNumber }: PaginationFilterParams) => {
    setLoading(true);

    (async () => {
      const response = await getErrorCodeMap({ ...filterParams, pageSize, pageNumber });
      setTotalErrorCode(response.totalCount);
      setErrorCodeList(response.result);
      setLoading(false);
    })();
  };

  useEffect(() => {
    setLoading(true);

    getErrorCodeMap(filterParams).then((res) => {
      setTotalErrorCode(res.totalCount);
      setErrorCodeList(res.result);
      setLoading(false);
    });
  }, [loadingUpdate]);

  useEffect(() => {
    const sidebarItem = sidebarItems.find((sidebarItem) => sidebarItem.route === 'error-codes');
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser?.role);

    if (!hasPermission) {
      goToMainPage();
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb title="Hata Kodları" breadcrumbs={breadcrumbs} />
      </Widget>
      <ErrorCodesFilters
        onClear={onClear}
        filterParams={filterParams}
        onApplyFilter={onApplyFilter}
        setFilterParams={setFilterParams}
      />
      <Widget>
        {loading ? (
          <Card>
            <Loader />
          </Card>
        ) : (
          <Card
            leftItem={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? <div /> : null}
            rightItem={
              loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? (
                <RightItemContainer onClick={goToAddErrorCodeHandler}>
                  <AddErrorCodeIcon />
                  <TextSpan>Hata Kodu Ekle</TextSpan>
                </RightItemContainer>
              ) : null
            }>
            <ErrorCodesTable
              filterParams={filterParams}
              errorCodeList={errorCodeList}
              totalErrorCode={totalErrorCode}
              setFilterParams={setFilterParams}
              onApplyPaginationFilter={onApplyPaginationFilter}
            />
          </Card>
        )}
      </Widget>
      {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && <ErrorCodesDeletePopUp />}
    </>
  );
};

export default ErrorCodesMain;
