import { Widget } from 'components';
import { Breadcrumb } from 'containers/elements/@commons';

const breadcrumbs = [
  {
    name: 'Ana Sayfa',
    path: '/dashboard',
  },
  {
    name: 'Ayarlar',
    path: '/settings',
  },
];

const Settings = () => {
  return (
    <Widget>
      <Breadcrumb title="Ayarlar" breadcrumbs={breadcrumbs} />
    </Widget>
  );
};

export default Settings;
