import TextField from '@mui/material/TextField';
import { EditUserFormParams } from 'pages/users-edit';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

type EditUserTextfieldPasswordProps = {
  onChange: Dispatch<SetStateAction<EditUserFormParams>>;
  formParams: EditUserFormParams;
};

const EditUserTextfieldPassword = ({ onChange, formParams }: EditUserTextfieldPasswordProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    onChange((prevState: EditUserFormParams) => ({ ...prevState, password }));
  };
  return (
    <TextField
      size="small"
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      type="password"
      fullWidth
      onChange={handleChange}
      value={formParams.password}
      id="outlined-basic-password"
      variant="outlined"
    />
  );
};

export default EditUserTextfieldPassword;
