import { User, UsersMap } from '../request/types';

export const formatUsers = (usersMap: UsersMap): UsersMap => {
  const formattedResult: User[] = usersMap.result.map((user) => {
    const label = `${user.name.charAt(0).toUpperCase() + user.name.slice(1)} ${
      user.surname.charAt(0).toUpperCase() + user.surname.slice(1)
    }`;

    return { ...user, label };
  });

  return { ...usersMap, result: formattedResult };
};
