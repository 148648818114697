import { RuleSet, styled } from 'styled-components';

type WidgetContainerProps = {
  $additionalStyle?: RuleSet;
};

export const WidgetTitleContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 15px 0px 15px 0px;
  color: #000000de;

  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const WidgetContainer = styled.div<WidgetContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 275px;
  margin: 0 auto;
  margin-bottom: 16px;

  ${({ $additionalStyle }) => $additionalStyle && $additionalStyle}

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

type RowOneProps = {
  $additionalRowStyle?: RuleSet;
};

export const RowOne = styled.div<RowOneProps>`
  width: 100%;

  @media (min-width: 990px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  /* [data-tag='card'] { */
  flex: 1 1 100%;
  margin-bottom: 1.2%;
  max-height: 40%;
  min-height: 100%;

  @media (max-width: 767px) {
    display: block !important;
    flex: 1 1 100% !important;
    margin-bottom: 1.6% !important;
    max-width: 100% !important;
  }

  @media (max-width: 414px) {
    width: -webkit-fill-available;
    display: block !important;
    /* } */
  }
  ${({ $additionalRowStyle }) => $additionalRowStyle && $additionalRowStyle}
`;

export const RowTwo = styled(RowOne)`
  /* [data-tag='card'] { */
  flex: 1 1 49% !important;
  max-width: 49% !important;
  margin-right: 2% !important;
  margin-bottom: 2.85% !important;
  padding: 0px;

  > div {
    padding: 16px;
  }

  &:nth-child(even) {
    margin-right: 0px !important;
  }

  @media (max-width: 767px) {
    flex: 1 1 100% !important;
    margin-right: 0px !important;
    margin-bottom: 4.6% !important;
    max-width: 100% !important;
  }
`;

export const RowThree = styled(RowOne)`
  /* [data-tag='card'] { */
  flex: 1 1 32% !important;
  max-width: 32% !important;
  margin-right: 1.98% !important;
  margin-bottom: 2.85% !important;
  padding: 0px;

  > div {
    padding: 16px;
  }

  &:nth-child(3n) {
    margin-right: 0px !important;
  }

  @media (max-width: 767px) {
    flex: 1 1 100% !important;
    margin-right: 0px !important;
    max-width: 100% !important;
  }
  /* } */
`;

export const RowFour = styled(RowOne)`
  /* [data-tag='card'] { */
  flex: 1 1 24.2% !important;
  max-width: 24.2% !important;
  margin-right: 1.05% !important;
  margin-bottom: 2.85% !important;
  padding: 0px;

  > div {
    padding: 16px;
  }

  &:nth-child(4n) {
    margin-right: 0px !important;
  }

  @media (max-width: 767px) {
    flex: 1 1 100% !important;
    margin-right: 0px !important;
    max-width: 100% !important;
    /* } */
  }
`;
