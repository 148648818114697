import { Dispatch, SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { CHANNEL_TYPE_ENUM, getChannelTranslated } from 'enums';
import { EditNotificationRuleFormParams } from 'pages/notification-panel-edit';

type EditNotificationRuleSelectChannelProps = {
  onChange: Dispatch<SetStateAction<EditNotificationRuleFormParams>>;
  formParams: EditNotificationRuleFormParams;
};

const EditNotificationRuleSelectChannel = ({ onChange, formParams }: EditNotificationRuleSelectChannelProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const notificationType = Number(event.target.value);
    onChange((prevState: EditNotificationRuleFormParams) => ({ ...prevState, notificationType }));
  };

  return (
    <Select
      fullWidth
      size="small"
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'medium',
      }}
      labelId="select-channel-dialog-label"
      id="select-channel-dialog"
      value={String(formParams.notificationType)}
      defaultValue={String(formParams.notificationType)}
      onChange={handleChange}>
      {(Object.keys(CHANNEL_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof CHANNEL_TYPE_ENUM)[]).map(
        (key, index) => (
          <MenuItem key={index.toString()} value={CHANNEL_TYPE_ENUM[key]}>
            {getChannelTranslated(CHANNEL_TYPE_ENUM[key])}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default EditNotificationRuleSelectChannel;
