import { styled } from 'styled-components';

type ContentContainerProps = {
  $pt?: string;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: row;

  margin: 0px 16px 16px 16px;

  box-sizing: border-box;

  padding-top: ${({ $pt }) => $pt || 0};

  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0px 0px 16px 0px;
  }
`;
