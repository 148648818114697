import * as Icons from 'assets/icons';
import styled, { css } from 'styled-components';

export const additionalInfoWidgetStyle = css`
  margin-bottom: 24px !important;
`;

export const GroupCompaniesIcon = styled(Icons.GroupCompaniesSvg)`
  font-size: 24px !important;
  color: #000000;
`;

export const CompaniesIcon = styled(Icons.AddGroupCompanySvg)`
  font-size: 24px !important;
  color: #000000;
`;

export const SuccessIntegrationsIcon = styled(Icons.SuccessIntegrationsSvg)`
  font-size: 24px !important;
  color: #000000;
`;

export const FailedIntegrationsIcon = styled(Icons.FailedIntegrationsSvg)`
  font-size: 24px !important;
  color: #000000;
`;
