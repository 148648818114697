import {
  Box,
  Grid,
  Paper,
  Table,
  Button,
  Tooltip,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Autocomplete,
  TableContainer,
} from '@mui/material';
import request from 'library/request';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TelegramNotificationModel, User } from 'library/request/types';
import { AddNotificationRuleFormParams } from 'pages/notification-panel-add';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

const { getUsersMap } = request;

const initialRow = {
  order: 1,
  telegramUserName: '',
  telegramUserId: '',
};

type TelegramFormProps = {
  setFormParams: Dispatch<SetStateAction<AddNotificationRuleFormParams>>;
  error: boolean;
};

const TelegramForm = ({ setFormParams, error }: TelegramFormProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([{ ...initialRow }]);
  const [telegramAddresses, setTelegramAddresses] = useState<User[]>([]);

  const addRow = () => {
    setRows([...rows, { ...initialRow, order: rows?.length + 1 }]);
  };

  const deleteSelectedRows = () => {
    const updatedRows = rows.filter((_, index) => !selectedRows.includes(index));

    // Reassign order values to the remaining rows
    const reorderedRows = updatedRows.map((row, index) => ({
      ...row,
      order: index + 1,
    }));

    setRows(reorderedRows);
    setSelectedRows([]);
  };

  const toggleSelectedRow = (index: any) => {
    const isSelected = selectedRows.includes(index);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((row) => row !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;

    setRows(updatedRows);
  };

  useEffect(() => {
    const distinctedRows: TelegramNotificationModel[] = rows.map((row) => ({
      telegramUserName: row.telegramUserName,
      telegramUserId: row.telegramUserId,
    }));

    setFormParams((prevState) => ({ ...prevState, telegramNotificationModels: distinctedRows }));
  }, [rows]);

  useEffect(() => {
    getUsersMap({ pageNumber: 1, pageSize: 50 }).then((response) => {
      setUsers(response.result);
    });
  }, []);

  useEffect(() => {
    if (users.length && searchText) {
      const data = users.filter((user) => {
        const telegramUserNames = user.telegramUserName.toLowerCase();
        return telegramUserNames.includes(searchText.toLowerCase());
      });

      setTelegramAddresses(data);
    }

    if (users.length && !searchText) {
      setTelegramAddresses(users.filter((user) => user.telegramUserName));
    }
  }, [users, searchText]);

  return (
    <Grid container spacing={1} direction={'column'}>
      <Grid item container xs={12} alignItems="center" flexWrap={'nowrap'}>
        <Grid item container spacing={2} flexGrow={1} xs={12}>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              color="success"
              sx={{ marginLeft: 1, fontFamily: '"Open Sans", sans-serif' }}
              startIcon={<AddIcon />}
              onClick={addRow}>
              Satır Ekle
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{ fontFamily: '"Open Sans", sans-serif' }}
              startIcon={<DeleteIcon />}
              onClick={deleteSelectedRows}
              disabled={selectedRows.length === 0}>
              Satır Sil
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ overflow: 'auto', width: '100%' }}>
        <TableContainer component={Paper} sx={{ overflowX: 'visible', fontFamily: '"Open Sans", sans-serif' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={10} align="center">
                  {''}
                </TableCell>
                <TableCell sx={{ fontFamily: '"Open Sans", sans-serif' }} width={50} align="center">
                  Sıra No
                </TableCell>
                <TableCell sx={{ fontFamily: '"Open Sans", sans-serif' }} align="center">
                  Telegram Kullanıcı Adı
                </TableCell>
                <TableCell sx={{ fontFamily: '"Open Sans", sans-serif' }} align="center">
                  Telegram Kullanıcı Id
                  <Tooltip
                    placement="top"
                    sx={{ marginLeft: 2 }}
                    title="Kullanıcı Id Telegram üzerinden alınacaktır. https://t.me/userinfobot adresinde bulunan bota /start şeklinde mesaj gönderilerek alınacaktır!">
                    <InfoOutlinedIcon />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox checked={selectedRows.includes(index)} onChange={() => toggleSelectedRow(index)} />
                  </TableCell>
                  <TableCell align="center">{row.order}</TableCell>
                  <TableCell>
                    <Autocomplete
                      options={telegramAddresses}
                      freeSolo
                      inputValue={rows[index].telegramUserName}
                      getOptionLabel={(option) => {
                        return typeof option !== 'string' ? `${option.telegramUserName}` : option;
                      }}
                      onInputChange={(_, newInputValue) => {
                        const updatedRows = [...rows];
                        updatedRows[index] = {
                          ...updatedRows[index],
                          telegramUserName: newInputValue || '',
                        };
                        setRows(updatedRows);
                      }}
                      onChange={(_, item: any) => {
                        const updatedRows = [...rows];
                        updatedRows[index] = {
                          ...updatedRows[index],
                          telegramUserName: item?.telegramUserName || '',
                          telegramUserId: item?.telegramUserId || '',
                        };
                        setRows(updatedRows);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="text"
                          name="telegramUserName"
                          value={row.telegramUserName}
                          fullWidth
                          onChange={(e) => setSearchText(e.target.value)}
                          variant="standard"
                          placeholder={'Telegram Kullanıcı Adı'}
                          error={error && !row.telegramUserName}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={`${option}-${Math.random() * Math.random()}`}>
                          {`${option.telegramUserName} ( ${option.label} )`}
                        </Box>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      name="telegramUserId"
                      value={row.telegramUserId}
                      fullWidth
                      onChange={(e) => handleChange(e, index)}
                      variant="standard"
                      placeholder={'Telegram Kullanıcı Id'}
                      error={error && !row.telegramUserId}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TelegramForm;
