import { TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { GroupCompaniesFilterParams } from 'pages/group-companies-main';

type GroupCompaniesTextfieldTitleProps = {
  onChange: Dispatch<SetStateAction<GroupCompaniesFilterParams>>;
  filterParams: GroupCompaniesFilterParams;
};

const GroupCompaniesTextfieldTitle = ({ onChange, filterParams }: GroupCompaniesTextfieldTitleProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const title = event.target.value;
    onChange((prevState: GroupCompaniesFilterParams) => ({ ...prevState, title }));
  };
  return (
    <TextField
      sx={{
        '& div': {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'medium',
        },
      }}
      size="small"
      fullWidth
      onChange={handleChange}
      value={filterParams.title}
      id="outlined-basic"
      label="Şirket Grubu"
      variant="outlined"
    />
  );
};

export default GroupCompaniesTextfieldTitle;
