import {
  ErrorTextContainer,
  LoginTextContainer,
  additionalLogoStyle,
  additionalButtonStyle,
  additionalWidgetStyle,
} from './styles';
import Page from 'containers/layout/page';
import Main from 'containers/layout/main';
import { ChangeEvent, useState } from 'react';
import Content from 'containers/layout/content';
import Authentication from 'utils/authentication';
import { Card, Logo, Text, Input, Button, Widget, Loader } from 'components';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    username: {
      value: '',
      error: false,
    },
    password: {
      value: '',
      error: false,
    },
  });

  const handleChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [key]: {
        value: e.target.value,
        error: false,
      },
    });
    setError(false);
  };

  const handleSubmit = async () => {
    const { username, password } = form;

    if (!username.value || !password.value || username.value === '' || password.value === '')
      return setForm({
        username: {
          value: username.value,
          error: !username.value,
        },
        password: {
          value: password.value,
          error: !password.value,
        },
      });

    setLoading(true);

    const data = await Authentication.logIn({
      userName: form.username.value,
      password: form.password.value,
    });

    setLoading(false);

    if (data?.message === 'Network Error') {
      setErrorMessage('Sunucuya bağlanılamadı.');
      setError(true);
    }

    if (data?.response?.data?.message === 'Kullanıcı adı veya parola hatalı') {
      setErrorMessage('Lütfen bilgilerinizi kontrol ediniz.');
      setError(true);
    }
  };

  return (
    <Page>
      <Content $pt="10%">
        <Main>
          <Widget $additionalStyle={additionalWidgetStyle} row={3}>
            <Card centerItem={<div style={{ height: '40px' }} />}>
              <Logo $additionalStyle={additionalLogoStyle} />
              {(form.username.error || form.password.error || error) && (
                <ErrorTextContainer>
                  <Text>{errorMessage}</Text>
                </ErrorTextContainer>
              )}
              <Input
                value={form.username.value}
                placeholder="Kullanıcı Adı"
                onChange={handleChange.bind(this, 'username')}
                $error={form.username.error}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') return handleSubmit();
                }}
              />
              <Input
                value={form.password.value}
                type="password"
                placeholder="Parola"
                onChange={handleChange.bind(this, 'password')}
                $error={form.password.error}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') return handleSubmit();
                }}
              />
              <br />
              <Button onClick={handleSubmit} $additionalStyle={additionalButtonStyle}>
                {loading ? (
                  <Loader $white={true} />
                ) : (
                  <LoginTextContainer>
                    <Text>Giriş Yap</Text>
                  </LoginTextContainer>
                )}
              </Button>
            </Card>
          </Widget>
        </Main>
      </Content>
    </Page>
  );
};

export default Login;
