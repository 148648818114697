import { createSlice } from '@reduxjs/toolkit';
import { DrawerIntegrationsState } from './drawer-integrations.types';

const initialState: DrawerIntegrationsState = {
  rows: null,
  isDrawerOpen: false,
  loadingUpdate: false,
  isChangedActivity: false,
  selectedCompanyIntegrationId: null,
};

const drawerIntegrationsSlice = createSlice({
  initialState,
  name: 'drawer-integrations',
  reducers: {
    setRows(state, { payload }) {
      state.rows = payload;
    },
    setIsDrawerOpen(state, { payload }) {
      state.isDrawerOpen = payload;
    },
    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
    setIsChangedActivity(state, { payload }) {
      state.isChangedActivity = payload;
    },
    setSelectedCompanyIntegrationId(state, { payload }) {
      state.selectedCompanyIntegrationId = payload;
    },
  },
});

export const { setRows, setIsChangedActivity, setSelectedCompanyIntegrationId } = drawerIntegrationsSlice.actions;

export default drawerIntegrationsSlice.reducer;
