import { useState } from 'react';
import { Loader } from 'components';
import request from 'library/request';
import { ROLE_API_NAME_ENUM } from 'enums';
import { useAppSelector } from 'store/hooks';
import { stringAvatar } from 'utils/string-avatar';
import { MenuItem, Select, Avatar } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { IntegrationByFilter } from 'library/request/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { selectIsLoading, selectUsersMap } from 'store/features/users/users.selector';

const avatarStyleObj = {
  height: '18px',
  width: '18px',
  fontSize: '8px',
  marginRight: '5px',
  display: 'inline-block',
  overflow: 'none',
  textAlign: 'center',
  lineHeight: '2.25',
};

const { assignToUser } = request;

type ChangeUserProps = {
  selectedIntegration: IntegrationByFilter;
  role: string;
};

const ChangeUser = ({ selectedIntegration, role }: ChangeUserProps) => {
  const users = useAppSelector(selectUsersMap);
  const loading = useAppSelector(selectIsLoading);

  const [newUser, setNewUser] = useState<string>(selectedIntegration.assignedUserName);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedUserName = event.target.value as string;
    assignToUser({ companyIntegrationId: selectedIntegration.id, userName: selectedUserName }).then((res) => {
      setNewUser(res.assignedUserName);
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <Select
      sx={{ height: '30px' }}
      size="small"
      fullWidth
      readOnly={role === ROLE_API_NAME_ENUM.CUSTOMER}
      IconComponent={
        role === ROLE_API_NAME_ENUM.CUSTOMER
          ? () => (
              <ArrowDropDownIcon
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: role === ROLE_API_NAME_ENUM.CUSTOMER ? 'none' : 'block',
                }}
              />
            )
          : undefined
      }
      variant="standard"
      id="change-user"
      value={newUser}
      defaultValue={selectedIntegration.assignedUserName}
      onChange={handleChange}>
      {users.map((user, index) => (
        <MenuItem
          sx={{ display: 'flex', alignItems: 'center', height: '50px' }}
          key={index.toString()}
          value={user.value}>
          <div style={{ display: 'flex', height: 'auto' }}>
            <Avatar component="span" {...stringAvatar(user.label, avatarStyleObj)} />
            <span style={{ display: 'inline-flex', height: 'auto', overflow: 'hidden' }}>{user.label}</span>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChangeUser;
