import { css, styled } from 'styled-components';

export const additionalWidgetStyle = css`
  margin: 0px 0px 0px 0px !important;
`;

export const additionalRowStyle = css`
  margin: 0px 0px 0px 0px !important;

  @media (max-width: 766px) {
    margin-bottom: 0px !important;
  }
`;

export const additionalButtonStyle = css`
  height: 20px;
  font-size: 12px;
`;

export const additionalMainContainerStyle = css`
  border-radius: 12px 12px 0px 12px;

  @media (max-width: 766px) {
    margin-bottom: 0px !important;
  }
`;

export const additionalContainerStyle = css`
  width: fit-content;
  margin-bottom: 10px;
  margin-left: auto;
  padding: 5px;
  border-radius: 0px 0px 12px 12px;
`;

export const OperationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  width: 100%;

  & button {
    width: 100%;
  }
`;
