import { createSlice } from '@reduxjs/toolkit';
import { UpdateNotificationRuleState } from './update-notification-rule.types';

const initialState: UpdateNotificationRuleState = {
  isPopUpOpen: false,
  errorUpdate: false,
  loadingUpdate: false,
  notificationRule: null,
  notificationRuleId: null,
};

const updateNotificationRuleSlice = createSlice({
  initialState,
  name: 'update-notification-rule',
  reducers: {
    setErrorUpdate(state, { payload }) {
      state.errorUpdate = payload;
    },
    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
    setNotificationRule(state, { payload }) {
      state.notificationRule = payload;
    },
    setIsDeletePopUpOpen(state, { payload }) {
      state.isPopUpOpen = payload;
    },
    setNotificationRuleId(state, { payload }) {
      state.notificationRuleId = payload;
    },
  },
});

export const { setErrorUpdate, setNotificationRule, setLoadingUpdate, setNotificationRuleId, setIsDeletePopUpOpen } =
  updateNotificationRuleSlice.actions;

export default updateNotificationRuleSlice.reducer;
