import Page from './page';
import Main from './main';
import Header from './header';
import Content from './content';
import SideBar from './sidebar';
import { ReactElement, useEffect } from 'react';
import Authentication from 'utils/authentication';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import selectCompaniesActions from 'store/features/select-companies/select-companies.actions';
import { selectFetchCompaniesInitialized } from 'store/features/select-companies/select-companies.selector';

type LayoutProps = {
  children: ReactElement;
};

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const hasSession = Authentication.hasSession();
  const didInitFetchCompanies = useAppSelector(selectFetchCompaniesInitialized);

  useEffect(() => {
    if (hasSession && !didInitFetchCompanies) {
      dispatch(selectCompaniesActions.initialize());
    }
  }, [didInitFetchCompanies, hasSession]);

  if (!hasSession) return children;

  return (
    <Page>
      <Header />
      <Content>
        <SideBar />
        <Main>{children}</Main>
      </Content>
    </Page>
  );
};

export default Layout;
