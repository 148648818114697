import { SortType } from 'pages/integrations';
import { useNavigate } from 'react-router-dom';
import { Company } from 'library/request/types';
import { sortHandler } from 'utils/render-sort';
import Authentication from 'utils/authentication';
import { renderFormattedTime } from 'utils/format-time';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { CustomPagination } from 'containers/elements/@commons';
import { selectUsersMap } from 'store/features/users/users.selector';
import { GroupCompaniesFilterParams } from 'pages/group-companies-main';
import {
  setGroupCompany,
  setGroupCompanyId,
  setIsDeletePopUpOpen,
} from 'store/features/update-group-company/update-group-company.slice';
import { TableHeads } from 'containers/elements/integrations/integration-table';
import { PaginationFilterParams } from 'containers/elements/@commons/pagination';
import { ORDER_TYPE_ENUM, ROLE_API_NAME_ENUM, getServerTranslated, getStatusTranslated } from 'enums';
import { EditIcon, DeleteIcon, StyledTableCell, StyledTableRow, SortDownIcon, SortUpIcon } from './styles';
import { Table, Paper, Tooltip, TableRow, TableBody, TableHead, TableFooter, TableContainer } from '@mui/material';

type GroupCompaniesTableProps = {
  totalGroupCompany: number;
  groupCompanyList: Company[];
  filterParams: GroupCompaniesFilterParams;
  setFilterParams: Dispatch<SetStateAction<GroupCompaniesFilterParams>>;
  onApplySortFilter: ({ selector, orderByType }: SortType) => Promise<void>;
  onApplyPaginationFilter: ({ pageSize, pageNumber }: PaginationFilterParams) => void;
};

const GroupCompaniesTable = ({
  groupCompanyList: rows,
  totalGroupCompany,
  filterParams,
  setFilterParams,
  onApplySortFilter,
  onApplyPaginationFilter,
}: GroupCompaniesTableProps) => {
  const loggedUser = Authentication.getLoggedUser();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const usersMap = useAppSelector(selectUsersMap);

  const tableHeads: TableHeads[] = [
    {
      label: 'Şirket Grubu',
      key: 'title',
      isSort: filterParams.selector === 'title',
      sortType: filterParams.selector === 'title' ? filterParams.orderByType : null,
    },
    {
      label: 'API Kullanıcı Adı',
      key: 'userName',
      isSort: filterParams.selector === 'userName',
      sortType: filterParams.selector === 'userName' ? filterParams.orderByType : null,
    },
    {
      label: 'Sunucu',
      key: 'serverType',
      isSort: filterParams.selector === 'serverType',
      sortType: filterParams.selector === 'serverType' ? filterParams.orderByType : null,
    },
    {
      label: 'Durum',
      key: 'statusType',
      isSort: filterParams.selector === 'statusType',
      sortType: filterParams.selector === 'statusType' ? filterParams.orderByType : null,
    },
    {
      label: 'Destek Personeli',
      key: 'defaultSupportPerson',
      isSort: filterParams.selector === 'defaultSupportPerson',
      sortType: filterParams.selector === 'defaultSupportPerson' ? filterParams.orderByType : null,
    },
    {
      label: 'Otomatik Mail Gönderimi',
      key: 'sendAutoEmail',
      isSort: filterParams.selector === 'sendAutoEmail',
      sortType: filterParams.selector === 'sendAutoEmail' ? filterParams.orderByType : null,
    },
    {
      label: 'Son Erişim Tarihi',
      key: 'lastAccessDate',
      isSort: filterParams.selector === 'lastAccessDate',
      sortType: filterParams.selector === 'lastAccessDate' ? filterParams.orderByType : null,
    },
    {
      label: 'IP Adresi',
      key: 'ipAddress',
      isSort: filterParams.selector === 'ipAddress',
      style: { borderRight: '1px solid #1e1e1e20' },
      sortType: filterParams.selector === 'ipAddress' ? filterParams.orderByType : null,
    },
    {
      label: 'Toplam',
      key: 'integrationCount',
      isSort: 'none',
      sortType: filterParams.selector === 'integrationCount' ? filterParams.orderByType : null,
    },
    {
      label: 'Başarılı',
      key: 'successIntegrationCount',
      isSort: 'none',
      sortType: filterParams.selector === 'successIntegrationCount' ? filterParams.orderByType : null,
    },
    {
      label: 'Başarısız',
      key: 'errorIntegrationCount',
      isSort: 'none',
      style: { borderRight: '1px solid #1e1e1e20' },
      sortType: filterParams.selector === 'errorIntegrationCount' ? filterParams.orderByType : null,
    },
    {
      label: 'Kapasite',
      key: 'diskCapacity',
      isSort: filterParams.selector === 'diskCapacity',
      sortType: filterParams.selector === 'diskCapacity' ? filterParams.orderByType : null,
    },
    {
      label: 'Kullanılan',
      key: 'diskUsedSpace',
      isSort: filterParams.selector === 'diskUsedSpace',
      sortType: filterParams.selector === 'diskUsedSpace' ? filterParams.orderByType : null,
    },
    {
      label: 'Boş',
      key: 'diskFreeSpace',
      isSort: filterParams.selector === 'diskFreeSpace',
      style: { borderRight: '1px solid #1e1e1e20' },
      sortType: filterParams.selector === 'diskFreeSpace' ? filterParams.orderByType : null,
    },
    {
      label: 'Kapasite',
      key: 'memoryCapacity',
      isSort: filterParams.selector === 'memoryCapacity',
      sortType: filterParams.selector === 'memoryCapacity' ? filterParams.orderByType : null,
    },
    {
      label: 'Kullanılan',
      key: 'memoryUsedSpace',
      isSort: filterParams.selector === 'memoryUsedSpace',
      sortType: filterParams.selector === 'memoryUsedSpace' ? filterParams.orderByType : null,
    },
    {
      label: 'Boş',
      key: 'memoryFreeSpace',
      isSort: 'none',
      sortType: filterParams.selector === 'memoryFreeSpace' ? filterParams.orderByType : null,
    },
  ];

  const goToEditGroupCompanyHandler = useCallback(() => navigate('edit'), []);

  return (
    <TableContainer
      sx={{
        width: '100%',
        overflow: 'hidden',
        overflowX: 'scroll',
        transform: 'rotateX(180deg)',

        '&::-webkit-scrollbar': {
          display: 'block',
          width: '7px',
          height: '7px',
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: '12px',
          backgroundColor: '#0f62fe',
        },

        '&::-webkit-scrollbar-track': {
          backgroundColor: '#dfdfdf57',
          borderRadius: '12px',

          '&::-webkit-scrollbar-thumb:hover': {
            background: '#5490ff',
          },
        },
      }}
      component={Paper}>
      <Table sx={{ width: '2200px', transform: 'rotateX(180deg)' }} aria-label="custom pagination table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#1e1e1e20' }}>
            {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
              <>
                <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                <StyledTableCell
                  colSpan={1}
                  sx={{ borderRight: '1px solid #1e1e1e20' }}
                  align="center"></StyledTableCell>
              </>
            )}
            <StyledTableCell colSpan={8} sx={{ borderRight: '1px solid #1e1e1e20' }} align="center">
              Detaylar
            </StyledTableCell>
            <StyledTableCell colSpan={3} sx={{ borderRight: '1px solid #1e1e1e20' }} align="center">
              Entegrasyon
            </StyledTableCell>
            <StyledTableCell colSpan={3} sx={{ borderRight: '1px solid #1e1e1e20' }} align="center">
              Disk
            </StyledTableCell>
            <StyledTableCell colSpan={3} align="center">
              Bellek
            </StyledTableCell>
          </TableRow>
          <TableRow>
            {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
              <>
                <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                <StyledTableCell
                  colSpan={1}
                  sx={{ borderRight: '1px solid #1e1e1e20' }}
                  align="center"></StyledTableCell>
              </>
            )}
            {tableHeads.map((tableHead, index) => (
              <StyledTableCell
                key={index.toString()}
                sx={{ ...tableHead.style, cursor: tableHead.isSort !== 'none' ? 'pointer' : 'default' }}
                onClick={() => sortHandler({ tableHead, setFilterParams, onApplySortFilter })}
                align="center">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                  }}>
                  <span style={{ marginRight: '5px' }}>{tableHead.label}</span>
                  {tableHead.isSort && tableHead.isSort !== 'none' && tableHead.sortType === ORDER_TYPE_ENUM.ASC && (
                    <SortDownIcon />
                  )}
                  {tableHead.isSort && tableHead.isSort !== 'none' && tableHead.sortType === ORDER_TYPE_ENUM.DESC && (
                    <SortUpIcon />
                  )}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {!totalGroupCompany ? (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={17}>Şirket Grubu bulunmamaktadır.</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            <TableBody>
              {rows.map((row, index) => {
                const [user] = usersMap.filter((user) => user.value === row.defaultSupportPerson);

                const formatStorage = (storage: undefined | string) =>
                  storage && storage !== 'NaN' ? `${Math.round(Number(storage.replaceAll(',', '.')))} GB` : '-';

                const formattedDiskCapasity = formatStorage(row.diskCapacity);
                const formattedDiskUsedSpace = formatStorage(row.diskUsedSpace);
                const formattedDiskFreeSpace = formatStorage(row.diskFreeSpace);

                const formattedMemoryCapasity = formatStorage(row.memoryCapacity);
                const formattedMemoryUsedSpace = formatStorage(row.memoryUsedSpace);
                const formattedMemoryFreeSpace = formatStorage(
                  String(Number.parseInt(formattedMemoryCapasity) - Number.parseInt(formattedMemoryUsedSpace))
                );

                return (
                  <StyledTableRow key={index.toString()}>
                    {loggedUser.role === ROLE_API_NAME_ENUM.ADMIN && (
                      <>
                        <Tooltip placement="top" title="Sil" arrow followCursor>
                          <StyledTableCell
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              dispatch(setGroupCompanyId(row.id));
                              dispatch(setIsDeletePopUpOpen(true));
                            }}
                            padding="none"
                            align="center">
                            <DeleteIcon />
                          </StyledTableCell>
                        </Tooltip>
                        <Tooltip placement="top" title="Düzenle" arrow followCursor>
                          <StyledTableCell
                            padding="none"
                            sx={{
                              borderRight: '1px solid #1e1e1e20',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(setGroupCompany(row));
                              goToEditGroupCompanyHandler();
                            }}
                            align="center">
                            <EditIcon />
                          </StyledTableCell>
                        </Tooltip>
                      </>
                    )}
                    <StyledTableCell align="center">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">{row.userName}</StyledTableCell>
                    <StyledTableCell align="center">{getServerTranslated(row.serverType as number)}</StyledTableCell>
                    <StyledTableCell align="center">{getStatusTranslated(row.statusType as number)}</StyledTableCell>
                    <StyledTableCell align="center">{user?.label}</StyledTableCell>
                    <StyledTableCell align="center">{row.sendAutoEmail ? 'Evet' : 'Hayır'}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.lastAccessDate ? renderFormattedTime(row.lastAccessDate) : '-'}
                    </StyledTableCell>
                    <StyledTableCell sx={{ borderRight: '1px solid #1e1e1e20' }} align="center">
                      {row.ipAddress || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.integrationCount}</StyledTableCell>
                    <StyledTableCell align="center">{row.successIntegrationCount}</StyledTableCell>
                    <StyledTableCell sx={{ borderRight: '1px solid #1e1e1e20' }} align="center">
                      {row.errorIntegrationCount}
                    </StyledTableCell>
                    <StyledTableCell align="center">{formattedDiskCapasity}</StyledTableCell>
                    <StyledTableCell align="center">{formattedDiskUsedSpace}</StyledTableCell>
                    <StyledTableCell sx={{ borderRight: '1px solid #1e1e1e20' }} align="center">
                      {formattedDiskFreeSpace}
                    </StyledTableCell>
                    <StyledTableCell align="center">{formattedMemoryCapasity}</StyledTableCell>
                    <StyledTableCell align="center">{formattedMemoryUsedSpace}</StyledTableCell>
                    <StyledTableCell align="center">{formattedMemoryFreeSpace}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <StyledTableRow sx={{ cursor: 'default' }}>
                <CustomPagination
                  colSpan={loggedUser.role === ROLE_API_NAME_ENUM.ADMIN ? 9 : 7}
                  totalCount={totalGroupCompany}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  onApplyPaginationFilter={onApplyPaginationFilter}
                />
              </StyledTableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default GroupCompaniesTable;
