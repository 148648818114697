import { LogoContainer } from './styles';
import { RuleSet } from 'styled-components';
import LogoSrc from 'assets/images/logo.png';

type LogoProps = {
  $additionalStyle?: RuleSet;
};

const Logo = ({ $additionalStyle }: LogoProps) => {
  return (
    <LogoContainer $additionalStyle={$additionalStyle}>
      <img src={LogoSrc} alt="logo" />
    </LogoContainer>
  );
};

export default Logo;
