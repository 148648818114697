import TextField from '@mui/material/TextField';
import { AddUserFormParams } from 'pages/users-add';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

type AddUserTextfieldTelegramUserNameProps = {
  onChange: Dispatch<SetStateAction<AddUserFormParams>>;
  formParams: AddUserFormParams;
  error: boolean;
};

const AddUserTextfieldTelegramUserName = ({ onChange, formParams, error }: AddUserTextfieldTelegramUserNameProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const telegramUserName = event.target.value;
    onChange((prevState) => ({ ...prevState, telegramUserName }));
  };

  return (
    <TextField
      fullWidth
      size="small"
      sx={
        error && formParams.useTelegram && !formParams.telegramUserName
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
              },
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
          : {
              '& div': {
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'medium',
              },
            }
      }
      onChange={handleChange}
      value={formParams.telegramUserName}
      id="outlined-basic-telegram-user-name"
      variant="outlined"
    />
  );
};

export default AddUserTextfieldTelegramUserName;
