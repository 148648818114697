import { createSlice } from '@reduxjs/toolkit';
import { UpdateGroupCompanyState } from './update-group-company.types';

const initialState: UpdateGroupCompanyState = {
  groupCompany: null,
  isPopUpOpen: false,
  errorUpdate: false,
  loadingUpdate: false,
  groupCompanyId: null,
};

const updateGroupCompanySlice = createSlice({
  initialState,
  name: 'update-group-company',
  reducers: {
    setErrorUpdate(state, { payload }) {
      state.errorUpdate = payload;
    },
    setGroupCompany(state, { payload }) {
      state.groupCompany = payload;
    },
    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
    setGroupCompanyId(state, { payload }) {
      state.groupCompanyId = payload;
    },
    setIsDeletePopUpOpen(state, { payload }) {
      state.isPopUpOpen = payload;
    },
  },
});

export const { setErrorUpdate, setGroupCompany, setLoadingUpdate, setGroupCompanyId, setIsDeletePopUpOpen } =
  updateGroupCompanySlice.actions;

export default updateGroupCompanySlice.reducer;
