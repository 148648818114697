import * as Icons from 'assets/icons';
import { styled } from 'styled-components';
import TableRow from '@mui/material/TableRow';
import { styled as styledMui } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableCell = styledMui(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1e1e1e29',
    color: '#1e1e1e',
    fontFamily: '"Open Sans", sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: '"Open Sans", sans-serif',
  },
}));

export const StyledTableRow = styledMui(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffffff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f7f7f7ff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}));

export const EditIcon = styled(Icons.EditSvg)``;

export const DeleteIcon = styled(Icons.DeleteSvg)``;

export const SortDownIcon = styled(Icons.SortDownSvg)`
  height: 16px;
  width: 16px;
`;

export const SortUpIcon = styled(Icons.SortUpSvg)`
  height: 16px;
  width: 16px;
`;
