import {
  Select,
  Button,
  Dialog,
  Checkbox,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@mui/material';
import { Loader } from 'components';
import request from 'library/request';
import { useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { IntegrationByFilter } from 'library/request/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  selectRows,
  selectSelectedCompanyIntegrationId,
} from 'store/features/drawer-integrations/drawer-integrations.selector';
import { ACTIVITY_TYPE_ENUM, ROLE_API_NAME_ENUM, getActivityTranslated } from 'enums';
import { setIsChangedActivity, setRows } from 'store/features/drawer-integrations/drawer-integrations.slice';

const { changeIntegrationStatus } = request;

type ChangeActivityProps = {
  role: string;
};

const ChangeActivity = ({ role }: ChangeActivityProps) => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(selectRows) as IntegrationByFilter[];
  const id = useAppSelector(selectSelectedCompanyIntegrationId) as number;
  const restRows = rows.filter((row) => row.id !== id);
  const [selectedRow] = rows.filter((row) => row.id === id);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [openMailDialog, setOpenMailDialog] = useState(false);
  const [newActivity, setNewActivity] = useState(String(selectedRow.activityType));

  const handleClickOpenMailDialog = () => {
    setOpenMailDialog(true);
  };

  const handleCloseMailDialog = () => {
    setOpenMailDialog(false);
    setError(false);
    setErrorMessage('');
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selectedStatus = event.target.value;

    if (Number(selectedStatus) === ACTIVITY_TYPE_ENUM.WAITING_ON_CUSTOMER) {
      handleClickOpenMailDialog();
    } else {
      setLoading(true);

      changeIntegrationStatus({
        companyIntegrationId: selectedRow.id,
        activityType: Number(selectedStatus),
        autoEmailContentToCustomer: '',
      }).then((res) => {
        dispatch(setIsChangedActivity(true));
        setNewActivity(selectedStatus);
        dispatch(setRows([...restRows, { ...selectedRow, activityType: selectedStatus }]));
        setLoading(false);
      });
    }
  };

  const handleChangeNotifyCustomer = () => {
    setError(false);
    setErrorMessage('');
    setNotifyCustomer(!notifyCustomer);
  };

  useEffect(() => {
    dispatch(setIsChangedActivity(false));
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Select
        sx={{ height: '30px' }}
        variant="standard"
        size="small"
        fullWidth
        readOnly={role === ROLE_API_NAME_ENUM.CUSTOMER}
        IconComponent={
          role === ROLE_API_NAME_ENUM.CUSTOMER
            ? () => (
                <ArrowDropDownIcon
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    display: role === ROLE_API_NAME_ENUM.CUSTOMER ? 'none' : 'block',
                  }}
                />
              )
            : undefined
        }
        labelId="demo-simple-select-label-change-activity"
        id="demo-simple-select-change-activity"
        value={newActivity}
        defaultValue={String(selectedRow.activityType)}
        onChange={handleChange}>
        {(Object.keys(ACTIVITY_TYPE_ENUM).filter((v) => isNaN(Number(v))) as (keyof typeof ACTIVITY_TYPE_ENUM)[]).map(
          (key, index) => (
            <MenuItem key={index.toString()} value={ACTIVITY_TYPE_ENUM[key]}>
              {getActivityTranslated(ACTIVITY_TYPE_ENUM[key])}
            </MenuItem>
          )
        )}
      </Select>
      <Dialog
        fullWidth
        open={openMailDialog}
        onClose={handleCloseMailDialog}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const text = formJson.text;

            if (!text && notifyCustomer) {
              setError(true);
              setErrorMessage('Bu alanın doldurulması zorunludur.');
              return;
            }

            const activityType = ACTIVITY_TYPE_ENUM.WAITING_ON_CUSTOMER;

            setLoading(true);

            changeIntegrationStatus({
              companyIntegrationId: selectedRow.id,
              activityType,
              autoEmailContentToCustomer: text || '',
            }).then((res) => {
              dispatch(setIsChangedActivity(true));
              setNewActivity(String(activityType));
              dispatch(setRows([...restRows, { ...selectedRow, activityType }]));
              setLoading(false);
            });

            handleCloseMailDialog();
          },
        }}>
        <DialogTitle>Müşteriye Gönderilecek E-Posta İçeriği</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            disabled={!notifyCustomer}
            margin="dense"
            id="text"
            name="text"
            label={notifyCustomer ? 'E-Posta İçeriği' : 'Bu alan doldurulamaz'}
            type="text"
            fullWidth
            variant="outlined"
            rows={6}
            multiline
            error={error}
            helperText={errorMessage}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (event.target.value) {
                setError(false);
                setErrorMessage('');
              }
            }}
          />
          <FormControlLabel
            control={<Checkbox />}
            sx={{
              fontFamily: '"Open Sans", sans-serif',
            }}
            labelPlacement="end"
            label="Müşteriye Bildirim Gitmesin."
            checked={!notifyCustomer}
            onChange={handleChangeNotifyCustomer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMailDialog}>Vazgeç</Button>
          <Button variant="contained" type="submit">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeActivity;
