import { ReactNode } from 'react';
import { Card } from 'components';
import * as Styles from './styles';
import { Paper } from '@mui/material';

type InfoCardProps = {
  rate?: boolean;
  Icon: ReactNode;
  mainTitle: string;
  mainTitleAmount: number;
  primaryExplanation?: string;
  iconBackgroundColor: string;
  secondaryExplanation?: string;
  primaryExplanationAmount?: number;
  secondaryExplanationAmount?: number;
  primaryExplanationAmountColor?: string;
  secondaryExplanationAmountColor?: string;
  onClickPrimaryExplanationHandler?: () => void;
  onClickSecondaryExplanationHandler?: () => void;
};

const InfoCard = ({
  Icon,
  rate,
  mainTitle,
  mainTitleAmount,
  primaryExplanation,
  iconBackgroundColor,
  secondaryExplanation,
  primaryExplanationAmount,
  secondaryExplanationAmount,
  primaryExplanationAmountColor,
  secondaryExplanationAmountColor,
  onClickPrimaryExplanationHandler,
  onClickSecondaryExplanationHandler,
}: InfoCardProps) => {
  return (
    <Paper elevation={2} sx={{ borderRadius: '15px' }}>
      <Card
        $additionalContainerStyle={Styles.additionalInfoCardStyle}
        $additionalStyle={Styles.additionalInfoCardContentStyle}>
        <Paper
          elevation={5}
          sx={{
            position: 'absolute',
            top: '-24px',
            left: '24px',
            width: '64px',
            height: '64px',
            backgroundColor: iconBackgroundColor,
            borderRadius: '15px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          {Icon}
        </Paper>
        <Styles.TopContentContainer>
          <Styles.MainTitleSpan>{mainTitle}</Styles.MainTitleSpan>
          <Styles.MainTitleAmountSpan>{mainTitleAmount}</Styles.MainTitleAmountSpan>
        </Styles.TopContentContainer>
        <Styles.Divider></Styles.Divider>
        <Styles.BottomContentContainer>
          {primaryExplanation && (
            <Styles.ExplanationContainer onClick={onClickPrimaryExplanationHandler}>
              <Styles.PrimaryExplanationAmountSpan $color={primaryExplanationAmountColor}>
                {rate ? `% ${primaryExplanationAmount}` : primaryExplanationAmount}
              </Styles.PrimaryExplanationAmountSpan>
              <span>{primaryExplanation}</span>
            </Styles.ExplanationContainer>
          )}
          {secondaryExplanation && (
            <Styles.ExplanationContainer onClick={onClickSecondaryExplanationHandler}>
              <Styles.SecondaryExplanationAmountSpan $color={secondaryExplanationAmountColor}>
                {rate ? `% ${secondaryExplanationAmount}` : secondaryExplanationAmount}
              </Styles.SecondaryExplanationAmountSpan>
              <span>{secondaryExplanation}</span>
            </Styles.ExplanationContainer>
          )}
        </Styles.BottomContentContainer>
      </Card>
    </Paper>
  );
};

export default InfoCard;
